<div class="modal-header">
  <h6 class="h7">{{ 'modals.edit_contact_details.edit_contact_details' | translate }}</h6>
  <button type="button" class="btn-close" id="edit-email-modal-close" data-dismiss="modal" aria-label="Close" (click)="bsModalRef.hide()"></button>
</div>

<div class="modal-body" [ngClass]="{'validation-visible': validationVisible}">
  <form [formGroup]="form">
    <div *ngIf="(step === 'email')">
      <div class="form-group">
        <label for="inputEmail" class="required">{{ 'form.labels.email_address' | translate }}</label>
        <input type="email" id="inputEmail" class="form-control w-100" [placeholder]="('form.labels.email_address' | translate)" formControlName="email" email>
        <app-feedback-field [field]="form.get('email')"></app-feedback-field>
      </div>
    </div>

    <div *ngIf="(step === 'code')">
      <p>{{ 'modals.edit_contact_details.enter_code_email' | translate }}</p>

      <div class="row align-items-end">
        <div class="col">
          <div class="form-group">
            <label for="inputCode">{{ 'modals.edit_contact_details.verification_code' | translate }}</label>
            <input type="text" id="inputCode" class="form-control w-100" [placeholder]="('modals.edit_contact_details.verification_code' | translate)" formControlName="code" required>
          </div>
        </div>
        <div class="col-auto">
          <a class="btn btn-link btn-lg" href="" (click)="resendCode($event)">{{ 'modals.edit_contact_details.resend' | translate }}</a>
        </div>
      </div>
    </div>

    <div class="info-box info-box-warning mt-4">
      <em class="info-box-icon" [inlineSVG]="'./assets/svg/warning-24.svg'"></em>
      <p class="info-box-title m-0">{{ 'modals.edit_contact_details.warning' | translate }}</p>
    </div>
  </form>
</div>

<div class="modal-footer">
  <div class="m-start-auto">
    <button class="btn btn-secondary" (click)="handleCancel()">{{ 'action.cancel' | translate }}</button>

    <button class="btn btn-primary m-start-2" [ngClass]="{'loader': isSaving}" *ngIf="(step === 'email') && isEmailChanged()" (click)="handleSubmit()">
      <span class="loader" *ngIf="isSaving" [inlineSVG]="'./assets/svg/loader-dark-sm.svg'"></span>
      <span *ngIf="(step === 'email')">{{ 'action.next_step' | translate }}</span>
    </button>

    <button class="btn btn-primary m-start-2" [ngClass]="{'loader': isSaving}" *ngIf="((step === 'code') || !isEmailChanged())" (click)="handleSubmit()" [disabled]="!isEmailChanged()">
      <span class="loader" *ngIf="isSaving" [inlineSVG]="'./assets/svg/loader-dark-sm.svg'"></span>
      <span>{{ 'action.confirm' | translate }}</span>
    </button>
  </div>
</div>
