<div class="layout-header-pusher header-back"></div>
<div class="layout-header header-back">
  <div class="header-content">
    <div class="container">
      <p>
        <a routerLink="/help-center" class="btn btn-tertiary m-start-n2">
          <span class="rtl-mirrored-inline-svg" [inlineSVG]="'./assets/svg/arrow-left-24.svg'"></span>
          <span>{{ 'action.back_to' | translate }} {{ 'pages.default.help_center.help_center' | translate }}</span>
        </a>
      </p>
    </div>

    <div class="container">
      <h5>{{ helpcenterSection?.title }}</h5>
    </div>
  </div>
</div>

<div class="body-content">
  <div class="container">
    <div class="faq-blocks">
      <div class="items">
        <app-faq-block
          *ngFor="let category of faqCategories; last as last;"
          [class]="!last ? 'mb-5' : 'mb-3'"
          [category]="category"
          [section]="helpcenterSection"
          (goToFaq)="goToFaq($event)"
        />
      </div>
    </div>
  </div>
</div>
