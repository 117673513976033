<div class="layout-header-pusher header-tabs"></div>

<div class="layout-header header-tabs">
  <div class="header-content">
    <div class="container">
      <h5>{{ 'pages.default.help_center.help_center' | translate }}</h5>
    </div>

    <div class="container">
      <app-page-tabs [items]="pageTabItems" *ngIf="!pageLoading"></app-page-tabs>
    </div>
  </div>
</div>

<div class="body-content">
  <div class="container" *ngIf="pageLoading">
    <app-page-loader></app-page-loader>
  </div>

  <div class="container" *ngIf="!pageLoading">
    @for (helpcenterSection of helpcenterSections; track helpcenterSection.title) {

      @if(!$first && !helpcenterSection.isBanner) {
        <hr class="my-5" />
      }

      <div id="zone-{{$index}}" class="mb-5">
        <!-- Articles -->
        <div *ngIf="helpcenterSection.isArticles">
          <div class="heading-block mb-3">
            <div class="block-wrapper overflow-hidden">
              <h5 class="text-truncate">{{ helpcenterSection?.title }}</h5>
              <p class="text-truncate">{{ helpcenterSection?.subtitle }}</p>
            </div>
            <a *ngIf="(helpcenterSection.isArticles && !helpcenterSection.has2LBlocks && helpcenterSection?.articles?.length > 3) || (helpcenterSection.has2LBlocks && helpcenterSection.isArticles && helpcenterSection?.articles?.length > 5)"
               href=""
               class="btn btn-secondary m-start-auto"
               (click)="goToSectionOverview(helpcenterSection, $event)"
            >
              {{ 'pages.default.help_center.details' | translate }}
            </a>
          </div>

          <div class="empty-state my-5 my-lg-7 my-xl-9" *ngIf="!helpcenterSection?.articles || helpcenterSection?.articles?.length === 0">
            <span [inlineSVG]="'./assets/svg-color/no-notifications-graphic.svg'"></span>
            <h6 class="h7">{{ 'pages.default.help_center.no_articles_yet' | translate }}</h6>
          </div>

          <div *ngIf="helpcenterSection?.articles">
            <div class="row g-4 mb-2">
              <div *ngFor="let article of helpcenterSection?.articles | slice: 0 : 5; index as index" [ngClass]="(helpcenterSection.has2LBlocks && index < 2)? 'col-6' : 'col-4'">
                <a *ngIf="index < 3 || (helpcenterSection.has2LBlocks && index < 5)"
                   href=""
                   class="card h-100"
                   [ngClass]="(helpcenterSection.has2LBlocks && index < 2)? 'featured-card' : 'preview-card'"
                   (click)="goToArticleDetail($event, article, helpcenterSection)"
                >
                  <div class="card-cover">
                    <img *ngIf="article?.thumbnail?.image?.url"
                         [src]="article?.thumbnail?.image?.url + '?width=' + ((helpcenterSection.has2LBlocks && index < 2) ? '1298' : '908') + '&disable=upscale&format=pjpg&auto=webp'"
                         alt="Thumbnail"
                    />

                    <div class="cover-meta">
                      <div class="meta-category" *ngIf="article.thumbnail">
                        <span [inlineSVG]="'./assets/svg/file-general-24.svg'" *ngIf="article.isArticle" class="rtl-mirrored-inline-svg"></span>
                        <span [inlineSVG]="'./assets/svg/file-pdf-24.svg'" *ngIf="article.isDocument"></span>
                        <span [inlineSVG]="'./assets/svg/play-24.svg'" *ngIf="article.isMedia" class="rtl-mirrored-inline-svg"></span>
                      </div>
                    </div>
                  </div>

                  <div class="card-body">
                    <p class="lead fw-semibold mb-1 e2e-material-title">{{ article.title }}</p>
                    <p class="fw-normal mb-0 e2e-material-description">{{ article.description }}</p>
                  </div>
                </a>
              </div>
            </div>

            <div *ngIf="( !helpcenterSection.has2LBlocks && helpcenterSection?.articles?.length > 3) || ( helpcenterSection.has2LBlocks && helpcenterSection?.articles?.length > 5)" class="text-end">
              <button (click)="goToSectionOverview(helpcenterSection, $event)" class="btn btn-link m-start-auto">
                {{ 'pages.default.help_center.show_all' | translate }} ({{ helpcenterSection?.articles?.length }})
              </button>
            </div>
          </div>
        </div>
        <!-- /Articles -->

        <!-- Faqs -->
        <div *ngIf="helpcenterSection.isFaqs">
          <div class="heading-block mb-3">
            <div class="block-wrapper overflow-hidden">
              <h5 class="text-truncate">{{ helpcenterSection?.title }}</h5>
              <p class="text-truncate">{{ helpcenterSection?.subtitle }}</p>
            </div>
            <a *ngIf="helpcenterSection?.faqs?.length > helpcenterSection.maxCategoriesToShow"
               href=""
               class="btn btn-secondary m-start-auto"
               (click)="goToSectionOverview(helpcenterSection, $event)"
            >
              {{ 'pages.default.help_center.details' | translate }}
            </a>
          </div>

          <div class="faq-blocks">
            <div class="items">
              <app-faq-block
                *ngFor="let category of helpcenterSection?.faqs?.slice(0, helpcenterSection.maxCategoriesToShow); last as last;"
                [class]="!last ? 'mb-5' : 'mb-3'"
                [category]="category"
                [section]="helpcenterSection"
                (goToFaq)="goToFaq($event)"
              />
            </div>
          </div>

          <div *ngIf="(helpcenterSection?.faqs?.length > helpcenterSection.maxCategoriesToShow)" class="text-end">
            <button (click)="goToSectionOverview(helpcenterSection, $event)" class="btn btn-link m-start-auto">
              {{ 'pages.default.help_center.show_all' | translate }} ({{ helpcenterSection?.faqs?.length }})
            </button>
          </div>
        </div>
        <!-- /Faqs -->

        <!-- Contact -->
        <div *ngIf="helpcenterSection.isContact">
          <app-help-center-contact [helpcenterSection]="helpcenterSection"></app-help-center-contact>
        </div>
        <!-- /Contact -->

        <!-- Banner -->
        <div *ngIf="helpcenterSection.isBanner">
          <div class="picture-card mb-4 mb-lg-5">
            <div class="card">
              <div class="card-body p-4">
                <h5>{{ helpcenterSection?.title }}</h5>
                <p>{{ helpcenterSection?.subtitle }}</p>

                @if (helpcenterSection?.path?.href) {
                  <div class="pb-2 pb-md-3"></div>

                  <a href="{{ helpcenterSection?.path.href }}" target="_blank" rel="noopener noreferrer" class="btn btn-primary">
                    <span>{{ helpcenterSection?.path?.title }}</span>
                  </a>
                }

              </div>
            </div>
            <div class="picture" [style.background-image]="'url('+ helpcenterSection?.thumbnail?.url + '?width=1394&disable=upscale&format=pjpg&auto=webp' +')'"></div>
          </div>
        </div>
        <!-- /Banner -->
      </div>
    }
  </div>
</div>
