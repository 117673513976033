import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-back-link',
  templateUrl: './back-link.component.html',
})
export class BackLinkComponent {
  @Input() public route: string;
  @Input() public label: string;
  @Input() public labelPrefix: boolean = false;
}
