<div class="container layout-sidebar">
  <div class="sidebar-content is-flexed">
    <div class="content-part d-none d-sm-block">
      <app-flow-welcome [inSidebar]="true"></app-flow-welcome>
    </div>

    <div class="content-part">
      <p class="text-uppercase text-muted fw-bold small" *ngIf="homeFlowService.getSidebarTitleKey()">{{homeFlowService.getSidebarTitleKey() | translate }}</p>
      <app-onboaring-icon-steps *ngIf="showOnboardingIconSteps" [onBoardingType]="onBoardingType"
        [currentState]="currentState"></app-onboaring-icon-steps>
    </div>

    <div class="footer-part mt-auto d-none d-sm-block">
      <app-flow-copyrights></app-flow-copyrights>
    </div>
  </div>
</div>

<div class="layout-body">
  <div class="body-content">
    <div class="container">
      <h3 class="mb-3">{{ 'form.labels.your_pw' | translate }}</h3>

      <form [formGroup]="form" id="form" (ngSubmit)="formSubmit()" [ngClass]="{'validation-visible': validationVisible}"
        autocomplete="off">
        <div class="row">
          <div class="col-12 col-sm-8 col-md-6 col-lg-5 col-xl-4">

            <div class="form-group">
              <label for="inputAccountPassword" class="required">{{ 'pages.flow.onboarding_password.acc_pw' | translate
              }}</label>
              <input type="password" id="inputAccountPassword" class="form-control"
                formControlName="account_password"
                [ngClass]="{'ng-invalid-important': (form?.errors?.mismatch || passwordIsBad)}">
              <p class="validation-feedback" *ngIf="form?.controls?.account_password?.errors">{{
                'form.feedback.pw_complexity_error' | translate }}</p>
              <p class="validation-feedback general-feedback" *ngIf="passwordIsBad">{{
                'form.feedback.pw_complexity_error' | translate }}</p>
            </div>

            <div class="form-group">
              <label for="inputConfirmPassword" class="required">{{ 'pages.flow.onboarding_password.confirm_pw' |
                translate }}</label>
              <input type="password" id="inputConfirmPassword" class="form-control"
                formControlName="confirm_password" [ngClass]="{'ng-invalid-important': form?.errors?.mismatch}">
              <p class="validation-feedback general-feedback" *ngIf="form?.errors?.mismatch">{{
                'pages.flow.onboarding_password.pw_not_the_same' | translate }}</p>
            </div>
          </div>
        </div>
      </form>

      <!-- <hr/> -->
      <div class="pb-4 pb-lg-5"></div>

      <app-password-policy-rules [policy]="policy" [validationVisible]="validationVisible"
      [passwordControl]="form?.controls?.account_password"></app-password-policy-rules>

      <div class="d-sm-none mt-8">
        <app-flow-copyrights></app-flow-copyrights>
      </div>

    </div>
  </div>

  <div class="layout-footer-pusher"></div>
  <div class="layout-footer">
    <div class="footer-content">
      <div class="container">
        <button class="btn btn-lg btn-primary m-start-auto" type="submit" form="form" [ngClass]="{'loader': isLoading}" [disabled]="!policy">
          <span class="loader" *ngIf="isLoading" [inlineSVG]="'./assets/svg/loader-dark-sm.svg'"></span>
          <span>{{ 'action.continue' | translate }}</span>
        </button>
      </div>
    </div>
  </div>
</div>
