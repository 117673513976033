import { Component, OnInit } from '@angular/core';
import { PageTabItem } from '../../../../models/page-tab-item';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { EditPhoneModalComponent } from '../../../../modals/edit-phone-modal/edit-phone-modal.component';
import { EditEmailModalComponent } from '../../../../modals/edit-email-modal/edit-email-modal.component';
import { EditPersonalDetailsModalComponent } from '../../../../modals/edit-personal-details-modal/edit-personal-details-modal.component';
import { Patient } from '../../../../models/patient';
import { PatientService } from '../../../../services/patient.service';
import { HomeFlowService } from '../../../../services/home-flow.service';
import { GeneralService } from '../../../../services/general.service';
import { DialCode } from '../../../../models/dial-code';
import { TranslateService } from '@ngx-translate/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { UserService } from '../../../../services/user.service';
import { Profile } from '../../../../models/profile';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { DataService } from '../../../../services/data.service';
import { DateFormat } from '../../../../models/date-format';
import { LocaleService } from '../../../../services/locale.service';
import { LanguageService } from '../../../../services/language.service';
import { LanguageCode } from '../../../../models/language-code';
import { CountryService } from '../../../../services/country.service';
import { Country } from '../../../../models/country';
import { forkJoin } from 'rxjs';
import { ScopeService } from '../../../../services/scope.service';

@Component({
  selector: 'app-settings-personal',
  templateUrl: './settings-personal.component.html',
  styleUrls: ['./settings-personal.component.scss']
})
export class SettingsPersonalComponent implements OnInit {
  pageTabItems: PageTabItem[] = [];

  dialCode: DialCode;
  patient: Patient;
  profile: Profile;
  dateFormat: DateFormat;
  patientCountryLabel: string;

  contactForm: UntypedFormGroup;
  notificationsForm: UntypedFormGroup;

  countries: Country[];
  country: Country;

  language: LanguageCode

  modalRef: BsModalRef;

  constructor(
    public formBuilder: UntypedFormBuilder,
    public userService: UserService,
    public homeFlowService: HomeFlowService,
    public generalService: GeneralService,
    public modalService: BsModalService,
    public patientService: PatientService,
    public translate: TranslateService,
    public toastrService: ToastrService,
    public router: Router,
    public dataService: DataService,
    public localeService: LocaleService,
    public languageService: LanguageService,
    public countryService: CountryService,
    public scopeService: ScopeService
  ) {
  }

  ngOnInit(): void {
    this.dateFormat = this.localeService.getLocalePreferences().dateFormat;

    this.pageTabItems = [
      new PageTabItem('pages.default.settings.personal.personal_details', 'zone_your_information'),
      new PageTabItem('pages.default.settings.personal.contact_details', 'zone_contact_details'),
      new PageTabItem('pages.default.settings.personal_info.notifications', 'zone_notifications')
    ];

    this.contactFormSetup();
    this.notificationsFormSetup();
    this.getDetails();
  }

  getDetails() {
    this.patient = this.patientService.getCurrentStoredPatient();
    this.profile = this.userService.getStoredProfile();

    this.alignLanguage();
    this.setPatientCountry();

    forkJoin([
      this.scopeService.refreshCurrentPatient(),
      this.userService.getProfile()
    ]).subscribe((response: [Patient, Profile]) => {
      this.handleGetDetails(response);
    });
  }

  handleGetDetails(response: [Patient, Profile]) {
    this.patient = response[0];
    this.profile = response[1];

    this.alignLanguage();
    this.setPatientCountry();
    this.setDialCode(this.profile.mobile_number?.code);
    this.contactFormSetup();
    this.notificationsFormSetup();
  }

  alignLanguage() {
    this.language = this.languageService.getLanguageCodeFromValue(this.profile.locale.language);
  }

  setPatientCountry() {
    if (this.countries) {
      if (this.patient) {
        this.country = this.countries.find(country => country.value === this.patient.country);
      }
    } else {
      this.countryService.getCountriesFromCms().subscribe((countries: Country[]) => {
        this.countries = countries;
        this.setPatientCountry();
      });
    }
  }

  setDialCode(code) {
    this.dialCode = this.generalService.mapPatientPhoneNumberCodeToDialCode(code);
  }

  contactFormSetup(): void {
    if (this.contactForm) {
      delete this.contactForm;
    }

    const profile: Profile = this.profile || new Profile();

    this.contactForm = this.formBuilder.group({
      contact_channel: [profile?.contact_channel]
    });

    this.contactForm.get('contact_channel').setValue(profile?.contact_channel);
  }

  notificationsFormSetup() {
    if (this.notificationsForm) {
      delete this.notificationsForm;
    }

    const profile: Profile = this.profile || new Profile();

    this.notificationsForm = this.formBuilder.group({
      email_notifications: [profile.locale?.notification_channels?.includes('EMAIL')],
      phone_notifications: [profile.locale?.notification_channels?.includes('PUSH')]
    });

    this.notificationsForm.controls['phone_notifications'].disable();
  }

  showEditPersonalDetailsModal(event): void {
    event.preventDefault();

    const initialState = {
      patient: this.patient
    };

    const modalRef = this.modalService.show(EditPersonalDetailsModalComponent,
      GeneralService.BsModalOptions({
        initialState
      })
    );
    modalRef.content.patientUpdatedEvent.subscribe(() => {
      this.onUpdateSuccess();
    });
  }

  showEditEmailModal(event): void {
    event.preventDefault();

    const initialState = {
      profile: this.profile
    };

    this.modalRef = this.modalService.show(EditEmailModalComponent,
      GeneralService.BsModalOptions({
        class: 'modal-dialog-centered',
        initialState
      })
    );

    this.modalRef.content?.onProfileUpdated.subscribe(result => {
      this.visualUpdateProfile(result);
    });
  }

  showEditPhoneModal(event): void {
    event.preventDefault();

    const initialState = {
      profile: this.profile
    };

    this.modalRef = this.modalService.show(EditPhoneModalComponent,
      GeneralService.BsModalOptions({
        class: 'modal-dialog-centered',
        initialState
      })
    );

    this.modalRef.content?.onProfileUpdated.subscribe(result => {
      this.visualUpdateProfile(result);
    });
  }

  visualUpdateProfile(result) {
    if (result.mobile_number) {
      this.profile.mobile_number = result.mobile_number;
      this.setDialCode(result.mobile_number.code);
    }
  }

  onHandleContactFormSubmission(): void {
    this.profile.contact_channel = this.contactForm.value.contact_channel;

    this.userService.updateProfile(this.profile).subscribe(
      () => this.onUpdateSuccess(),
      () => this.onUpdateError()
    );
  }

  onUpdateSuccess(): void {
    this.patient = this.patientService.getCurrentStoredPatient();
    this.profile = this.userService.getStoredProfile();

    this.setPatientCountry();
    this.alignLanguage();

    this.toastrService.success(this.translate.instant('pages.default.settings.personal.update_success'));
  }

  onUpdateError(): void {
    this.router.navigateByUrl('error');
  }

  // Notifications
  onHandleNotificationsFormSubmission() {
    this.profile.locale.notification_channels = [];

    if (this.notificationsForm.get('email_notifications').value === true) {
      this.profile.locale.notification_channels.push('EMAIL');
    }

    if (this.notificationsForm.get('phone_notifications').value === true) {
      this.profile.locale.notification_channels.push('PUSH');
    }

    this.userService.updateProfile(this.profile).subscribe(
      () => this.onUpdateSuccess(),
      () => this.onUpdateError()
    );
  }
}
