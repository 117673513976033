export const environment = {
  production: false,
  app_version: '1.0.0',

  // PF
  platformUrl: 'https://public-patient-api.ppp-dev.apps.jnj',
  auth_username: '67df3537-ff72-4b1b-bc91-434020c628dc', // = application uid
  auth_password: 'AppPass123!',
  accept_header: 'application/vnd.c4t.public-patient-api.v1+json',
  content_type_header: 'application/vnd.c4t.public-patient-api.v1+json',

  // PF - Self-reg
  selfRegistrationUrl: 'https://public-self-registration-api.ppp-dev.apps.jnj',
  self_registration_accept_header: 'application/vnd.c4t.public-self-registration-api.v1+json',
  self_registration_content_type_header: 'application/vnd.c4t.public-self-registration-api.v1+json',

  // CMS
  cmsUrl: 'https://eu-cdn.contentstack.com',
  cmsApiKey: 'blt2043dcd74f319d2f',
  cmsAccessToken: 'csd6cb2dae29d3bfcf8c6cc9df',
  cmsEnvironment: 'dev',

  // Brightcove
  brightcove_acc_id: '6202477157001',

  // Options
  refreshEvaluation: false,
  dev_remember_login_email: false,
  trans_loader_skip_cs_errors: true,


  // Stores
  appStore:  'https://apps.apple.com/us/app/care4today-2-0-patient-pre-qa/id1538558564',
  playStore: 'https://play.google.com/store/apps/details?id=com.jnj.mdd.emea.care4today.patient.preqa'
};
