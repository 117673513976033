<div class="layout-header-pusher header-back-tabs"></div>

<div class="layout-header header-back-tabs">
  <div class="header-content">
    <div class="container">
      <app-back-link route="/settings" label="action.back_to_settings" id="settings-personal-back" />
    </div>

    <div class="container">
      <h5>{{ patient?.first_name }}&nbsp;{{ patient?.last_name }}</h5>
    </div>

    <div class="container">
      <app-page-tabs [items]="pageTabItems"></app-page-tabs>
    </div>
  </div>
</div>

<div class="body-content">
  <div class="container">

    <div id="zone_your_information">
      <div class="heading-block">
        <div class="block-wrapper overflow-hidden">
          <h5 class="text-truncate">{{ 'pages.default.settings.personal.personal_details' | translate }}</h5>
        </div>
      </div>

      <div class="single-item-block position-relative">
        <div class="block-wrapper p-3 w-100" *ngIf="!patient">
          <app-page-loader [padding]="'30px 0'"></app-page-loader>
        </div>

        <div class="block-wrapper p-3 w-100" *ngIf="patient">
          <div class="w-100">
            <div class="row align-items-center">
              <div class="col-auto m-end-4" style="width: 160px;">
                <app-avatar class="profile-picture" [profilePicture]="patient?.profile_picture"></app-avatar>
              </div>

              <div class="col">
                <div class="row g-2">
                  <div class="col-4">
                    <div class="label-value-pairs">
                      <div class="pair">
                        <p class="label">{{ 'form.labels.first_name' | translate }}</p>
                        <p class="value text-truncate">{{ patient?.first_name }}</p>
                      </div>
                    </div>
                  </div>

                  <div class="col-4">
                    <div class="label-value-pairs">
                      <div class="pair">
                        <p class="label">{{ 'form.labels.last_name' | translate }}</p>
                        <p class="value text-truncate">{{ patient?.last_name }}</p>
                      </div>
                    </div>
                  </div>

                  <div class="col-4">
                    <div class="label-value-pairs">
                      <div id="function-name" class="pair">
                        <p class="label">{{ 'form.labels.preferred_name' | translate }}</p>
                        <p class="value text-truncate" *ngIf="patient?.preferred_name">{{ patient?.preferred_name }}</p>
                        <p class="value text-truncate" *ngIf="!patient?.preferred_name">{{ patient?.first_name }}</p>
                      </div>
                    </div>
                  </div>

                  <div class="col-4">
                    <div class="label-value-pairs">
                      <div class="pair">
                        <p class="label">{{ 'form.labels.gender' | translate }}</p>
                        <p class="value text-truncate" *ngIf="patient?.gender==='MALE'">{{ 'form.labels.male' | translate }}</p>
                        <p class="value text-truncate" *ngIf="patient?.gender==='FEMALE'">{{ 'form.labels.female' | translate }}</p>
                        <p class="value text-truncate" *ngIf="patient?.gender==='UNKNOWN'">{{ 'form.labels.other' | translate }}</p>
                      </div>
                    </div>
                  </div>

                  <div class="col-4">
                    <div class="label-value-pairs">
                      <div class="pair">
                        <p class="label">{{ 'form.labels.date_of_birth' | translate }}</p>
                        <p class="value text-truncate">{{ patient?.date_of_birth | timeZoneDate:dateFormat?.format }}</p>
                      </div>
                    </div>
                  </div>

                  <div class="col-4"></div>

                  <div class="col-4">
                    <div class="label-value-pairs">
                      <div class="pair">
                        <p class="label">{{ 'form.labels.language' | translate }}</p>
                        <p class="value text-truncate" id="language">{{ language?.translationKey | translate }}</p>
                      </div>
                    </div>
                  </div>

                  <div class="col-4">
                    <div class="label-value-pairs">
                      <div id="location" class="pair">
                        <p class="label">{{ 'form.labels.country' | translate }}</p>
                        <p class="value text-truncate">{{ country?.label }}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="col-auto align-self-start">
                <a *ngIf="patient"
                  href=""
                  (click)="showEditPersonalDetailsModal($event)"
                  class="btn btn-secondary"
                  id="settings-personal-show-personal-details"
                >
                  <span class="icon m-end-1" [inlineSVG]="'./assets/svg/edit-24.svg'"></span>
                  <span>{{ 'action.edit' | translate }}</span>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <hr class="my-5">

    <div id="zone_contact_details">
      <div class="heading-block">
        <div class="block-wrapper overflow-hidden">
          <h5 class="text-truncate">{{ 'pages.default.settings.personal.contact_details' | translate }}</h5>
        </div>
      </div>

      <div class="mt-2 mb-5">
        <div class="single-item-block mb-2">
          <div class="block-icon">
            <span class="icon rtl-mirrored-inline-svg" [inlineSVG]="'./assets/svg/envelope-outline-24.svg'"></span>
          </div>
          <div class="block-wrapper">
            <p class="label">{{ 'form.labels.email' | translate }}</p>
            <p class="value" id="EmailValue">{{ profile?.email }}</p>
          </div>
          <div class="block-btn-wrapper m-end-2">
            <a (click)="showEditEmailModal($event)" class="btn btn-secondary" href="">
              {{ 'action.edit' | translate }}
            </a>
          </div>
        </div>

        <div class="single-item-block">
          <div class="block-icon">
            <span class="icon rtl-mirrored-inline-svg" [inlineSVG]="'./assets/svg/mobile-device-outline-24.svg'"></span>
          </div>
          <div class="block-wrapper">
            <p class="label">{{ 'form.labels.phone' | translate }}</p>
            <p class="value" id="PhoneValue">{{ dialCode?.label }} {{ profile?.mobile_number?.number }}</p>
          </div>
          <div class="block-btn-wrapper m-end-2">
            <a (click)="showEditPhoneModal($event)" class="btn btn-secondary" href="">
              {{ 'action.edit' | translate }}
            </a>
          </div>
        </div>
      </div>
    </div>

    <hr class="my-5">

    <!--  -->
    <!-- HIDDEN BY D-NONE - AS PER REQUEST OF: AGIK-5008 -->
    <!--  -->
    <div id="zone_contact" class="d-none">
      <div class="mb-2 mt-4" *ngIf="profile?.contact_consented">
        <p>{{ 'pages.default.settings.personal_info.contact_info' | translate }}</p>

        <form [formGroup]="contactForm" id="contactForm" class="form-width-limit">
          <div class="custom-control custom-radio mb-1">
            <input type="radio" class="custom-control-input" id="radioContactEmail" value="EMAIL"
                  formControlName="contact_channel" (change)="onHandleContactFormSubmission()">
            <label class="custom-control-label" for="radioContactEmail">{{ 'form.labels.email' | translate }}</label>
          </div>


          <div class="custom-control custom-radio mb-3" *ngIf="profile?.mobile_number?.number">
            <input type="radio" class="custom-control-input" id="radioContactPhone" value="PHONE"
                  formControlName="contact_channel" (change)="onHandleContactFormSubmission()">
            <label class="custom-control-label" for="radioContactPhone">{{ 'form.labels.phone' | translate }}</label>
          </div>
        </form>

        <div class="custom-control custom-radio mb-3" *ngIf="!profile?.mobile_number?.number">
          <input type="radio" class="custom-control-input" id="radioContactPhoneDisabled" disabled="disabled">
          <label class="custom-control-label" for="radioContactPhoneDisabled">{{ 'form.labels.phone' | translate }}
            <p class="small text-muted">{{ 'pages.default.settings.preferences.please_update_with_phone' | translate }}</p>
          </label>
        </div>

        <div class="bg-white p-3 mt-4">
          <h3 class="mb-1">
            <!-- <em [inlineSVG]="'./assets/svg-color/XXX-NEEDS-NEW-ICON.svg'"></em> -->
            <span class="align-middle">{{ 'pages.default.settings.preferences.prefer_no_contact' | translate }}</span>
          </h3>

          <p class="m-0">{{ 'pages.default.settings.preferences.prefer_no_contact_info1' | translate }}</p>
          <p class="m-0">
            <a routerLink="/settings/consents" class="font-weight-bold text-secondary" id="settings-personal-prefer-no-contact">
              {{ 'pages.default.settings.preferences.prefer_no_contact_info2' | translate }}
            </a>
          </p>
        </div>
      </div>

      <div *ngIf="!profile?.contact_consented">
        <div class="bg-white p-3 mt-4">
          <h3 class="mb-1">
            <!-- <em [inlineSVG]="'./assets/svg-color/XXX-NEEDS-NEW-ICON.svg'"></em> -->
            <span class="align-middle">{{ 'pages.default.settings.preferences.no_consent_given' | translate }}</span>
          </h3>

          <p class="m-0">{{ 'pages.default.settings.preferences.no_consent_given_text' | translate }}</p>
          <p class="m-0">
            <a routerLink="/settings/consents" class="font-weight-bold text-secondary" id="settings-personal-contact-consent">
              {{ 'pages.default.settings.preferences.prefer_no_contact_info2' | translate }}
            </a>
          </p>
        </div>
      </div>
    </div>

    <div id="zone_notifications" class="container">
      <div class="heading-block">
        <div class="block-wrapper overflow-hidden">
          <h5 class="text-truncate">{{ 'pages.default.settings.personal.notifications' | translate }}</h5>
        </div>
      </div>

      <form [formGroup]="notificationsForm" id="notificationsForm" class="form-width-limit">
        <div class="form-check form-switch mb-3">
          <input
            type="checkbox"
            class="form-check-input"
            id="checkboxNotificationsEmail"
            role="switch"
            value="EMAIL"
            formControlName="email_notifications"
            (change)="onHandleNotificationsFormSubmission()"
          />
          <label class="form-check-label" for="checkboxNotificationsEmail">
            {{ 'form.labels.email' | translate }}
          </label>
        </div>
        <div class="form-check form-switch mb-3">
          <input
            type="checkbox"
            class="form-check-input"
            id="checkboxNotificationsPhone"
            role="switch"
            value="PUSH"
            formControlName="phone_notifications"
          />
          <label class="form-check-label" for="checkboxNotificationsPhone">
            {{ 'form.labels.push' | translate }}
          </label>
        </div>

        <p class="text-muted">
          <span *ngIf="notificationsForm.get('phone_notifications').value">{{ 'pages.default.settings.personal.push_enabled' | translate }}</span>
          <span *ngIf="!notificationsForm.get('phone_notifications').value">{{ 'pages.default.settings.personal.push_disabled' | translate }}</span>
          {{ 'pages.default.settings.personal.modified_phone' | translate }}
        </p>
      </form>
    </div>
  </div>
</div>
