<div class="modal-header">
  <h6 class="h7">{{ 'modals.start_new_convo.start_new_convo' | translate }}</h6>
  <button type="button" class="btn-close" id="start-new-conversation-close" data-dismiss="modal" aria-label="Close" (click)="bsModalRef.hide()"></button>
</div>

<div class="modal-body">
  <div *ngIf="isLoading">
    <app-page-loader padding="2rem"></app-page-loader>
  </div>

  <div *ngIf="!isLoading">

    <form *ngIf="form" [formGroup]="form" id="form" [ngClass]="{'validation-visible': validationVisible}">
      <p class="validation-feedback general-feedback" *ngIf="!isModalValid()">{{
        'modals.start_new_convo.complete_all_fields' | translate }}</p>

      <div class="form-group required" *ngIf="pathways?.length>1">
        <app-select
        [label]="('modals.start_new_convo.treatment' | translate)"
        [clearable]="false"
        [searchable]="false"
        [hideSelected]="false"
        [multiple]="false"
        [items]="pathways"
        formControlName="pathway"

        (change)="loadPossibleParticipants()"
        [asFilter]="false"
        [showCheckboxes]="false">
        </app-select>

        <app-feedback-field [field]="form.get('pathway')"></app-feedback-field>
      </div>

      <div class="form-group required">
        <div class="d-flex justify-content-between mb-1">
          <label for="inputSubject" class="required">{{ 'modals.start_new_convo.subject' | translate }}</label>
          <p class="small text-muted text-align-end m-0">
            {{ (form?.controls?.subject?.value?.length || '0') | translateNumber }}/{{subjectMaxLength | translateNumber}}
          </p>
        </div>

        <input type="text" id="inputSubject" class="form-control" formControlName="subject" maxlength="{{subjectMaxLength}}" required>
        <app-feedback-field [field]="form.get('subject')"></app-feedback-field>
      </div>

      <div *ngIf="form?.controls?.pathway?.value" class="mt-3">
        <p class="mb-2 fw-bold">{{ 'modals.start_new_convo.participants' | translate }}</p>

        <!-- <div class="text-center text-muted py-3" *ngIf="isLoadingParticipants">
          <em class="spinner-border spinner-border-sm"></em>
        </div> -->

        <div class="spinner spinner-sm my-2" *ngIf="isLoadingParticipants" [inlineSVG]="'./assets/svg/loader-light-sm.svg'"></div>

        <!-- <app-page-loader padding="1rem" *ngIf="isLoadingParticipants"></app-page-loader> -->

        <div class="mb-3" *ngIf="!isLoadingParticipants">
          <p class="small fw-semibold text-muted mb-1">{{ 'modals.start_new_convo.team' | translate }}</p>

          <div class="participant-item clickable" (click)="selectTeam()" [class.selected]="all_hcps"
            [ngClass]="(!isParticipantChosen() && validationVisible)? 'error' : ''">
            <div class="avatar" [inlineSVG]="'./assets/svg/participants-outline-24.svg'"></div>
            <div class="meta">
              <p class="title">Test: {{ 'modals.start_new_convo.my_team' | translate }}</p>
              <p class="description">{{ getAllParticipantNames() }}</p>
            </div>
            <div class="checkbox">
              <div class="form-check">
                <input type="radio" class="form-check-input" id="check_my_team"  [checked]="all_hcps">
                <label class="form-check-label" for="check_my_team"></label>
              </div>
            </div>
          </div>
        </div>

        <div *ngIf="!isLoadingParticipants">
          <p class="small fw-semibold text-muted mb-1">{{ 'modals.start_new_convo.people' | translate }}</p>

          <div class="participant-item clickable" *ngFor="let p of participants" (click)="setSelectedParticipant(p)"
            [class.selected]="p === selectedParticipant"
            [ngClass]="(!isParticipantChosen() && validationVisible)? 'error' : ''">
            <app-avatar [profilePicture]="p?.profile_picture"></app-avatar>

            <div class="meta">
              <p class="title">{{ p?.getFullName() }}</p>
              <p class="description">{{ p?.job_title }}</p>
            </div>
            <div class="checkbox">
                <div class="form-check">
                  <input type="radio" class="form-check-input" id="check_my_team"  [checked]="p === selectedParticipant">
                  <label class="form-check-label" for="check_my_team"></label>
                </div>
            </div>
          </div>

        </div>
      </div>
    </form>
  </div>

</div>
<div class="modal-footer">
  <div class="m-start-auto">
    <button class="btn btn-lg btn-secondary" (click)="handleCancel()">{{ 'action.cancel' | translate }}</button>
    <button class="btn btn-lg btn-primary m-start-2" [ngClass]="{'loader': isCreating}" (click)="handleSubmit()">
      <span class="loader" *ngIf="isCreating" [inlineSVG]="'./assets/svg/loader-dark-sm.svg'"></span>
      <span>{{ 'modals.start_new_convo.start_convo' | translate }}</span>
    </button>
  </div>
</div>
