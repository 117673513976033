import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Faq } from '../../models/faq';
import { FaqCategory } from '../../models/faq-category';
import { HelpcenterSection } from '../../models/helpcenter-section';

@Component({
  selector: 'app-faq-block',
  templateUrl: './faq-block.component.html'
})
export class FaqBlockComponent {
  @Input() category: FaqCategory;
  @Input() section: HelpcenterSection;
  @Input() class: string = 'mb-1';
  @Input() skip: string|null = null;
  @Input() showHeading: boolean = true;

  @Output() goToFaq = new EventEmitter<{ faq: Faq, category: FaqCategory, section: HelpcenterSection }>();

  onGoToFaq(event, faq, category, section) {
    event.preventDefault();

    this.goToFaq.emit({
      faq,
      category,
      section
    });
  }
}
