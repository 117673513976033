<div class="layout-header-pusher header-back"></div>

<div class="layout-header header-back">
  <div class="header-content">
    <div class="container">
      <p>
        <a routerLink="/dashboard" class="btn btn-tertiary m-start-n2">
          <span class="rtl-mirrored-inline-svg m-end-1" [inlineSVG]="'./assets/svg/arrow-left-24.svg'"></span>
          <span>{{ 'action.back_to_overview' | translate }}</span>
        </a>
      </p>
    </div>

    <div class="container">
      <h5>{{ 'pages.default.faq.faq' | translate }}</h5>
    </div>
  </div>
</div>

<div class="body-content">
  <div class="container" *ngIf="isLoading">
    <app-page-loader></app-page-loader>
  </div>

  <div class="container" *ngIf="!isLoading">

    @for(category of faqCategories; track category.title) {
      <app-faq-block
        [category]="category"
        (goToFaq)="goToFaq($event)"
      />

      <div class="my-8"></div>
    }
  </div>
</div>
