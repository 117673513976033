<div class="modal-body">
  <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="bsModalRef.hide()">
    <span [inlineSVG]="'./assets/svg/close-24.svg'"></span>
  </button>

  <h2>{{ 'modals.export_personal_data.export_personal_data' | translate }}</h2>
  <p class="m-0">{{ 'modals.export_personal_data.export_personal_data_info' | translate }}</p>
</div>

<div class="modal-footer">
  <div class="m-start-auto">
    <button class="btn btn-link" (click)="handleCancel()">{{ 'action.cancel' | translate }}</button>
      <button class="btn btn-secondary m-start-3" (click)="handleExport()" [ngClass]="{'loader': isLoading}">
        <span class="loader"><em class="spinner-border spinner-border-sm" *ngIf="isLoading"></em></span>
        <span>{{ 'modals.export_personal_data.export' | translate }}</span>
      </button>
  </div>
</div>

