<div class="layout-header-pusher header-back"></div>

<div class="layout-header header-back">
  <div class="header-content">
    <div class="container">
      <app-back-link route="/dashboard" label="action.back_to_overview" />
    </div>

    <div class="container">
      <h5>{{ 'pages.default.medical_team.medical_team' | translate }}</h5>
    </div>
  </div>
</div>

<div class="body-content">
  <div class="container" *ngIf="isLoading">
    <app-page-loader></app-page-loader>
  </div>

  <div class="container" *ngIf="!isLoading">
    <div class="row mb-5">
      <div class="col-6" *ngIf="clinicalLead">
        <p class="lead font-weight-semibold">
          {{ 'pages.default.medical_team.clinical_lead' | translate }}
        </p>

        <div class="participant-item item-lg bg-white">
          <app-avatar [profilePicture]="clinicalLead?.profile_picture"></app-avatar>

          <div class="meta">
            <p class="title">{{ clinicalLead.first_name }} {{ clinicalLead.last_name }}</p>
            <p class="description">{{ clinicalLead.job_title }}</p>
          </div>

          <div class="status-badge clinical-lead">
            {{ 'pages.default.medical_team.clinical_lead' | translate }}
          </div>
        </div>
      </div>

      <div class="col-6" *ngIf="caseManager">
        <p class="lead font-weight-semibold">
          {{ 'pages.default.medical_team.case_manager' | translate }}
        </p>

        <div class="participant-item item-lg bg-white">
          <app-avatar [profilePicture]="caseManager?.profile_picture"></app-avatar>

          <div class="meta">
            <p class="title">{{ caseManager.first_name }} {{ caseManager.last_name }}</p>
            <p class="description">{{ caseManager.job_title }}</p>
          </div>

          <div class="status-badge case-manager">
            {{ 'pages.default.medical_team.case_manager' | translate }}
          </div>
        </div>
      </div>
    </div>


    <ng-container *ngIf="hcps?.length">
      <p class="lead font-weight-semibold">
        {{ 'pages.default.medical_team.other_clinicians' | translate }}
      </p>

      <div class="row">
        <div class="col-4 mb-2" *ngFor="let hcp of hcps">

          <div class="participant-item item-lg bg-white">
            <app-avatar [profilePicture]="hcp?.profile_picture"></app-avatar>

            <div class="meta">
              <p class="title">{{ hcp?.getFullName() }}</p>
              <p class="description">{{ hcp?.job_title }}</p>
            </div>
          </div>

        </div>
      </div>
    </ng-container>

  </div>
</div>
