import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { FlowLayoutComponent } from './layouts/flow-layout/flow-layout.component';
import { DefaultLayoutComponent } from './layouts/default-layout/default-layout.component';
import { HomePageComponent } from './pages/flow/home-page/home-page.component';
import { DashboardPageComponent } from './pages/default/dashboard-page/dashboard-page.component';
import { DeviceNotSupportedPageComponent } from './pages/flow/device-not-supported-page/device-not-supported-page.component';
import { LinkInvalidPageComponent } from './pages/flow/link-invalid-page/link-invalid-page.component';
import { OnboardingConsentComponent } from './pages/flow/onboarding-consent/onboarding-consent.component';
import { OnboardingPasswordComponent } from './pages/flow/onboarding-password/onboarding-password.component';
import { OnboardingDetailsComponent } from './pages/flow/onboarding-details/onboarding-details.component';
import { LocaleRegistrationComponent } from './pages/flow/locale-registration/locale-registration.component';
import { GenericErrorComponent } from './pages/general/generic-error/generic-error.component';
import { SelfOnboardingCodeComponent } from './pages/flow/self-onboarding-code/self-onboarding-code.component';
import { SelfOnboardingConfirmComponent } from './pages/flow/self-onboarding-confirm/self-onboarding-confirm.component';
import { SelfOnboardingConsentComponent } from './pages/flow/self-onboarding-consent/self-onboarding-consent.component';
import { SelfOnboardingDetailsComponent } from './pages/flow/self-onboarding-details/self-onboarding-details.component';
import { SelfOnboardingApprovalComponent } from './pages/flow/self-onboarding-approval/self-onboarding-approval.component';
import { AuthGuard } from './helpers/auth.guard';
import { MfaCodeComponent } from './pages/flow/mfa-code/mfa-code.component';
import { GuestGuard } from './helpers/guest.guard';
import { ForgotPasswordComponent } from './pages/flow/forgot-password/forgot-password.component';
import { ForgotPasswordResetComponent } from './pages/flow/forgot-password-reset/forgot-password-reset.component';
import { ExpiredPasswordResetComponent } from './pages/flow/expired-password-reset/expired-password-reset.component';
import { SettingsPreferencesComponent } from './pages/default/settings/settings-preferences/settings-preferences.component';
import { SettingsPersonalComponent } from './pages/default/settings/settings-personal/settings-personal.component';
import { SettingsOverviewComponent } from './pages/default/settings/settings-overview/settings-overview.component';
import { SettingsConsentsOverviewComponent } from './pages/default/settings/settings-consents-overview/settings-consents-overview.component';
import { SettingsConsentDetailComponent } from './pages/default/settings/settings-consent-detail/settings-consent-detail.component';
import { SettingsSecurityComponent } from './pages/default/settings/settings-security/settings-security.component';
import { SettingsPrivacyComponent } from './pages/default/settings/settings-privacy/settings-privacy.component';
import { ConversationsComponent } from './pages/default/conversations/conversations.component';
import { CalendarComponent } from './pages/default/calendar/calendar.component';
import {MobileRedirectComponent} from './components/mobile-redirect/mobile-redirect.component';
import { MedicalTeamComponent } from './pages/default/medical-team/medical-team.component';
import { FaqOverviewComponent } from './pages/default/faq-overview/faq-overview.component';
import { FaqDetailComponent } from './pages/default/faq-detail/faq-detail.component';
import { PatientMaterialsComponent } from './pages/default/patient-materials/patient-materials.component';
import { MaterialDetailComponent } from './pages/default/material-detail/material-detail.component';
import { CookiePageComponent } from './pages/general/cookie-page/cookie-page.component';
import { HospitalComponent } from './pages/default/hospital/hospital.component';
import { TimelineComponent } from './pages/default/timeline/timeline.component';
import { PatientGoalsComponent } from './pages/default/patient-goals/patient-goals.component';
import { GoalDetailComponent } from './pages/default/goal-detail/goal-detail.component';
import { HelpCenterOverviewComponent } from './pages/default/help-center/help-center-overview/help-center-overview.component';
import { HelpCenterArticlesOverviewComponent } from './pages/default/help-center/help-center-articles-overview/help-center-articles-overview.component';
import { HelpCenterArticlesDetailComponent } from './pages/default/help-center/help-center-articles-detail/help-center-articles-detail.component';
import { HelpCenterFaqsOverviewComponent } from './pages/default/help-center/help-center-faqs-overview/help-center-faqs-overview.component';
import { HelpCenterFaqsDetailComponent } from './pages/default/help-center/help-center-faqs-detail/help-center-faqs-detail.component';
import { MaintenanceComponent } from './pages/general/maintenance/maintenance.component';
import { InactiveComponent } from './pages/general/inactive/inactive.component';
import { QueryListOverviewComponent } from './pages/default/query-list-overview/query-list-overview.component';
import { QueryListDetailComponent } from './pages/default/query-list-detail/query-list-detail.component';
import { QueryListCanDeactivateGuard } from './helpers/query-list-form.guard';
import { OnboardingRedirectToAppComponent } from './pages/flow/onboarding-redirect-to-app/onboarding-redirect-to-app.component';


export const routes: Routes = [
  {
    path: '',
    component: FlowLayoutComponent,
    children: [
      { path: '', component: HomePageComponent, pathMatch: 'full' },
      { path: 'portal-not-supported', component: DeviceNotSupportedPageComponent },
      { path: 'link-invalid', component: LinkInvalidPageComponent },

      { path: 'onboarding/language', component: LocaleRegistrationComponent},
      { path: 'onboarding/consent', component: OnboardingConsentComponent},
      { path: 'onboarding/password', component: OnboardingPasswordComponent},
      { path: 'onboarding/details', component: OnboardingDetailsComponent},

      { path: 'register', redirectTo: 'register/language', pathMatch: 'full' },
      { path: 'register/language', component: LocaleRegistrationComponent, canActivate: [GuestGuard] },
      { path: 'register/code', component: SelfOnboardingCodeComponent, canActivate: [GuestGuard] },
      { path: 'register/confirm-treatment', component: SelfOnboardingConfirmComponent, canActivate: [GuestGuard] },
      { path: 'register/consent', component: SelfOnboardingConsentComponent, canActivate: [GuestGuard] },
      { path: 'register/details', component: SelfOnboardingDetailsComponent, canActivate: [GuestGuard] },
      { path: 'register/approval', component: SelfOnboardingApprovalComponent, canActivate: [GuestGuard] },

      { path: 'security-code', component: MfaCodeComponent, canActivate: [AuthGuard] },
      { path: 'password-expired', component: ExpiredPasswordResetComponent, canActivate: [AuthGuard] },
      { path: 'forgot-password', component: ForgotPasswordComponent },
      { path: 'password-reset', component: ForgotPasswordResetComponent },

      { path: 'maintenance', component: MaintenanceComponent },
      { path: 'cookie-policy', component: CookiePageComponent },

      { path: 'mobile-redirect/:deepLinkUrl', component: MobileRedirectComponent}
    ]
  },

  {
    path: '',
    component: DefaultLayoutComponent,
    children: [
      { path: 'dashboard', component: DashboardPageComponent, canActivate: [AuthGuard] },
      { path: 'patient/:patientUid/:patientPathwayUid/learning-materials', component: PatientMaterialsComponent, canActivate: [AuthGuard] },
      { path: 'patient/:patientUid/:patientPathwayUid/learning-materials/article/:educationalMaterialUid', component: MaterialDetailComponent, canActivate: [AuthGuard] },
      { path: 'patient/:patientUid/:patientPathwayUid/faq', component: FaqOverviewComponent, canActivate: [AuthGuard] },
      { path: 'patient/:patientUid/:patientPathwayUid/faq/:id', component: FaqDetailComponent, canActivate: [AuthGuard] },
      { path: 'patient/:patientUid/:patientPathwayUid/medical-team', component: MedicalTeamComponent, canActivate: [AuthGuard] },
      { path: 'patient/:patientUid/:patientPathwayUid/timeline', component: TimelineComponent, canActivate: [AuthGuard] },
      { path: 'patient/:patientUid/:patientPathwayUid/goals', component: PatientGoalsComponent, canActivate: [AuthGuard] },
      { path: 'patient/:patientUid/:patientPathwayUid/query-lists', component: QueryListOverviewComponent, canActivate: [AuthGuard] },
      { path: 'patient/:patientUid/:patientPathwayUid/query-lists/:formUid', component: QueryListDetailComponent, canActivate: [AuthGuard], canDeactivate: [QueryListCanDeactivateGuard] },
      { path: 'patient/:patientUid/:patientPathwayUid/goal/:goalId', component: GoalDetailComponent, canActivate: [AuthGuard] },
      { path: 'patient/:patientUid/:patientPathwayUid/hospital', component: HospitalComponent, canActivate: [AuthGuard] },

      { path: 'settings', component: SettingsOverviewComponent, canActivate: [AuthGuard] },
      { path: 'settings/personal', component: SettingsPersonalComponent, canActivate: [AuthGuard] },
      { path: 'settings/preferences', component: SettingsPreferencesComponent, canActivate: [AuthGuard] },
      { path: 'settings/consents', component: SettingsConsentsOverviewComponent, canActivate: [AuthGuard] },
      { path: 'settings/consents/:consentKey', component: SettingsConsentDetailComponent, canActivate: [AuthGuard] },
      { path: 'settings/security', component: SettingsSecurityComponent, canActivate: [AuthGuard] },
      // { path: 'settings/privacy', component: SettingsPrivacyComponent, canActivate: [AuthGuard] },

      { path: 'conversations', component: ConversationsComponent, canActivate: [AuthGuard] },

      { path: 'calendar', component: CalendarComponent, canActivate: [AuthGuard] },

      { path: 'help-center', component: HelpCenterOverviewComponent, canActivate: [AuthGuard] },
      { path: 'help-center/articles', component: HelpCenterArticlesOverviewComponent, canActivate: [AuthGuard] },
      { path: 'help-center/articles/:articleUid', component: HelpCenterArticlesDetailComponent, canActivate: [AuthGuard] },
      { path: 'help-center/faqs', component: HelpCenterFaqsOverviewComponent, canActivate: [AuthGuard] },
      { path: 'help-center/faqs/:faqUid', component: HelpCenterFaqsDetailComponent, canActivate: [AuthGuard] },
    ]
  },

  { path: 'inactive', component: InactiveComponent },
  { path: 'error', component: GenericErrorComponent },
  { path: 'error/:code', component: GenericErrorComponent },
  { path: 'onboarding/redirect-to-app', component: OnboardingRedirectToAppComponent },

  { path: '**', redirectTo: '' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {}
