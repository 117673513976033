import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { MobileNumber } from '../../interfaces/mobile-number.interface';
import { GeneralService } from '../../services/general.service';
import { SelfRegistrationService } from '../../services/self-registration.service';
import { UserService } from '../../services/user.service';

@Component({
  selector: 'app-mobile-verification-code-modal',
  templateUrl: './mobile-verification-code-modal.component.html'
})
export class MobileVerificationCodeModalComponent implements OnInit {
  @Output() verificationCodeReceived: EventEmitter<any> = new EventEmitter();

  public validationVisible: boolean;
  public form: UntypedFormGroup;
  public isLoading = false;
  public mobileNumber: MobileNumber;
  public mode: string;
  public code: string;
  public language: string;

  constructor(
    public bsModalRef: BsModalRef,
    public formBuilder: UntypedFormBuilder,
    public generalService: GeneralService,
    public router: Router,
    public toastrService: ToastrService,
    public translate: TranslateService,
    public userService: UserService,
    public selfRegistrationService: SelfRegistrationService
  ) {}

  ngOnInit() {
    this.formSetup();
    this.requestMobileVerificationCode(this.mobileNumber);
  }

  formSetup() {
    if (this.form) {
      return;
    }

    this.form = this.formBuilder.group({
      code: ['', [Validators.required]]
    });
  }

  handleCancel() {
    return this.bsModalRef.hide();
  }

  handleSubmit() {
    this.validationVisible = false;
    if (this.form.get('code').errors) {
      this.validationVisible = true;
      return;
    } else {
      this.verificationCodeReceived.emit(this.form.get('code').value);
      this.bsModalRef.hide();
    }
  }

  requestMobileVerificationCode(mobile_number: MobileNumber) {
    if (this.mode === 'onboarding') {
      this.userService.verifyMobileNumber(mobile_number).subscribe(resp => {
        this.toastVerificationCodeSent();
      });
    } else if (this.mode === 'self-onboarding') {
      this.selfRegistrationService.verifyMobileNumber(mobile_number, this.code, this.language).subscribe(resp => {
        this.toastVerificationCodeSent();
      });
    }
  }

  toastVerificationCodeSent() {
    this.toastrService.info(this.translate.instant('modals.edit_contact_details.verification_code_sent'));
  }

  resendCode(event) {
    event.preventDefault();
    this.requestMobileVerificationCode(this.mobileNumber);
  }
}
