<div class="modal-header">
  <h6 class="h7">{{ 'modals.surgery_date.title' | translate }}</h6>
  <button type="button" class="btn-close" data-dismiss="modal" aria-label="Close" (click)="bsModalRef.hide()"></button>
</div>

<div class="modal-body">
  <p class="mb-0">{{ 'modals.surgery_date.description' | translate }}</p>
</div>

<div class="modal-footer">
    <button class="btn btn-primary m-start-auto" (click)="bsModalRef.hide()">{{ 'action.close' | translate }}</button>
</div>
