import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {Appointment} from "../../models/appointment";
import {DateFormat} from "../../models/date-format";
import {UserService} from "../../services/user.service";
import {GeneralService} from "../../services/general.service";
import {BsModalRef} from "ngx-bootstrap/modal";
import {PatientService} from "../../services/patient.service";
import { AppointmentService } from '../../services/appointment.service';
import { LocaleService } from '../../services/locale.service';
import { AttentionCenterService } from '../../services/attention-center.service';
import { LanguageService } from '../../services/language.service';

@Component({
  selector: 'app-appointment-modal',
  templateUrl: './appointment-modal.component.html',
  styleUrls: ['./appointment-modal.component.scss']
})
export class AppointmentModalComponent implements OnInit {
  @Output() appointmentSubmitSuccess: EventEmitter<any> = new EventEmitter();

  public appointment: Appointment;
  public day: string;
  public dateFormat: DateFormat;
  public time_24_hours: boolean;
  public showLoaderFor: string;


  constructor(
    public bsModalRef: BsModalRef,
    public generalService: GeneralService,
    public patientService: PatientService,
    public userService: UserService,
    public appointmentService: AppointmentService,
    public localService: LocaleService,
    public languageService: LanguageService,
    public attentionCenterService: AttentionCenterService
  ) {
  }

  ngOnInit(): void {
    this.appointmentService.getAppointment(this.appointment.uid).subscribe((appointment: Appointment) => {
      if(this.appointment.status_of_user !== appointment.status_of_user) {
        this.appointmentService.appointmentStatusChanged.next();
        this.attentionCenterService.refresh();
      }

      this.appointment = appointment;
    });
  }

  handleYes() {
    this.changeAppointment(AppointmentService.StatusAppointmentAccepted);
  }

  handleNo() {
    this.appointmentService.declineAppointmentClickedSource.next(this.appointment);
    this.bsModalRef.hide();
  }

  handleDecline() {
    this.appointmentService.declineAppointmentClickedSource.next(this.appointment);
    this.bsModalRef.hide();
  }

  handleRemove() {
    this.changeAppointment(AppointmentService.StatusAppointmentRemoved);
  }

  changeAppointment(status) {
    if (this.showLoaderFor) {
      return;
    }

    this.showLoaderFor = status;

    this.appointmentService.changeAppointment(this.appointment, status).subscribe(result => {
      this.showLoaderFor = undefined;
      this.bsModalRef.hide();
      this.appointment.status_of_user = status;
      this.appointmentSubmitSuccess.emit();
    });
  }
}
