import { AbstractControl } from '@angular/forms';
import { PasswordPolicy } from '../models/password-policy';

export class PasswordPolicyValidator {
  static validate(policy: PasswordPolicy) {
    return (control: AbstractControl): {[key: string]: any} => {
      let hasErrors = false;
      const numeric = /\d/;
      const specials = /[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;
      const lowercase = /[a-z]/;
      const uppercase = /[A-Z]/;

      const errors: any = {};

      //
      // Min length
      //

      if(String(control.value).length < policy.min_length) {
        hasErrors = true;
        errors.password_policy_min_length = true;
      }

      //
      // Max length
      //

      if(String(control.value).length > policy.max_length) {
        hasErrors = true;
        errors.password_policy_max_length = true;
      }

      //
      // Use numbers
      //

      if(policy.use_numbers === "REQUIRED" && !numeric.test(String(control.value))) {
        hasErrors = true;
        errors.password_policy_use_numbers = true;
      }

      if(policy.use_numbers === "DISABLED" && numeric.test(String(control.value))) {
        hasErrors = true;
        errors.password_policy_use_numbers = true;
      }

      //
      // Use specials
      //

      if(policy.use_specials === "REQUIRED" && !specials.test(String(control.value))) {
        hasErrors = true;
        errors.password_policy_use_specials = true;
      }

      if(policy.use_specials === "DISABLED" && specials.test(String(control.value))) {
        hasErrors = true;
        errors.password_policy_use_specials = true;
      }

      //
      // Use lowercase
      //

      if(policy.use_lowercase === "REQUIRED" && !lowercase.test(String(control.value))) {
        hasErrors = true;
        errors.password_policy_use_lowercase = true;
      }

      if(policy.use_lowercase === "DISABLED" && lowercase.test(String(control.value))) {
        hasErrors = true;
        errors.password_policy_use_lowercase = true;
      }

      //
      // Use uppercase
      //

      if(policy.use_uppercase === "REQUIRED" && !uppercase.test(String(control.value))) {
        hasErrors = true;
        errors.password_policy_use_uppercase = true;
      }

      if(policy.use_uppercase === "DISABLED" && uppercase.test(String(control.value))) {
        hasErrors = true;
        errors.password_policy_use_uppercase = true;
      }

      if(hasErrors) {
        errors.password_policy = true;
        return errors;
      } else {
        return {};
      }
    };
  }
}
