<div class="modal-header">
  <h6 class="h7">
    <span *ngIf="userTask?.title">{{ userTask?.translationTitleKey | translate | evalTranslation }}</span>
    <span *ngIf="!userTask?.title">...</span>
  </h6>

  <button type="button" class="btn-close" id="edit-personal-modal-back" data-dismiss="modal" aria-label="Close" (click)="bsModalRef.hide()"></button>
</div>

<div class="modal-body user-task-standard-modal">
  <p *ngIf="userTask?.description">{{ userTask?.translationDescriptionKey | translate | evalTranslation }}</p>

  <div *ngIf="isLoading">
    <app-page-loader [padding]="'20px 0'"></app-page-loader>
  </div>

  <div *ngIf="!isLoading">
    <app-user-task-form [userTask]="userTask" [isReadOnly]="false" [validationVisible]="validationVisible"
      [(form)]="form"></app-user-task-form>
  </div>
</div>

<div class="modal-footer">
  <div class="m-start-auto">
    <button class="btn btn-secondary" id="user-task-standard-cancel" (click)="handleCancel()">{{ 'action.cancel' | translate
      }}</button>

    <button class="btn btn-primary m-start-2" id="user-task-standard-submit" [ngClass]="{'loader': isSubmitting}"
      (click)="handleSubmit()" [disabled]="!userTask">
      <span class="loader" *ngIf="isSubmitting" [inlineSVG]="'./assets/svg/loader-dark-sm.svg'"></span>
      <span>{{ 'action.submit' | translate }}</span>
    </button>
  </div>
</div>
