<div class="article-content" [ngClass]="{'no-paddings': noPaddings}">
  <!-- content-head - start -->
  <!-- (should always be here, is not in the repeater loop) -->
  <div class="content-head">
    <h1 class="m-0">{{ faq?.question }}</h1>
  </div>
  <!-- content-head - end -->
  <ng-container *ngFor="let answer of faq?.answer">
    <div class="content-partial" *ngIf="answer['text']" [innerHtml]="answer.text.text"></div>
    <div class="content-image" *ngIf="answer['image']">
      <img [src]="answer.image.file.url + '?width=1640&disable=upscale&format=pjpg&auto=webp'">
    </div>
  </ng-container>

  <ng-container *ngIf="category?.items.length > 1">
    <div class="mt-7 mt-lg-8">
      <p class="label large font-weight-semibold">
        {{ 'pages.helpcenter.faq_detail.other_in' | translate }} {{ category?.title }}
      </p>

      <app-faq-block
        [showHeading]="false"
        [class]="'mb-0'"
        [category]="category"
        [skip]="faq?.uid"
        (goToFaq)="goToFaq.emit($event)"
      />
    </div>
  </ng-container>
</div>
