<div class="modal-header">
  <h6 class="h7">{{ pathwayMessage?.translationKey | translate }}</h6>

  <button type="button" class="btn-close" data-dismiss="modal" aria-label="Close" (click)="handleClose()"></button>
</div>

<div class="modal-body">
  <div *ngIf="isLoading">
    <app-page-loader [padding]="'30px 0'"></app-page-loader>
  </div>

  <ng-container *ngIf="!isLoading">
    <ng-container *ngFor="let text of pathwayMessage?.message_content">
      <div [innerHtml]="text"></div>
    </ng-container>
  </ng-container>
</div>


<div class="modal-footer">
  <div class="m-start-auto">
    <button class="btn btn-primary" (click)="handleClose()">{{ 'action.close' | translate }}</button>
  </div>
</div>
