import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormField } from '../../models/form-field';
import { FFUiIconSource } from '../../models/form-field-ui-config';

@Component({
  selector: 'app-styled-enum',
  templateUrl: './styled-enum.component.html',
})
export class StyledEnumComponent {
  @Input() field: FormField;
  @Input() value: any;
  @Output() valueChange = new EventEmitter<any>();

  public IconSource = FFUiIconSource;

  getColorFor(value): string {
    if (this.field.ui_config.hasColors) {
      return this.field.ui_config.getColorFor(value);
    }
  }

  getIconFor(value) {
    if (this.field.ui_config.hasIcons) {
      return this.field.ui_config.getIconFor(value);
    }
  }

  onValueChange(e) {
    this.value = e;
    this.valueChange.emit(e);
  }

  onMultiValueChange(value: string) {
    if (!this.value) {
      this.value = [];
    }

    this.value = this.field.multiValueChange(value, this.value);

    this.valueChange.emit(this.value);
  }
}
