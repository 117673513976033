<form [formGroup]="form" id="form" class="form-width-limit" [ngClass]="{'validation-visible': validationVisible}"
  autocomplete="off">

  <div class="form-width-limit-element">
    <label class="small mb-1">{{ 'form.labels.profile_picture' | translate }}</label>

    <div class="control-profile-picture mb-3">
      <app-avatar-input formControlName="profile_picture"></app-avatar-input>
    </div>
  </div>

  <!-- Gender -->
  <div class="form-group mb-n2 form-no-width-limit-element">
    <label class="required">{{ 'form.labels.gender' | translate }}</label>

    <div class="form-checks-inline">
      <div class="form-check">
        <input type="radio" class="form-check-input" id="radioSexMale" formControlName="gender" value="MALE">
        <label class="form-check-label" for="radioSexMale">{{ 'form.labels.male' | translate }}</label>
      </div>

      <div class="form-check">
        <input type="radio" class="form-check-input" id="radioSexFemale" formControlName="gender" value="FEMALE">
        <label class="form-check-label" for="radioSexFemale">{{ 'form.labels.female' | translate }}</label>
      </div>

      <div class="form-check">
        <input type="radio" class="form-check-input" id="radioSexOther" formControlName="gender" value="UNKNOWN">
        <label class="form-check-label" for="radioSexOther">{{ 'form.labels.other' | translate }}</label>
      </div>

      <app-feedback-field [field]="form.get('gender')" [extraClass]="'general-feedback'"></app-feedback-field>
    </div>
  </div>

  <div class="form-group ">
    <label for="inputFirstName" class="required">{{ 'form.labels.first_name' | translate }}</label>
    <input type="text" id="inputFirstName" class="form-control" formControlName="first_name">
    <app-feedback-field [field]="form.get('first_name')"></app-feedback-field>
  </div>

  <div class="form-group ">
    <label for="inputPreferredName">{{ 'form.labels.preferred_name' | translate }}</label>
    <input type="text" id="inputPreferredName" class="form-control w-100" formControlName="preferred_name">
    <app-feedback-field [field]="form.get('preferred_name')"></app-feedback-field>
  </div>

  <div class="form-group ">
    <label for="inputLastName" class="required">{{ 'form.labels.last_name' | translate }}</label>
    <input type="text" id="inputLastName" class="form-control w-100" formControlName="last_name">
    <app-feedback-field [field]="form.get('last_name')"></app-feedback-field>
  </div>

  <div class="form-group required">
    <app-select
      id="select_details_form_country"
      [placeholder]="('form.labels.country' | translate)"
      [clearable]="false"
      [searchable]="true"
      [hideSelected]="false"
      [multiple]="false"
      [items]="countryOptions"
      [bindValue]="'value'"

      formControlName="country"

      [autoSortOnLabel]="true"
      [selectedItemsOnTop]="false"

      [asFilter]="false"
      [showCheckboxes]="false"
    ></app-select>
    <app-feedback-field [field]="form.get('country')"></app-feedback-field>
  </div>

  <div class="form-group required">
    <app-select id="select_details_form_language" [placeholder]="('form.labels.preferred_language' | translate)"
      [clearable]="false" [searchable]="true" [hideSelected]="false" [multiple]="false" [items]="languageOptions"
      formControlName="language" [autoSortOnLabel]="true" [selectedItemsOnTop]="false" [asFilter]="false"
      [showCheckboxes]="false"></app-select>

    <app-feedback-field [field]="form.get('language')"></app-feedback-field>
  </div>

  <div class="form-group  mb-0">
    <label for="inputBirth" class="required">{{ 'form.labels.date_of_birth' | translate }}</label>
    <input type="text" id="inputBirth" class="form-control" bsDatepicker formControlName="date_of_birth">
  </div>

  <hr />

  <div class="form-group ">
    <label for="inputEmail" class="required">{{ 'form.labels.email_address' | translate }}</label>
    <input type="email" id="inputEmail" class="form-control" formControlName="email">
    <app-feedback-field [field]="form.get('email')"></app-feedback-field>
  </div>

  <div class="form-width-limit-element mt-2">
    <div class="row" formGroupName="phone_number">
      <div class="col-6" [ngClass]="{'order-last': isLocaleHebrew()}">
        <div class="form-group">
          <app-select id="inputCountryCode" [placeholder]="('form.labels.country_code' | translate)" [clearable]="false"
            [searchable]="true" [hideSelected]="false" [multiple]="false" [items]="dialCodeOptions" [bindValue]="'value'"
            (change)="phoneValueChanged()" [autoSortOnLabel]="true" [selectedItemsOnTop]="false" formControlName="code"
            [asFilter]="false" [showCheckboxes]="false"></app-select>
        </div>
      </div>
      <div class="col-6" [ngClass]="{'order-first': isLocaleHebrew()}">
        <div class="form-group ">
          <label for="inputPhone">{{ 'form.labels.phone_number' | translate }}</label>
          <input type="tel" id="inputPhone" class="form-control" formControlName="number">
          <p class="validation-feedback" *ngIf="form?.controls['phone']?.errors?.phone_is_wrong">{{
            'form.feedback.phone_wrong_format' | translate }}</p>
        </div>
      </div>
    </div>
    <app-feedback-field [field]="form.get('phone_number')"></app-feedback-field>
  </div>

  <!--
  <div class="position-relative">
    <div class="custom-control custom-checkbox custom-control-inline mb-1">
      <input type="checkbox" class="custom-control-input" id="checkboxNotificationsTextMessage" formControlName="notifications_method_text" value="TEXT" (change)="smsNotificationChanged($event)">
      <label class="custom-control-label" for="checkboxNotificationsTextMessage">{{ 'components.onboarding_details_form.text_message' | translate }}</label>
    </div>
  </div>
  -->


  <!--  -->
  <!-- HIDDEN BY D-NONE - AS PER REQUEST OF: AGIK-5006 -->
  <!--  -->
  <div class="position-relative mb-2 d-none" *ngIf="contactConsented">
    <label class="small d-block">{{ 'components.onboarding_details_form.preferred_contact_method' | translate }}</label>

    <div class="custom-control custom-radio custom-control-inline mb-1">
      <input type="radio" class="custom-control-input" id="radioContactEmail" formControlName="contact_channel"
        value="EMAIL">
      <label class="custom-control-label" for="radioContactEmail">{{ 'form.labels.email' | translate }}</label>
    </div>

    <div class="custom-control custom-radio custom-control-inline mb-1" *ngIf="isPhoneComplete()">
      <input type="radio" class="custom-control-input" id="radioContactPhone" formControlName="contact_channel"
        value="PHONE">
      <label class="custom-control-label" for="radioContactPhone">{{ 'form.labels.phone' | translate }}</label>
    </div>

    <div class="custom-control custom-radio custom-control-inline mb-1" *ngIf="!isPhoneComplete()">
      <input type="radio" class="custom-control-input" id="radioContactPhoneDisabled" disabled="true">
      <label class="custom-control-label" for="radioContactPhoneDisabled">{{ 'form.labels.phone' | translate }}</label>
    </div>
  </div>

  <hr />

  <p class="small text-muted">{{ 'components.onboarding_details_form.notifications' | translate }}</p>

  <div class="form-check">
    <input type="checkbox" class="form-check-input" id="checkboxNotificationsEmail" formControlName="notifications_method_email" value="EMAIL">
    <label class="form-check-label" for="checkboxNotificationsEmail">{{'components.onboarding_details_form.email_notifications_channel' | translate }}</label>
  </div>

  <p class="small text-muted"><i>{{ 'components.onboarding_details_form.push_notifications_message' | translate }}</i></p>

</form>
