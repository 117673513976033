<div class="page-tabs" #wrapper>
  <ul class="inline-nav">
    <li *ngFor="let item of items" #viewItems class="invisible-item" [ngClass]="{'active': activeItem === item}">
      <a href="" (click)="clickHandler($event, item)" [id]="['page-tabs-' + item?.htmlID]">
        <span>
          {{ item?.translationKey | translate | evalTranslation }}
<!--          <ng-container *ngIf="item?.badge">({{item?.badge}})</ng-container>-->
        </span>
      </a>
    </li>
  </ul>

  <div class="dropdown" dropdown placement="top right" #moreBtn id="tabsMoreBtn">
    <button id="tabsMoreBtnToggle" dropdownToggle type="button" class="inline-nav-more-btn"
            aria-controls="tabsMoreBtnMenu">
      <span>More <em [inlineSVG]="'./assets/svg/chevron-down-24.svg'" class="icon m-start-1"></em></span>
    </button>
    <ul id="tabsMoreBtnMenu" *dropdownMenu class="dropdown-menu dropdown-menu-right" role="menu" aria-labelledby="tabsMoreBtnToggle">
      <li role="menuitem" *ngFor="let item of moreItems" [ngClass]="{'active': activeItem === item}">
        <a class="dropdown-item" href="" (click)="clickHandler($event, item)">
          <span>{{ item?.translationKey | translate | evalTranslation }}</span>
        </a>
      </li>
    </ul>
  </div>

</div>
