import { Component, OnInit, ViewChild, ElementRef, HostListener, Output, EventEmitter, Input } from '@angular/core';
import { AppointmentSelectionEvent } from '../../events/appointment-selection-event';
import moment, { Moment } from 'moment';
import 'moment/min/locales';
import { AppointmentService } from '../../services/appointment.service';
import { Appointment } from '../../models/appointment';
import { Profile } from '../../models/profile';
import { AttentionCenterService } from '../../services/attention-center.service';
import { Subscription } from 'rxjs';

class MonthDay {
  label: string;
  key: string;
  appointments: Array<Appointment> = [];
}

@Component({
  selector: 'app-calendar-month-view',
  templateUrl: './calendar-month-view.component.html',
  styleUrls: ['./calendar-month-view.component.scss']
})
export class CalendarMonthViewComponent implements OnInit {
  @Output() onAppointmentSelect: EventEmitter<AppointmentSelectionEvent> = new EventEmitter();
  @Output() onMore: EventEmitter<Date> = new EventEmitter();
  @Input() timeZone: string;

  @ViewChild('monthDaysContainer') monthDaysContainer: ElementRef;

  public appointmentServiceSubscription;
  public onNewAppointmentsPendingSubscription: Subscription;
  public isLoading: boolean;

  private _currentMonthFirstDay;
  get currentMonthFirstDay(): Moment {
    return this._currentMonthFirstDay;
  }
  @Input()
  set currentMonthFirstDay(val: Moment) {
    this._currentMonthFirstDay = val;
    this._currentMonthFirstDay.status = 'In Process';
    this.fillUpDays();
    this.setDays();
    this.getAppointments();

    let totalBlocks = this.days.length;
    totalBlocks += this.fillUpDaysAtStart;
    totalBlocks += this.fillUpDaysAtEnd;
    this.storeTotalBlocksCount(totalBlocks);
  }

  public totalBlocksCount;
  public maxEventsPerBlock;
  public blockHeight;
  public fillUpDaysAtStart: number;
  public fillUpDaysAtEnd: number;
  public days = [];
  public weekdays = [];
  public today = moment();
  private appointmentsFetchInterval: any;

  constructor(
    public appointmentService: AppointmentService,
    public attentionCenterService: AttentionCenterService,
  ) { }

  ngOnInit() {
    this.setWeekDays();
    this.appointmentService.appointmentChanged$.subscribe(appointment => {
      if (appointment.status_of_user === AppointmentService.StatusAppointmentRemoved || appointment.status_of_user === AppointmentService.StatusAppointmentDeclined) {
        this.removeAppointmentFromDay(appointment);
      } else {
        this.appointmentChanged(appointment);
      }
    });

    this.appointmentService.appointmentStatusChanged$.subscribe(() => this.getAppointments(false));

    this.appointmentsFetchInterval = setInterval(() => {
      this.getAppointments(false);
    }, 60000);

    this.onNewAppointmentsPendingSubscription = this.attentionCenterService.onNewAppointmentsPending.subscribe(() => {
      this.getAppointments(false);
    });
  }

  appointmentChanged(appointment) {
    const appointmentKey = moment(appointment.start_date).tz(this.timeZone).format('DMMYYYY');
    const days = this.days.filter((day: MonthDay) => {
      return (day.key === appointmentKey);
    });

    if (days.length > 0) {
      const foundAppointments = days[0].appointments.filter(item => item.uid === appointment.uid);

      if (foundAppointments && foundAppointments.length) {
        const foundAppointment: Appointment = foundAppointments[0];
        foundAppointment.merge(appointment);
      }
    }
  }

  ngOnDestroy() {
    clearInterval(this.appointmentsFetchInterval);
    this.onNewAppointmentsPendingSubscription?.unsubscribe();
  }

  storeTotalBlocksCount(count: number) {
    this.totalBlocksCount = count;

    setTimeout(() => {
      this.calculateEventsPerBlock();
    });
  }

  setWeekDays() {
    this.weekdays = moment.weekdays(true);
  }

  fillUpDays() {
    this.fillUpDaysAtStart = this.currentMonthFirstDay.weekday();
    this.fillUpDaysAtEnd = 6 - this.currentMonthFirstDay.clone().endOf('M').weekday();
  }

  setDays() {
    this.days = [];
    const daysInMonth = this.currentMonthFirstDay.daysInMonth();
    for (let _i = 1; _i < (daysInMonth + 1); _i++) {
      const day: MonthDay = new MonthDay();
      day.label = _i.toString();
      day.key = _i + (this.currentMonthFirstDay.month() + 1).toString() + this.currentMonthFirstDay.year().toString();
      this.days.push(day);
    }
  }

  getAppointments(loader = true) {
    const startDate = this.currentMonthFirstDay;
    const endDate = this.currentMonthFirstDay.clone().endOf('M');

    if (loader) {
      this.isLoading = true;
    }

    if (this.appointmentServiceSubscription) {
      this.appointmentServiceSubscription.unsubscribe();
    }

    this.appointmentServiceSubscription = this.appointmentService.getAllAppointments(startDate, endDate).subscribe(result => {
      this.appointmentServiceSubscription = undefined;
      this.isLoading = false;
      this.setDays();
      result.forEach(appointment => {
        this.addAppointmentToDay(appointment);
      });

      setTimeout(() => {
        this.calculateEventsPerBlock();
      });
    });
  }

  removeAppointmentFromDay(appointment) {
    const appointmentKey = this.dateToKey(appointment.start_date);
    const days = this.days.filter((day: MonthDay) => {
      return (day.key === appointmentKey);
    });

    if (days.length > 0) {
      const foundAppointments = days[0].appointments.filter(item => item.uid === appointment.uid);

      if (foundAppointments && foundAppointments.length) {
        const foundAppointment: Appointment = foundAppointments[0];
        days[0].appointments.splice(days[0].appointments.indexOf(foundAppointment), 1);
      }
    }
  }

  addAppointmentToDay(appointment) {
    const appointmentKey = this.dateToKey(appointment.start_date || appointment.date);
    const days = this.days.filter((day: MonthDay) => {
      return (day.key.toString() === appointmentKey.toString());
    });

    if (days.length > 0) {
      days[0].appointments.push(appointment);
    }
  }

  @HostListener('window:resize')
  onResize() {
    this.calculateEventsPerBlock();
  }

  calculateEventsPerBlock() {
    if (!this.totalBlocksCount || !this.monthDaysContainer) {
      return;
    }

    const height = this.monthDaysContainer.nativeElement.offsetHeight;
    const rows = Math.ceil(this.totalBlocksCount / 7);

    const averageHeight = height / rows;
    const averageOccupiedHeight = 70;
    const averageEventHeight = 24;

    this.blockHeight = 100 / rows;
    this.maxEventsPerBlock = Math.floor((averageHeight - averageOccupiedHeight) / averageEventHeight);
  }

  getVisibleItems(list: any[]) {
    return {
      visible: list.slice(0, this.maxEventsPerBlock),
      invisible: list.slice(this.maxEventsPerBlock, list.length)
    };
  }

  onMoreClicked(event, date) {
    event.preventDefault();
    this.onMore.emit(new Date(date));
    this.appointmentService.moreAppointmentsClickedSource.next(new Date(date));
  }

  selectAppointment(event, appointment) {
    event.preventDefault();
    this.onAppointmentSelect.emit(new AppointmentSelectionEvent(event, appointment));
  }

  toTime(dateString) {
    const storageProfile = localStorage.getItem('profile');

    if (storageProfile) {
      let timeFormat;
      const profile = JSON.parse(storageProfile) as Profile;

      if (profile.locale.time_24_hours) {
        timeFormat = 'HH:mm';
      } else {
        timeFormat = 'hh:mm A';
      }

      return moment(dateString).tz(this.timeZone).format(timeFormat);
    } else {
      return '';
    }
  }

  isToday(day) {
    const today = this.dateToKey(this.today);
    return today === day;
  }

  private dateToKey(date) {
    return moment(date).tz(this.timeZone).date() + '' + (moment(date).tz(this.timeZone).month() + 1) + '' + moment(date).tz(this.timeZone).year();
  }
}
