import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { UserTask } from '../../models/user-task';
import { AuthenticationService } from '../../services/authentication.service';
import { UserTaskService } from '../../services/user-task.service';
import { FormField } from '../../models/form-field';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';

@Component({
  selector: 'app-user-task-form',
  standalone: false,
  templateUrl: './user-task-form.component.html',
  styleUrl: './user-task-form.component.scss'
})
export class UserTaskFormComponent implements OnInit {
  @Input() validationVisible: boolean;
  @Input() isReadOnly: boolean;
  @Input() userTask: UserTask;
  @Input() form: FormGroup;
  @Output() formChange: EventEmitter<FormGroup> = new EventEmitter<FormGroup>();

  public isEvaluating = false;

  constructor(
    public authenticationService: AuthenticationService,
    public userTaskService: UserTaskService,
  ) {
  }

  ngOnInit() {
    this.initForm();
  }

  initForm() {
    if (this.userTask?.form_fields) {
      this.userTask.form_fields.forEach(field => {
        this.createControl(field);
      });
    }

    if (this.userTask?.sections) {
      this.userTask.sections.forEach(section => {
        section.form_fields.forEach(field => {
          this.createControl(field);
        });
      });
    }

    if (this.isReadOnly) {
      this.form.disable();
    }

    if (this.userTask.hasDff) {
      this.evaluateControls();
    }
  }

  createControl(field: FormField) {
    const control = new FormControl(null, []);

    if (field.read_only) {
      control.disable();
    }

    control.setValidators(field.validators);

    if (field.field_value?.value !== undefined && field.field_value?.value !== null) {
      control.setValue(field.field_value?.value);
    }

    if (field.evaluation_required) {
      // Trigger evaluateControls() when value is updated
      control?.valueChanges.pipe(
        debounceTime(400),
        distinctUntilChanged(),
      ).subscribe(() => {
        if (this.form.get(field.id)?.dirty) {
          this.evaluateControls();
        }
      });
    }

    this.form.addControl(field.id, control);
    this.form.get(field.id)?.updateValueAndValidity();
  }

  evaluateControls() {
    const data = this.userTaskService.mapPayload(this.form?.value);

    if ((data && Object.keys(data).length <= 0) || this.isReadOnly) {
      return;
    }

    this.isEvaluating = true;
    this.userTaskService.evaluateUserTask(this.userTask.uid, this.userTask.patient?.uid, data).subscribe({
      next: result => {
        this.handleEvaluateControls(result);
        this.isEvaluating = false;
      },
      error: () => this.isEvaluating = false
    });
  }

  handleEvaluateControls(result: any) {
    this.userTask.form_fields.forEach(field => {
      field.visible = result.visible_fields.find(f => f.field_id === field.id && f.visible) !== undefined;
      this.evalControl(field);
    });

    this.userTask.sections.forEach(section => {
      section.visible = result.visible_sections.find(s => s.section_id === section.id && s.visible) !== undefined;

      section.form_fields.forEach(field => {
        field.visible = result.visible_fields.find(f => f.field_id === field.id && f.visible) !== undefined;
        this.evalControl(field);
      });
    });
  }

  evalControl(field: FormField) {
    if (!field.visible) {
      this.form.get(field.id)?.reset();
      this.form.get(field.id)?.clearValidators();
    } else {
      this.form.get(field.id)?.setValidators(field.validators);
    }

    this.form.get(field.id)?.updateValueAndValidity();

    // if (!field.visible) {
    //   this.form.get(field.id)?.reset();
    // }
    //
    // this.form.get(field.id)?.setValidators(field.validators);
    // this.form.get(field.id)?.updateValueAndValidity();
  }

  getVisibleFormFields() {
    return this.userTask?.form_fields.filter(field => field?.visible === true) || [];
  }
}
