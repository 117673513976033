<div class="heading-block mb-3 help-center-contact" *ngIf="!compact">
  <div class="block-wrapper overflow-hidden">
    <h5 class="text-truncate">{{ helpcenterSection?.title }}</h5>
    <p class="text-truncate">{{ helpcenterSection?.subtitle }}</p>
  </div>
</div>

<div class="row gy-2">
  <div *ngFor="let contact of helpcenterSection?.contacts; last as last" [ngClass]="compact ? 'col-12' : 'col-4'">
    <div class="card h-100">
      <div class="card-body">
        <div class="d-flex align-items-center mb-2">
          <img [src]="contact?.icon" class="img-icon m-end-2">
          <p class="mb-0 large font-weight-semibold">{{ contact?.title }}</p>
        </div>

        <p>{{ contact?.description }}</p>

        @if (contact.isPhone) {
          <app-select
            id="dropdown-btn-countries"
            [selectClass]="'text-select bold-select my-1'"
            [clearable]="false"
            [searchable]="false"
            [hideSelected]="false"
            [multiple]="false"
            [asFilter]="false"
            [items]="countries"
            [(ngModel)]="currentCountry"
            dropdownPosition="top"
          />

          <p class="small text-muted" [ngClass]="{'mb-0': !currentCountry?.description}">{{ currentCountry?.description }}</p>

          <ul class="list-style-none mb-0">
            <li *ngFor="let phone of currentCountry?.phones">
              <p class="d-flex align-items-center mb-1">
                <span class="small" *ngIf="phone.description">{{ phone?.description }}: </span>
                <span class="font-weight-semibold m-start-auto text-info">{{ phone?.number }}</span>
              </p>
              <p class="small text-muted mt-1 mb-0">{{ phone?.disclaimer }}</p>
            </li>
          </ul>
        }
      </div>

      @if (contact.hyperlink) {
        <div class="card-footer border-0">
          <a
            href="{{ contact.hyperlink }}"
            class="btn btn-tertiary btn-icon btn-lg m-start-auto"
            target="{{(contact.isChat)? '_blank' : '_self'}}"
            rel="noopener noreferrer"
          >
            <span class="rtl-mirrored-inline-svg" [inlineSVG]="'./assets/svg/arrow-right-24.svg'"></span>
          </a>
        </div>
      }
    </div>
  </div>
</div>
