<div class="layout-header-pusher header-back-tabs-progress"></div>

<div class="layout-header header-back-tabs-progress">
  <div class="header-content">
    <div class="container">
      <app-back-link route="/dashboard" label="action.back_to_overview" id="materials-overview-back" />
    </div>

    <div class="container my-auto">
      <h5>{{ 'pages.default.patient_materials.learning_materials' | translate }}</h5>
    </div>

    <div class="container">
      <app-page-tabs [asClassicTabs]="true" [items]="pageTabItems" (onTabChange)="onTabChangeHandler($event)"></app-page-tabs>
    </div>

    <div class="wide-progress-bar-wrapper">
      <div class="container">
        <div class="progress">
          <div class="progress-bar" role="progressbar" [style.width]="selectedPhase?.percent()"></div>
        </div>
        <label *ngIf="selectedPhase">{{ 'pages.default.patient_materials.read_stats' | translate:{value: selectedPhase?.completed, total: selectedPhase?.total} | translateNumber }}</label>
      </div>
    </div>
  </div>
</div>

<div class="body-content">
  <div class="container" *ngIf="isLoadingMaterials">
    <app-page-loader></app-page-loader>
  </div>

  <div class="container">
    <div class="empty-state my-5 my-lg-7 my-xl-9" *ngIf="!isLoadingMaterials && (!selectedPhase || !selectedPhase?.educational_materials?.length)">
      <span [inlineSVG]="'./assets/svg-color/no-notifications-graphic.svg'"></span>
      <h6 class="h7">{{ 'pages.default.patient_materials.no_materials_yet' | translate }}</h6>
    </div>

    <div class="row g-3 gy-4" *ngIf="!isLoadingMaterials">
      <div id="material-{{index}}" class="col-4" *ngFor="let material of selectedPhase?.educational_materials ; index as index">

        <a href=""
          class="card preview-card h-100"
          [ngClass]="{
            'completed': material.isCompleted,
            'unavailable': material.isUnavailable
          }"
          (click)="openMaterial($event, material)"
        >
          <div class="card-cover">
            <img *ngIf="material?.thumbnail?.image?.url" [src]="material?.thumbnail?.image?.url + '?width=814&disable=upscale&format=pjpg&auto=webp' " alt="Thumbnail" />

            <div class="cover-meta">
              <div class="meta-category" *ngIf="material.thumbnail">
                <span [inlineSVG]="'./assets/svg/file-general-24.svg'" *ngIf="material.isArticle" class="rtl-mirrored-inline-svg"></span>
                <span [inlineSVG]="'./assets/svg/file-pdf-24.svg'" *ngIf="material.isDocument"></span>
                <span [inlineSVG]="'./assets/svg/play-24.svg'" *ngIf="material.isMedia" class="rtl-mirrored-inline-svg"></span>
              </div>
            </div>
          </div>

          <div class="card-body">
            <ng-container *ngIf="!material.isUnavailable">
              <p class="lead fw-semibold mb-1 e2e-material-title">{{ material.title || 'pages.default.patient_materials.unavailable' | translate }}</p>
              <p class="fw-normal mb-0 preview-description e2e-material-description">{{ material?.description }}</p>
            </ng-container>

            <ng-container *ngIf="material.isUnavailable">
              <p class="lead fw-semibold m-0 e2e-material-title">{{ 'pages.default.patient_materials.unavailable' | translate }}</p>
            </ng-container>

            <ng-container *ngIf="!material.isUnavailable">
              <div class="preview-card-status" *ngIf="material.isStarted">
                <div class="progress">
                  <div class="progress-bar" role="progressbar" [style.width]="material.progress + '%'"></div>
                </div>

                <span class="label" *ngIf="material.isMedia">{{ 'pages.default.patient_materials.progress_played' | translate : {value:material.progress} }}</span>
                <span class="label" *ngIf="!material.isMedia">{{ 'pages.default.patient_materials.progress_read' | translate : {value:material.progress} }}</span>
              </div>

              <div class="preview-card-status" *ngIf="material.isCompleted">
                <span class="icon in-circle" [inlineSVG]="'./assets/svg/checkmark-24.svg'"></span>
                <span class="label" *ngIf="material.isMedia">{{ 'pages.default.patient_materials.played' | translate }}</span>
                <span class="label" *ngIf="!material.isMedia">{{ 'pages.default.patient_materials.read' | translate }}</span>
              </div>

              <div class="preview-card-status" *ngIf="!material.isStarted && !material.isCompleted">
                <span class="icon" [inlineSVG]="'./assets/svg/info-circle-24.svg'"></span>
                <span class="label" *ngIf="material.isMedia">{{ 'pages.default.patient_materials.not_played' | translate }}</span>
                <span class="label" *ngIf="!material.isMedia">{{ 'pages.default.patient_materials.not_read' | translate }}</span>
              </div>
            </ng-container>
          </div>
        </a>

      </div>
    </div>
  </div>
</div>
