import { Component, OnInit } from '@angular/core';
import { PageTabItem } from '../../../../models/page-tab-item';
import { PasswordPolicy } from '../../../../models/password-policy';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatchingFieldsValidator } from '../../../../validators/matching-fields-validator';
import { UserService } from '../../../../services/user.service';
import { PasswordPolicyValidator } from '../../../../validators/password-policy-validator';
import { AuthenticationService } from '../../../../services/authentication.service';
import { MfaService } from '../../../../services/mfa.service';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { MfaMethod } from '../../../../models/mfa-method';
import { Profile } from '../../../../models/profile';
import { BsModalService } from 'ngx-bootstrap/modal';
import { GeneralService } from '../../../../services/general.service';
import { AddMfaBackupModalComponent } from '../../../../modals/add-mfa-backup-modal/add-mfa-backup-modal.component';
import { DialCode } from '../../../../models/dial-code';

@Component({
  selector: 'app-settings-security',
  templateUrl: './settings-security.component.html',
  styleUrls: ['./settings-security.component.scss']
})
export class SettingsSecurityComponent implements OnInit {
  public pageTabItems: PageTabItem[];
  public policy: PasswordPolicy;
  public profile: Profile;
  public currentDialCode: DialCode;
  public passwordForm: UntypedFormGroup;
  public validationVisible: boolean;
  public isSettingPassword: boolean;


  public mfaPrimaryMethodes: MfaMethod[];
  public mfaBackupMethodes: MfaMethod[];

  constructor(
    public router: Router,
    public translate: TranslateService,
    public formBuilder: UntypedFormBuilder,
    public toastrService: ToastrService,
    public userService: UserService,
    public authenticationService: AuthenticationService,
    public generalService: GeneralService,
    public mfaService: MfaService,
    public modalService: BsModalService
  ) {
  }

  ngOnInit() {
    this.passwordFormSetup();

    this.refreshProfile();

    this.pageTabItems = [
      new PageTabItem('pages.default.settings.security.change_pw', 'zone_change_password'),
      // new PageTabItem('pages.default.settings.security.trusted_devices', 'zone_trusted_devices')
    ];

    if (this.profile?.mfa_enabled) {
      this.pageTabItems.push(new PageTabItem('pages.default.settings.security.two_step_auth', 'zone_tsa'));
    }

    this.userService.passwordPolicy().subscribe(policy => this.onPolicySuccess(policy));
  }

  public refreshProfile(): void {
    this.profile = this.userService.getStoredProfile();
    this.userService.getProfile().subscribe(() => {
      this.profile = this.userService.getStoredProfile();

      if (this.profile.mobile_number.code) {
        this.currentDialCode = this.generalService.mapPatientPhoneNumberCodeToDialCode(this.profile.mobile_number.code);
      } else {
        this.currentDialCode = undefined;
      }
    });
  }

  passwordFormSetup(): void {
    this.passwordForm = this.formBuilder.group({
      old_password: ['', [Validators.required]],
      new_password: ['', [Validators.required]],
      new_password_verification: ['', [Validators.required]]
    }, {
      validators: [MatchingFieldsValidator.validate('new_password', 'new_password_verification', 'mismatch')],
    });

    this.passwordForm.disable();
  }

  onPolicySuccess(policy: PasswordPolicy): void {
    this.policy = policy;
    this.setPolicyValidator();
  }

  setPolicyValidator(): void {
    if (this.passwordForm && this.policy) {
      this.passwordForm.get('new_password').setValidators([
        Validators.required,
        PasswordPolicyValidator.validate(this.policy)
      ]);
      this.passwordForm.enable();
    }
  }

  passwordFormSubmit(): void {
    if (!this.passwordForm.valid) {
      this.validationVisible = true;
    } else {
      this.validationVisible = false;
      this.setPassword();
    }
  }

  setPassword(): void {
    this.isSettingPassword = true;
    this.userService.changePassword(this.passwordForm.value.old_password, this.passwordForm.value.new_password, this.passwordForm.value.new_password_verification)
      .subscribe(
        () => this.onSetPasswordSuccess(),
        error => this.onSetPasswordError(error)
      );
  }

  onSetPasswordSuccess(): void {
    this.isSettingPassword = false;

    this.toastrService.success(this.translate.instant('pages.default.settings.security.pw_change_success'));

    this.authenticationService.logout();
  }

  onSetPasswordError(response): void {
    this.isSettingPassword = false;

    if (response?.error?.errors[0]?.key) {
      const key = response.error.errors[0].key

      // Cases for the newly provided password
      if (key === 'ERROR_IDENTITY_PASSWORD_NOT_MATCHING_CRITERIA' || key === 'ERROR_IDENTITY_PASSWORD_USED_ALREADY') {
        this.validationVisible = true;
        this.passwordForm.get('new_password').reset();
        this.passwordForm.get('new_password_verification').reset();

        if (key == 'ERROR_IDENTITY_PASSWORD_NOT_MATCHING_CRITERIA') {
          this.passwordForm.get('new_password').setErrors({
            password_policy: true
          });
        }

        if (key == 'ERROR_IDENTITY_PASSWORD_USED_ALREADY') {
          this.passwordForm.get('new_password').setErrors({
            password_already_used: true
          });
        }
        // Case for the old provided password
      } else if (key === 'ERROR_IDENTITY_PASSWORD_INVALID') {
        this.validationVisible = true;
        this.passwordForm.get('old_password').reset();
        this.passwordForm.get('old_password').setErrors({
          password_invalid: true
        });
      }
    }
  }

  showAddMfaBackupModal(event) {
    event.preventDefault();

    const modalRef = this.modalService.show(AddMfaBackupModalComponent,
      GeneralService.BsModalOptions({
        class: 'modal-dialog-centered',
        keyboard: false
      })
    );

    modalRef?.content?.onSuccess.subscribe(() => {
      this.refreshProfile();
    });
  }

  removeMfaBackupMethod(event: MouseEvent, mfaMethod: MfaMethod): void {
    if (mfaMethod.channel === 'EMAIL') {
      mfaMethod.value = this.profile?.email || 'EMAIL';
    } else if (mfaMethod.channel === 'SMS') {
      mfaMethod.value = this.profile?.mobile_number?.number || 'SMS';
    }

    event?.preventDefault();
    this.userService.removeMfaMethodeModal(mfaMethod).subscribe((done: boolean) => {
      if (done) {
        this.refreshProfile();
      }
    });
  }

  setPrimaryMfaMethod(event: MouseEvent, mfaMethod: MfaMethod): void {
    if (mfaMethod.channel === 'EMAIL') {
      mfaMethod.value = this.profile?.email || 'EMAIL';
    } else if (mfaMethod.channel === 'SMS') {
      mfaMethod.value = this.profile?.mobile_number?.number || 'SMS';
    }

    event?.preventDefault();
    this.userService.setPrimaryMfaMethodModal(mfaMethod).subscribe((done: boolean) => {
      if (done) {
        this.refreshProfile();
      }
    });
  }
}
