import { AfterViewChecked, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { BsModalService } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { combineLatest } from 'rxjs';
import { ConfirmModalComponent } from '../../../modals/confirm-modal/confirm-modal.component';
import { QueryList, QueryListStatus } from '../../../models/query-list';
import { UserTask } from '../../../models/user-task';
import { GeneralService } from '../../../services/general.service';
import { QueryListService } from '../../../services/query-list.service';
import { UserTaskService } from '../../../services/user-task.service';

@Component({
  selector: 'app-query-list-detail',
  templateUrl: './query-list-detail.component.html'
})
export class QueryListDetailComponent implements OnInit, AfterViewChecked {
  public patientPathwayUid: string;
  public patientUid: string;
  public formUid: string;
  public isLoading = false;
  public queryList: QueryList;
  public task: UserTask;
  public form: FormGroup = this.formBuilder.group({});
  public validationVisible = false;
  public isSaving = false;
  public isSubmitting = false;
  public filter: string;
  public requiredValidationEnabled = true;
  public type: string;

  public get isReadOnly(): boolean {
    return this.queryList?.isReadOnly;
  }

  constructor(
    public queryListService: QueryListService,
    public userTaskService: UserTaskService,
    public formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private translate: TranslateService,
    public router: Router,
    private readonly changeDetectorRef: ChangeDetectorRef,
    private modalService: BsModalService,
    private translateService: TranslateService,
    private toastrService: ToastrService
  ) {
    if (this.router.getCurrentNavigation()?.extras?.state?.userTask) {
      this.task = this.router.getCurrentNavigation()?.extras?.state?.userTask;
    }

    if (this.router.getCurrentNavigation()?.extras?.state?.queryListDetails) {
      this.queryList = this.router.getCurrentNavigation()?.extras?.state?.queryListDetails;
    }
  }

  ngAfterViewChecked(): void {
    this.changeDetectorRef.detectChanges();
  }

  ngOnInit() {
    combineLatest([this.route.params, this.route.queryParams]).subscribe(result => {
      const params = result[0];
      this.patientPathwayUid = params.patientPathwayUid;
      this.patientUid = params.patientUid;
      this.formUid = params.formUid;
      const qparams = result[1];
      this.filter = qparams['back-to-url'];
      this.type = qparams['type'];
      this.getQueryListItem();
    });
  }

  getQueryListItem() {
    if (this.type === 'STANDARD') {
      this.getUserTask();
    } else {
      this.queryListService.getQueryList(this.patientUid, this.patientPathwayUid, this.formUid).subscribe(result => {
        this.queryList = result;

        if (!this.queryList.isReadOnly) {
          this.getUserTask();
        } else {
          this.task = new UserTask(this.queryList);
        }
      });
    }
  }

  getUserTask() {
    if (this.task) {
      this.handleUserTask(this.task);
    } else {
      this.userTaskService.getUserTask(this.formUid, this.patientUid).subscribe(result => {
        this.handleUserTask(result);
      });
    }
  }

  handleUserTask(userTask: UserTask) {
    this.task = userTask;

    if (this.type === 'STANDARD') {
      this.queryList = new QueryList();
      this.queryList.title = this.task.title;
      this.queryList.assignee = this.task.patient;
    }
  }

  editedBy() {
    if (!this.queryList || !this.queryList?.assignee) {
      return '';
    }

    if (this.queryList?.assignee?.uid === this.patientUid) {
      return this.translate.instant('pages.default.query_lists.you').toLowerCase();
    } else {
      return this.queryList?.assignee?.getFullName();
    }
  }

  saveUserTask() {
    if (this.form.invalid) {
      this.validationVisible = true;
      return;
    }

    this.isSaving = true;

    this.userTaskService.saveUserTask(this.task.uid, this.patientUid, this.form.value).subscribe(() => {
      this.saveResultHandler();
      this.queryList.changed_at = new Date().toString();
      this.queryList.status = QueryListStatus.SAVED;
    }, error => {
      this.errorHandler(error);
    });
  }

  confirmSubmitUserTask() {
    const modalRef = this.modalService.show(ConfirmModalComponent,
      GeneralService.BsModalOptions({
        class: 'modal-dialog-centered',
        initialState: {
          title: 'modals.confirm_submit_query_list.title',
          description: 'modals.confirm_submit_query_list.description',
          yes: 'modals.confirm_submit_query_list.submit',
          no: 'modals.confirm_submit_query_list.cancel'
        }
      })
    );

    modalRef.content.onChoice.subscribe(() => {
      modalRef.hide();
      this.submitUserTask();
    });
  }

  submitUserTask() {
    if (this.form.invalid) {
      this.validationVisible = true;
      return;
    }

    this.isSubmitting = true;
    this.userTaskService.completeUserTask(this.task.uid, this.patientUid, { variables: this.form.value }).subscribe(() => {
      this.submitResultHandler();
    }, error => {
      this.errorHandler(error);
    });
  }

  saveResultHandler() {
    this.form.markAsPristine();
    this.isSaving = false;
    this.validationVisible = false;
    this.toastrService.success(this.translate.instant('pages.default.query_lists.save_success'));
  }

  submitResultHandler() {
    this.form.markAsPristine();
    this.isSubmitting = false;
    this.validationVisible = false;
    this.queryList.status = QueryListStatus.COMPLETED;
    this.queryList.completed_at = new Date().toString();
    this.form.disable();
    this.toastrService.success(this.translate.instant('pages.default.query_lists.submit_success'));
  }

  errorHandler(error) {
    this.isSaving = false;
    this.isSubmitting = false;

    const errorArray = error?.error?.errors;

    if (errorArray) {
      this.validationVisible = true;

      errorArray.forEach(err => {

        this.form.get(err.field)?.setErrors({
          backend_errors: true,
          message: err.key
        });
      });
    }
  }

  goBack($event: MouseEvent) {
    $event.preventDefault();

    if (this.type === 'QUESTIONNAIRE') {
      this.router.navigate(['/patient', this.patientUid, this.patientPathwayUid, 'query-lists'], { queryParams: { filter: 'QUESTIONNAIRE' } });
    } else if (this.type === 'CHECKLIST') {
      this.router.navigate(['/patient', this.patientUid, this.patientPathwayUid, 'query-lists'], { queryParams: { filter: 'CHECKLIST' } });
    } else {
      this.router.navigate(['/dashboard']);
    }
  }

  async canDeactivate(): Promise<boolean> {
    if (!this.form || !this.form?.dirty) {
      return true;
    }

    const modalRef = this.modalService.show(ConfirmModalComponent,
      GeneralService.BsModalOptions({
        class: 'modal-dialog-centered',
        initialState: {
          title: 'modals.cancel_query_list.title',
          description: 'modals.cancel_query_list.description',
          yes: 'modals.cancel_query_list.leave',
          no: 'modals.cancel_query_list.cancel'
        }
      })
    );

    return new Promise<boolean>((resolve) => {
      modalRef.content.onChoice.subscribe(() => {
        modalRef.hide();
        resolve(true);
      });
    });
  }
}
