<div class="layout-header-pusher header-tabs"></div>

<div class="layout-header header-tabs">
  <div class="header-content">
    <div class="container">
      <h5>{{ 'pages.default.dashboard.your_treatments' | translate }}</h5>
    </div>

    <div class="container">
      <div class="bar-tabs">
        <app-page-tabs [asClassicTabs]="true" [items]="pageTabItems"
                       (onTabChange)="onTabChangeHandler($event)"></app-page-tabs>
      </div>
    </div>
  </div>
</div>

<div class="body-content">

  <div class="container" *ngIf="isLoading">
    <app-page-loader></app-page-loader>
  </div>

  <div class="container" *ngIf="!isLoading && !pathways?.length">
    <div class="empty-state my-5 my-lg-7 my-xl-9">
      <span [inlineSVG]="'./assets/svg-color/no-notifications-graphic.svg'"></span>
      <h6 class="h7">{{ 'pages.default.dashboard.no_active_pathways' | translate }}</h6>
    </div>
  </div>

  <div class="container" *ngIf="!isLoading && pathwayId">
    <app-surgery-dates [pathway]="selectedPathway"></app-surgery-dates>
    <app-patient-reminders [pathway]="selectedPathway" (onCountChange)="onRemindersCountchange($event)"
                           (onUpdate)="onRemindersUpdate()"></app-patient-reminders>

    @if (hasReminders) {
      <hr class="my-6">
    }

    <div class="row g-2">
      <div class="col-6">
        <div class="single-item-block item-clickable"
             [routerLink]="['/patient', patientUid, pathwayId, 'timeline']" id="btn-block-timeline">
          <div class="block-icon">
            <span class="icon icon-lg rtl-mirrored-inline-svg" [inlineSVG]="'./assets/svg/timeline-24.svg'"></span>
          </div>
          <div class="block-wrapper">
            <p class="label py-1">{{ 'pages.default.dashboard.timeline' | translate }}</p>
          </div>
          <div class="block-btn-wrapper">
          <div class="block-action btn btn-lg btn-tertiary btn-icon">
            <span class="rtl-mirrored-inline-svg" [inlineSVG]="'./assets/svg/arrow-right-32.svg'"></span>
          </div>
            </div>
        </div>
      </div>


      <div class="col-6"
           *ngIf="scopeService.doesCurrentPatientHasClicinalCareModule() && selectedPathway?.goals?.length">
        <div class="single-item-block item-clickable" [routerLink]="['/patient', patientUid, pathwayId, 'goals']"
             id="btn-block-goals">
          <div class="block-icon">
            <span class="icon icon-lg rtl-mirrored-inline-svg" [inlineSVG]="'./assets/svg/goal-outline-24.svg'"></span>
          </div>
          <div class="block-wrapper">
            <p class="label py-1">{{ 'pages.default.dashboard.goals' | translate }}</p>
          </div>
          <div class="block-btn-wrapper">
          <div class="block-action btn btn-lg btn-tertiary btn-icon">
            <span class="rtl-mirrored-inline-svg" [inlineSVG]="'./assets/svg/arrow-right-32.svg'"></span>
          </div>
            </div>
        </div>
      </div>

      <div class="col-6">
        <div class="single-item-block item-clickable"
             [routerLink]="['/patient', patientUid, pathwayId, 'learning-materials']" id="btn-block-learning-materials">
          <div class="block-icon">
            <span class="icon icon-lg rtl-mirrored-inline-svg" [inlineSVG]="'./assets/svg/learning-outline-24.svg'"></span>
          </div>
          <div class="block-wrapper">
            <p class="label py-1">{{ 'pages.default.dashboard.learning_materials' | translate }}</p>
          </div>
          <div class="block-btn-wrapper">
          <div class="block-action btn btn-lg btn-tertiary btn-icon">
            <span class="rtl-mirrored-inline-svg" [inlineSVG]="'./assets/svg/arrow-right-32.svg'"></span>
          </div>
            </div>
        </div>
      </div>

      <div class="col-6">
        <div class="single-item-block item-clickable"
             [routerLink]="['/patient', patientUid, pathwayId, 'query-lists']" [queryParams]="{filter: 'CHECKLIST'}"
             id="btn-block-checklists">
          <div class="block-icon">
            <span class="icon icon-lg" [inlineSVG]="'./assets/svg/checklist-24.svg'"></span>
          </div>
          <div class="block-wrapper">
            <p class="label py-1">{{ 'pages.default.dashboard.checklists' | translate }}</p>
          </div>
          <div class="block-btn-wrapper">
          <div class="block-action btn btn-lg btn-tertiary btn-icon">
            <span class="rtl-mirrored-inline-svg" [inlineSVG]="'./assets/svg/arrow-right-32.svg'"></span>
          </div>
            </div>
        </div>
      </div>

      <div class="col-6" *ngIf="scopeService.doesCurrentPatientHasClicinalCareModule()">
        <div class="single-item-block item-clickable"
             [routerLink]="['/patient', patientUid, pathwayId, 'query-lists']" [queryParams]="{filter: 'QUESTIONNAIRE'}"
             id="btn-block-query-lists">
          <div class="block-icon">
            <span class="icon icon-lg" [inlineSVG]="'./assets/svg/calendar-outline-24.svg'"></span>
          </div>
          <div class="block-wrapper">
            <p class="label py-1">{{ 'pages.default.dashboard.essential-forms' | translate }}</p>
          </div>
          <div class="block-btn-wrapper">
          <div class="block-action btn btn-lg btn-tertiary btn-icon">
            <span class="rtl-mirrored-inline-svg" [inlineSVG]="'./assets/svg/arrow-right-32.svg'"></span>
          </div>
            </div>
        </div>
      </div>

      <div class="col-6">
        <div class="single-item-block item-clickable" [routerLink]="['/patient', patientUid, pathwayId, 'faq']"
             id="btn-block-faq">
          <div class="block-icon">
            <span class="icon icon-lg rtl-mirrored-inline-svg" [inlineSVG]="'./assets/svg/question-outline-24.svg'"></span>
          </div>
          <div class="block-wrapper">
            <p class="label py-1">{{ 'pages.default.dashboard.faqs' | translate }}</p>
          </div>
          <div class="block-btn-wrapper">
          <div class="block-action btn btn-lg btn-tertiary btn-icon">
            <span class="rtl-mirrored-inline-svg" [inlineSVG]="'./assets/svg/arrow-right-32.svg'"></span>
          </div>
            </div>
        </div>
      </div>

      <div class="col-6">
        <div class="single-item-block item-clickable"
             [routerLink]="['/patient', patientUid, pathwayId, 'hospital']" id="btn-block-hospital">
          <div class="block-icon">
            <span class="icon icon-lg rtl-mirrored-inline-svg" [inlineSVG]="'./assets/svg/hospital-outline-24.svg'"></span>
          </div>
          <div class="block-wrapper">
            <p class="label py-1">{{ 'pages.default.dashboard.hospital' | translate }}</p>
          </div>
          <div class="block-btn-wrapper">
          <div class="block-action btn btn-lg btn-tertiary btn-icon">
            <span class="rtl-mirrored-inline-svg" [inlineSVG]="'./assets/svg/arrow-right-32.svg'"></span>
          </div>
            </div>
        </div>
      </div>

      <div class="col-6" *ngIf="scopeService.doesCurrentPatientHasClicinalCareModule()">
        <div class="single-item-block item-clickable"
             [routerLink]="['/patient', patientUid, pathwayId, 'medical-team']" id="btn-block-medical-team">
          <div class="block-icon">
            <span class="icon icon-lg rtl-mirrored-inline-svg"
                  [inlineSVG]="'./assets/svg/participants-outline-more-24.svg'"></span>
          </div>
          <div class="block-wrapper">
            <p class="label py-1">{{ 'pages.default.dashboard.medical_team' | translate }}</p>
          </div>
          <div class="block-btn-wrapper">
          <div class="block-action btn btn-lg btn-tertiary btn-icon">
            <span class="rtl-mirrored-inline-svg" [inlineSVG]="'./assets/svg/arrow-right-32.svg'"></span>
          </div>
            </div>
        </div>
      </div>
    </div>

  </div>
</div>
