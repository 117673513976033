<h1 class="m-0" [ngClass]="{ 'text-primary h1-welcome': inSidebar, 'text-white display-1': !inSidebar }">
  <span class="me-2">{{ 'components.flow_welcome.welcome_to' | translate }}</span>
  <span class="app-name" [ngClass]="{ 'd-block': inSidebar }">{{ 'components.flow_welcome.polyphonic' | translate }}</span>
</h1>

<ng-template [ngIf]="!inSidebar">
  <p class="display-2 text-white fw-light mb-0 mt-1">
    {{ 'components.flow_welcome.care' | translate }}
  </p>
</ng-template>
