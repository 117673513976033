<div class="banner-info-modal">
  <div class="modal-header align-items-start">
    <h6 class="h7">{{ title }}</h6>

    <button type="button" class="btn-close" data-dismiss="modal" aria-label="Close" (click)="bsModalRef.hide()"></button>
  </div>

  <div class="modal-body">
    <div [innerHtml]="description" class="description"></div>
  </div>

  <div class="modal-footer no-shadow">
    <div class="m-start-auto">
      <button class="btn btn-primary" id="more-info-banner-modal-close" (click)="bsModalRef.hide()">
        {{ 'action.close' | translate }}
      </button>
    </div>
  </div>
</div>
