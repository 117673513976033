<div class="info-box info-box-warning sidebar-open-element-visible mx-2 mb-4" *ngIf="banner">
  <em *ngIf="banner?.type === bannerType.MAINTENANCE"   class="info-box-icon" [inlineSVG]="'./assets/svg/warning-outline-24.svg'"></em>
  <em *ngIf="banner?.type === bannerType.CRITICAL"      class="info-box-icon" [inlineSVG]="'./assets/svg/caution-24.svg'"></em>
  <em *ngIf="banner?.type === bannerType.WARNING"       class="info-box-icon" [inlineSVG]="'./assets/svg/notification-bell-24.svg'"></em>
  <em *ngIf="banner?.type === bannerType.INFORMATIONAL" class="info-box-icon" [inlineSVG]="'./assets/svg/info-circle-24.svg'"></em>

  <h6 class="info-box-title mb-1">{{ banner?.title }}</h6>
  <div [innerHtml]="banner?.description" class="description"></div>

  <div class="text-end">
    <button class="btn btn-tertiary" type="button" (click)="showMoreInfoModal(banner.title, banner.more_information)"
      aria-label="Read more about this notification">{{ 'action.read_more' | translate }}</button>
  </div>
</div>

<div class="sidebar-state-icon bg-warning sidebar-open-element-hidden" *ngIf="banner">
  <em *ngIf="banner?.type === bannerType.MAINTENANCE"   [inlineSVG]="'./assets/svg/warning-outline-24.svg'"></em>
  <em *ngIf="banner?.type === bannerType.CRITICAL"      [inlineSVG]="'./assets/svg/caution-24.svg'"></em>
  <em *ngIf="banner?.type === bannerType.WARNING"       [inlineSVG]="'./assets/svg/notification-bell-24.svg'"></em>
  <em *ngIf="banner?.type === bannerType.INFORMATIONAL" [inlineSVG]="'./assets/svg/info-circle-24.svg'"></em>
</div>
