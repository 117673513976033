<div class="layout-header-pusher header-back"></div>

  <div class="layout-header header-back">
    <div class="header-content">
      <div class="container">
        <app-back-link route="/dashboard" label="action.back_to_overview" />
      </div>

    <div class="container">
      <h5 *ngIf="filter === 'QUESTIONNAIRE'">{{ 'pages.default.dashboard.essential-forms' | translate }}</h5>
      <h5 *ngIf="filter === 'CHECKLIST'">{{ 'pages.default.dashboard.checklists' | translate }}</h5>
    </div>
  </div>
</div>

<div class="body-content">
  <div class="container" *ngIf="isLoading">
    <app-page-loader></app-page-loader>
  </div>

  <div class="container" *ngIf="!isLoading">

    <div class="empty-state my-5 my-lg-7 my-xl-9" *ngIf="!queryLists?.length">
      <span [inlineSVG]="'./assets/svg-color/no-notifications-graphic.svg'"></span>
      <h6 class="h7" *ngIf="filter === 'QUESTIONNAIRE'">{{ 'pages.default.essential_forms.no_essential_forms_yet' | translate }}</h6>
      <h6 class="h7" *ngIf="filter === 'CHECKLIST'">{{ 'pages.default.checklists.no_checklists_yet' | translate }}</h6>
    </div>

    <div class="row g-2">
      <div class="col-4" *ngFor="let form of queryLists">
        <div class="card h-100" [ngClass]="{'card-muted': grayedOut(form)}">
          <div class="card-header">
            <a href="" (click)="$event.preventDefault(); evalUserTask(form)">{{ form.title | translateHelp | translate }}</a>
          </div>

          <div class="card-body">
            <div class="user-compact">
              <app-avatar [profilePicture]="form.assignee?.profilePicture"></app-avatar>

              <div class="user-compact-info">
                <p class="title text-truncate">{{ form.assignee?.getFullName() }}</p>
                <p *ngIf="form.assignee?.uid === patientUid">{{ 'pages.default.query_lists.you' | translate }}</p>
                <p>{{ form.assignee?.job_title }}</p>
              </div>
            </div>

            <p class="text-muted small mb-0 mt-2" *ngIf="form.status === QueryListStatus.COMPLETED">
              {{ 'pages.default.query_lists.submitted_on' | translate }} {{ form.completed_at | timeZoneDate }}
            </p>
            <p class="text-muted small mb-0 mt-2" *ngIf="form.status === QueryListStatus.TIMED_OUT">
              {{ 'pages.default.query_lists.due_at' | translate : {value:form.due_days_ago?.toString() } }}
            </p>
          </div>

          <div class="card-footer">

            @switch (form.status) {

              @case (QueryListStatus.NEW) {
                <div class="status-badge success">
                  <span class="icon xs rtl-mirrored-inline-svg" [inlineSVG]="'./assets/svg/dot-24.svg'"></span>
                  {{ 'pages.default.query_lists.status.new' | translate }}
                </div>
              }

              @case (QueryListStatus.SAVED) {
                <div class="status-badge in-progress">
                  {{ 'pages.default.query_lists.status.saved' | translate }}
                </div>
              }

              @case (QueryListStatus.COMPLETED) {
                <div class="status-badge">
                  {{ 'pages.default.query_lists.status.completed' | translate }}
                </div>
              }

              @case (QueryListStatus.TIMED_OUT) {
                <div class="status-badge">
                  {{ 'pages.default.query_lists.status.timed_out' | translate }}
                </div>
              }
            }

            <button class="btn btn-tertiary btn-icon m-start-auto" (click)="evalUserTask(form)" [ngClass]="{'loader': evaluatingUids.includes(form.uid)}">
              @if (evaluatingUids.includes(form.uid)) {
                <span class="loader" [inlineSVG]="'./assets/svg/loader-light-sm.svg'"></span>
              }
              <span class="rtl-mirrored-inline-svg" [inlineSVG]="'./assets/svg/arrow-right-32.svg'"></span>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-container *ngIf="!isLoading && totalElements">
  <div class="layout-footer-pusher footer-paging"></div>
  <div class="layout-footer footer-paging">
    <div class="footer-content">
      <div class="container align-items-center">
        <!-- total items -->
        <div class="m-end-auto" id="total-patients">{{ getLabelForTotalPatients() }}</div>
        <!-- pagination -->
        <div *ngIf="totalPages > 1"><app-pagination [totalPages]="totalPages" [activePage]="currentPage" (pageChange)="onPaginationPageChanged($event)"></app-pagination></div>
        <!-- pagination range -->
        <div class="m-start-auto"><app-pagination-range [currentRange]="currentPageSize" (rangeChange)="onPaginationRangeChanged($event)" [rangeOptions]="[5,10]"></app-pagination-range></div>
      </div>
    </div>
  </div>
</ng-container>
