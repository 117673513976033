<div class="bg-pattern-light">
  <div class="container">
    <div class="layout-sidebar">
      <div class="sidebar-content is-flexed responsive-sidebar">
        <div class="content-part">
          <div class="sidebar-logo d-none d-sm-block" [inlineSVG]="'./assets/svg/logo-polyphonic.svg'"></div>
          <div class="icon icon-lg d-block d-sm-none pb-4" [inlineSVG]="'./assets/svg-color/polyphonic-hexagon.svg'"></div>
        </div>

        <div class="content-part d-block d-sm-none">
          <div class="mb-4">
            <h5 class="m-0">{{ 'components.flow_welcome.welcome_to' | translate }}</h5>
            <h4 class="m-0">
              <span>Polyphonic</span>
              {{ 'components.flow_welcome.care' | translate }}
            </h4>
          </div>
        </div>

        <div class="content-part">
          <h6>{{ 'pages.flow.locale_registration.confirm_language' | translate }}</h6>

          <div class="form-group required">
            <app-select id="select_locale_registration_language"
              [placeholder]="('form.labels.preferred_language' | translate)" [clearable]="false" [searchable]="true"
              [hideSelected]="false" [multiple]="false" [items]="languageOptions" [autoSortOnLabel]="true"
              [selectedItemsOnTop]="false" [(ngModel)]="selectedLanguage" (change)="onLanguageSelection()"
              [asFilter]="false" [showCheckboxes]="false"></app-select>
          </div>

          <p class="small mt-2 mb-0 text-muted">{{ 'pages.flow.locale_registration.confirm_language_info' | translate }}</p>

          <button id="button_locale_registration_continue" type="submit" class="btn btn-primary mt-3 mt-lg-4"
            (click)="submit()">{{ 'action.continue' | translate }}</button>
        </div>

        <div class="footer-part mt-auto d-flex d-sm-block">
          <app-flow-copyrights></app-flow-copyrights>

          <button class="btn btn-secondary align-self-center btn-icon m-start-auto d-sm-none" (click)="openHelpCenter()">
            <span class="icon" [inlineSVG]="'./assets/svg/assistant-24.svg'"></span>
          </button>
        </div>
      </div>
    </div>

    <div class="layout-body fixed with-background d-none d-sm-flex flex-column">
      <div class="body-content d-none d-md-block">
        <app-flow-welcome></app-flow-welcome>
      </div>

      <div class="body-content mt-auto m-start-auto">
        <button class="btn btn-light btn-rounded" (click)="openHelpCenter()">
          <span class="icon" [inlineSVG]="'./assets/svg/assistant-24.svg'"></span>
          <span>{{ 'pages.flow.home.need_help' | translate }}</span>
        </button>
      </div>

      <div class="layout-background"></div>
    </div>
  </div>
</div>
