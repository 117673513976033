@if(hasReminders) {
  <h6>{{ 'pages.default.dashboard.reminders' | translate }}</h6>

  @if(hasActionReminders) {
    <div class="d-flex justify-content-between align-items-center mb-1">
        <p class="small text-uppercase font-weight-bold text-muted mb-0">{{ 'components.patient_reminders.needs_action' | translate }}</p>

        @if(actionReminders?.length > actionRemindersPreview) {
          <button class="btn btn-link btn-sm" (click)="showMoreToggle($event, 'action')">
            @if (actionRemindersShowMore) {
              <span class="label small font-weight-semibold">
                {{ 'components.patient_reminders.view_less' | translate }}
              </span>

              <div class="icon-end" [inlineSVG]="'./assets/svg/chevron-up-24.svg'" ></div>
            }
            @else {
              <span class="label small font-weight-semibold">
                {{ 'components.patient_reminders.view_all' | translate }} ({{ actionReminders?.length }})
              </span>

              <div class="icon-end" [inlineSVG]="'./assets/svg/chevron-down-24.svg'" ></div>
            }
          </button>
        }

    </div>

    <div class="row g-2">
      <div class="col-6" *ngFor="let reminder of visibleActionReminders">
        <app-patient-reminder-item
          id="reminder-{{reminder?.testingID}}"
          [reminder]="reminder"
          [patientUid]="patientUid"
          [patientPathwayID]="pathway?.patient_pathway_id"
          (onUpdate)="anyReminderUpdated()"
        ></app-patient-reminder-item>
      </div>
    </div>
  }

  <div class="pb-4" *ngIf="hasActionReminders && hasInfoReminders"></div>

  <ng-container *ngIf="hasInfoReminders">
    <div class="d-flex justify-content-between align-items-center mb-1">

        <p class="small text-uppercase font-weight-bold text-muted mb-0">{{ 'components.patient_reminders.for_your_information' | translate }}</p>

        @if(otherInfoRemindersCount > 2 || (otherInfoRemindersCount > 0 && oldInformationReminders?.length > 0)) {
          <button class="btn btn-link btn-sm" (click)="showMoreToggle($event, 'information')">
            @if (informationRemindersShowMore) {
              <span class="label small font-weight-semibold">
                {{ 'components.patient_reminders.view_less' | translate }}
              </span>

              <div class="icon-end" [inlineSVG]="'./assets/svg/chevron-up-24.svg'" ></div>
            }
            @else {
              <span class="label small font-weight-semibold">
                @if(informationReminders?.length === 0) {
                  {{ 'components.patient_reminders.view_past_reminders' | translate }} ({{ otherInfoRemindersCount }})
                } @else {
                  {{ 'components.patient_reminders.view_all' | translate }} ({{ otherInfoRemindersCount }})
                }
              </span>

              <div class="icon-end" [inlineSVG]="'./assets/svg/chevron-down-24.svg'" ></div>
            }
          </button>
        }
    </div>

    <div class="row g-2">
      <div class="col-6" *ngFor="let reminder of visibleInformationReminders">
        <app-patient-reminder-item
          id="reminder-{{reminder?.testingID}}"
          [reminder]="reminder"
          [patientUid]="patientUid"
          [patientPathwayID]="pathway?.patient_pathway_id"
          (onUpdate)="anyReminderUpdated()"
        ></app-patient-reminder-item>
      </div>
    </div>

    <div *ngIf="informationRemindersShowMore && oldInformationReminders?.length > 0">
      <p class="text-muted small">{{ 'components.patient_reminders.read_messages_auto_removed_info' | translate }}</p>

      <div class="row">
        <div class="col-6" *ngFor="let reminder of oldInformationReminders">
          <app-patient-reminder-item
            id="reminder-{{reminder?.testingID}}"
            [reminder]="reminder"
            [patientUid]="patientUid"
            [patientPathwayID]="pathway?.patient_pathway_id"
            (onUpdate)="anyReminderUpdated()"
          ></app-patient-reminder-item>
        </div>
      </div>
    </div>
  </ng-container>
}
