import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { AppointmentModalComponent } from '../../modals/appointment-modal/appointment-modal.component';
import { PathwayMessageModalComponent } from '../../modals/pathway-message-modal/pathway-message-modal.component';
import { UserTaskStandardModalComponent } from '../../modals/user-task-standard-modal/user-task-standard-modal.component';
import { DateFormat } from '../../models/date-format';
import { UserTaskBasic } from '../../models/user-task-basic';
import { AppointmentService } from '../../services/appointment.service';
import { GeneralService } from '../../services/general.service';
import { LocaleService } from '../../services/locale.service';
import { UserTaskService } from '../../services/user-task.service';
import { ModalService } from '../../services/modal.service';
import { UserTask } from '../../models/user-task';
import { UserTaskVisualModalComponent } from '../../modals/user-task-visual-modal/user-task-visual-modal.component';


@Component({
  selector: 'app-patient-reminder-item',
  templateUrl: './patient-reminder-item.component.html',
  styleUrls: ['./patient-reminder-item.component.scss']
})
export class PatientReminderItemComponent implements OnInit {
  @Input() reminder: any;
  @Input() patientUid: string;
  @Input() patientPathwayID: string;

  @Output() onUpdate: EventEmitter<void> = new EventEmitter();

  public time_24_hours: boolean;
  public dateFormat: DateFormat;
  public isEvaluating = false;

  constructor(
    public router: Router,
    public modalService: ModalService,
    public localeService: LocaleService,
    public appointmentService: AppointmentService,
    public userTaskService: UserTaskService
  ) { }

  ngOnInit(): void {
    const preferences = this.localeService.getLocalePreferences();
    this.time_24_hours = preferences.locale.time_24_hours;
    this.dateFormat = preferences.dateFormat;
  }

  openReminder(event: MouseEvent): void {
    event?.preventDefault();

    if (this.reminder.className === 'UserTaskBasic') {
      this.openUserTask();
    } else if (this.reminder.className === 'Reminder') {
      if (this.reminder.type === 'APPOINTMENT') {
        this.openAppointment();
      } else if (this.reminder.type === 'UNREAD_MATERIALS') {
        this.openLearningMaterials();
      }
    } else if (this.reminder.className === 'PathwayMessage') {
      this.openPathwayMessage();
    }
  }

  openUserTask() {
    if (this.reminder.type === 'CHECKLIST') {
      this.router.navigate(['/patient', this.patientUid, this.patientPathwayID, 'query-lists', this.reminder.uid], { queryParams: { type: this.reminder.type } });
    } else {
      this.evalQuestionaire();
    }
  }

  evalQuestionaire() {
    // We need to check if a user-task has ui_config. We need UT details for that.
    this.isEvaluating = true;
    this.userTaskService.getUserTask(this.reminder.uid, this.reminder.patient?.uid).subscribe({
      next: (userTask: UserTask) => {
        this.isEvaluating = false;

        if (this.reminder.type === 'STANDARD') {

          this.openUserTaskModal(userTask, userTask.hasUiConfig ? 'visual' : 'standard');

        } else if (this.reminder.type === 'QUESTIONNAIRE') {
          if (userTask.hasUiConfig) {
            this.openUserTaskModal(userTask, 'visual');
          } else {
            this.router.navigate(['/patient', this.patientUid, this.patientPathwayID, 'query-lists', this.reminder.uid],
              {
                queryParams: { type: this.reminder.type },
                state: { userTask }
              });
          }
        }

      }, error: () => this.isEvaluating = false
    });
  }

  openUserTaskModal(userTask?: UserTask, type: 'standard' | 'visual' = 'standard') {
    let modalref: BsModalRef;
    const userTaskBasic = new UserTaskBasic({ uid: this.reminder.uid });
    const initialState: any = {
      userTaskBasic,
      task_id: userTask?.uid,
    };

    if (userTask) {
      initialState.userTask = userTask;
    }

    if (type === 'visual') {
      modalref = this.modalService.showWithInterceptor(UserTaskVisualModalComponent,
        GeneralService.BsModalOptions({
          class: 'modal-xl modal-compact',
          initialState
        }),
        GeneralService.BsModalOptions({
          class: 'modal-dialog-centered',
          initialState: {
            title: 'modals.user_task_visual_modal_confirm.title',
            description: 'modals.user_task_visual_modal_confirm.description',
            yes: 'modals.user_task_visual_modal_confirm.submit',
            no: 'modals.user_task_visual_modal_confirm.cancel'
          }
        })
      );
    } else {
      modalref = this.modalService.show(UserTaskStandardModalComponent,
        GeneralService.BsModalOptions({
          class: 'modal-xl modal-compact',
          initialState
        })
      );
    }

    modalref?.content?.taskSubmitSuccess.subscribe(() => {
      this.onUpdate.emit();
    });
  }

  openAppointment() {
    this.appointmentService.getAppointment(this.reminder.uid).subscribe(result => {
      const initialState = {
        appointment: result,
        dateFormat: this.dateFormat,
        time_24_hours: this.time_24_hours
      };

      const modalref = this.modalService.show(AppointmentModalComponent,
        GeneralService.BsModalOptions({
          class: 'modal-dialog-centered modal-xl',
          initialState
        })
      );

      modalref?.content?.appointmentSubmitSuccess.subscribe(() => {
        this.onUpdate.emit();
      });
    });
  }

  openPathwayMessage() {
    const initialState = {
      pathwayMessage: this.reminder,
      patientPathwayID: this.patientPathwayID
    };

    const modalref = this.modalService.show(PathwayMessageModalComponent,
      GeneralService.BsModalOptions({
        class: 'modal-dialog-centered',
        initialState
      })
    );

    modalref?.content?.onRead.subscribe(() => {
      this.onUpdate.emit();
    });
  }

  openLearningMaterials() {
    this.router.navigate(['/patient', this.patientUid, this.patientPathwayID, 'learning-materials']);
  }
}
