import { Faq } from './faq';

export class FaqCategory {
  public title: string;
  public items: Array<Faq>;

  constructor(item?) {
    if (item) {
      this.fillFromJson(item);
    }
  }

  fillFromJson(item) {
    this.title = item.title;
    if (item.items) {
      this.items = item.items.map(x => new Faq(x.faq));
    }
  }
}
