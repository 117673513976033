<div class="custom-slider" *ngIf="options">
  <ngx-slider
    [ngModel]="sliderValue"
    [ngModelOptions]="{standalone: true}"
    [options]="options"
    [ngClass]="{
      'empty': this.value === null || this.value === undefined,
      'has-labels': labels?.length,
      'reverse': isReverse,
      'ngx-slider-pointer-ceil': this.value === this.ceil,
      'ngx-slider-pointer-floor': this.value === this.floor
      }"
    (userChangeEnd)="onChange($event)"
  />
</div>
