import { Component, OnInit, Output, EventEmitter, Input, ChangeDetectorRef } from '@angular/core';
import {AppointmentSelectionEvent} from './../../events/appointment-selection-event';
import {AppointmentService} from "../../services/appointment.service";
import {Appointment} from "../../models/appointment";
import {UserService} from "../../services/user.service";
import {DateFormat} from "../../models/date-format";
import {GeneralService} from "../../services/general.service";
import moment from "moment";
import { Subject, Subscription } from "rxjs";
import { document } from "ngx-bootstrap/utils";
import { AttentionCenterService } from '../../services/attention-center.service';
import { LanguageService } from '../../services/language.service';

@Component({
  selector: 'app-calendar-year-view',
  templateUrl: './calendar-year-view.component.html',
  styleUrls: ['./calendar-year-view.component.scss']
})
export class CalendarYearViewComponent implements OnInit {
  @Output() onAppointmentSelect: EventEmitter<AppointmentSelectionEvent> = new EventEmitter();

  @Input() dateFormat: DateFormat;
  @Input('time24Hours') time_24_hours: boolean;
  @Input() yearChanged: Subject<any>;
  @Input() timeZone: string;

  public appointments: Appointment[];
  public currentYear: number;
  public isLoading: boolean = true;
  public scrollTo: Date;
  public appointmentsFetchInterval: any;
  public onNewAppointmentsPendingSubscription: Subscription;

  constructor(
    public appointmentService: AppointmentService,
    public ref: ChangeDetectorRef,
    public generalService: GeneralService,
    public userService: UserService,
    public attentionCenterService: AttentionCenterService,
    public languageService: LanguageService
  ) {
  }

  ngOnInit(): void {
    this.currentYear = parseInt(localStorage.getItem('current_year'));
    this.getAppointments();

    this.yearChanged.subscribe(event => {
      this.currentYear = event;
      this.scrollTo = null;
      this.getAppointments();
    });

    this.appointmentService.appointmentChanged$.subscribe(appointment => {
      if (appointment.status_of_user === AppointmentService.StatusAppointmentRemoved || appointment.status_of_user === AppointmentService.StatusAppointmentDeclined) {
        this.removeAppointment(appointment);
      } else {
        this.changeAppointment(appointment);
      }
    });

    this.appointmentService.moreAppointmentsClicked$.subscribe(result => {
      this.scrollTo = result;
    });

    this.appointmentService.appointmentStatusChanged$.subscribe(() =>  this.getAppointments(false));

    this.appointmentsFetchInterval = setInterval(() => {
      this.getAppointments(false);
    }, 60000);

    this.onNewAppointmentsPendingSubscription = this.attentionCenterService.onNewAppointmentsPending.subscribe(() => {
      this.getAppointments(false);
    });
  }

  ngOnDestroy() {
    clearInterval(this.appointmentsFetchInterval);
    this.onNewAppointmentsPendingSubscription?.unsubscribe();
  }

  scrollToAppointment(date?: Date) {
    if (date || (new Date().getFullYear() === this.currentYear)) {
      let apptToScrollTo: HTMLElement;

      const apptDate = date ? new Date(date).getTime() : new Date().getTime();
      const uid = this.findClosestAppointment(apptDate);

      setTimeout(() => {
        apptToScrollTo = document.getElementById('item-' + uid);

        if (apptToScrollTo) {
          apptToScrollTo.scrollIntoView({behavior: "smooth", block: "center"});
        }
      });
    }
  }

  findClosestAppointment(date: number): string {
    const givenDate = date; // Either now or a specified date
    let closestAppt;
    let closestDate = null;

    this.appointments.forEach(appt => {
      const apptDate = new Date(appt.start_date || appt.date).getTime();

      if (apptDate >= givenDate && (apptDate < closestDate || closestDate === null)) {
        closestAppt = appt;
        closestDate = apptDate;
      }
    });

    return closestAppt?.uid;
  }

  getAppointments(loader = true) {
    if (loader) {
      this.isLoading = true;
    }

    const start_date = moment('1/1/' + this.currentYear, 'DD-MM-YYYY');
    const end_date = moment('31/12/' + this.currentYear, 'DD-MM-YYYY');


    this.appointmentService.getAllAppointments(start_date, end_date).subscribe(allAppointments => {
      this.appointments = allAppointments;

      if (!Array.isArray(this.appointments)) {
        this.appointments = [];
      }

      this.sortAppointments();

      if (this.scrollTo === null) {
        this.scrollToAppointment();
      } else {
        this.scrollToAppointment(this.scrollTo);
        this.scrollTo = null;
      }

      this.isLoading = false;
    }, error => {
      this.isLoading = false;
      this.appointments = [];
    });
  }

  removeAppointment(appointment) {
    const foundAppointment: Appointment = this.appointments.find(item => {
      return item.uid === appointment.uid;
    });

    if (foundAppointment) {
      this.appointments.splice(this.appointments.indexOf(foundAppointment), 1);
    }
  }

  changeAppointment(appointment) {
    if(!this.appointments)  {
      return false;
    }

    const foundAppointment: Appointment = this.appointments.find(item => {
      return item.uid === appointment.uid;
    });

    if (foundAppointment) {
      foundAppointment.merge(appointment);
    }

    this.sortAppointments();
  }

  sortAppointments() {
    this.appointments = this.appointments.sort((a, b) => {
      let date1: any, date2: any;
      date1 = new Date(a.start_date? a.start_date : a.date);
      date2 = new Date(b.start_date? b.start_date : b.date );
      return (date1 - date2);
    });
  }

  selectAppointment(event, appointment) {
    this.onAppointmentSelect.emit(new AppointmentSelectionEvent(event, appointment));
  }

  getMonth(dateString: string): string {
    const date = moment(new Date(dateString)).tz(this.timeZone);
    return date.format('MMMM');
  }

  showHeading(index: number, currentAppointment: Appointment, prevAppointment: Appointment): boolean {

    if(index === 0) {
      return true;
    }

    const prevAppointmentMonth = this.getMonth(prevAppointment?.start_date? prevAppointment?.start_date : prevAppointment?.date);
    const currentAppointmentMonth = this.getMonth(currentAppointment?.start_date? currentAppointment?.start_date : currentAppointment?.date);

    if(prevAppointmentMonth !== currentAppointmentMonth) {
      return true;
    }

    return false;
  }
}
