import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {BsModalRef} from 'ngx-bootstrap/modal';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from "@angular/forms";
import {ToastrService} from "ngx-toastr";
import {TranslateService} from '@ngx-translate/core';
import {UserService} from "../../services/user.service";
import { Profile } from '../../models/profile';
import { AuthenticationService } from '../../services/authentication.service';

@Component({
  selector: 'app-edit-email-modal',
  templateUrl: './edit-email-modal.component.html',
  styleUrls: ['./edit-email-modal.component.scss']
})
export class EditEmailModalComponent implements OnInit {
  @Output() onProfileUpdated: EventEmitter<any> = new EventEmitter();

  public form: UntypedFormGroup;

  public profile: Profile;
  public validationVisible = false;
  public step: "email" | "code" = 'email';
  public isSaving: boolean;

  constructor(
    public bsModalRef: BsModalRef,
    public formBuilder: UntypedFormBuilder,
    public toastrService: ToastrService,
    public translate: TranslateService,
    public userService: UserService,
    public authenticationService: AuthenticationService
  ) {
  }

  ngOnInit() {
    this.formSetup();
  }

  formSetup() {
    if (this.form) {
      return;
    }

    this.form = this.formBuilder.group({
      email: [this.profile?.email, [Validators.email, Validators.required]],
      code: ['', [Validators.required]]
    });
  }

  handleCancel() {
    return this.bsModalRef.hide();
  }

  handleSubmit() {
    this.validationVisible = false;
    if(this.step === 'email') {
      // email is not valid
      if (this.form.get('email').errors) {
        this.validationVisible = true;
        return;
      }

      if(this.isEmailChanged()) {
        this.requestEmailVerificationCode(this.form.get('email').value);
      }
    }

    if(this.step === 'code') {
      if (this.form.get('code').errors) {
        this.validationVisible = true;
        return;
      }

      this.verifyEmailVerificationCode(this.form.get('code').value);
    }
  }

  isEmailChanged(): Boolean {
    return (this.form.get('email').value !== this.profile.email);
  }

  requestEmailVerificationCode(email) {
    this.isSaving = true;
    this.userService.changeEmail(email).subscribe(result => {
      this.isSaving = false;
      this.step = 'code';
      this.toastrService.info(this.translate.instant('modals.edit_contact_details.verification_code_sent'));
    }, error => {
      this.isSaving = false;
      this.onRequestError(error);
    });
  }

  verifyEmailVerificationCode(code) {
    this.isSaving = true;
    this.userService.verifyChangeEmailCode(code).subscribe(result => {
      this.isSaving = false;
      this.onUpdateSuccess();
    }, error => {
      this.isSaving = false;
      this.onRequestError(error);
    });
  }

  onUpdateSuccess() {
    const shouldLogOut: Boolean = this.isEmailChanged();
    this.toastrService.success(this.translate.instant('modals.edit_contact_details.successfully_updated'));

    this.onProfileUpdated.emit({
      email: this.form.get('email').value
    });

    this.bsModalRef.hide();

    if(shouldLogOut) {
      this.authenticationService.logout();
    }
  }

  onRequestError(error) {
    if(error?.error && error?.error['key'] === "ERROR_IDENTITY_IDENTITY_ALREADY_EXISTS") {
      this.toastrService.error(this.translate.instant('modals.edit_contact_details.email_already_exists'));
      return;
    }

    if(error?.error && error?.error['key'] === "ERROR_IDENTITY_EMAIL_CHANGE_CODE_INVALID" || error?.error['key'] === "ERROR_IDENTITY_EMAIL_CHANGE_CODE_EXPIRED") {
      this.toastrService.error(this.translate.instant('pages.flow.onboarding_details.wrong_code'));
      return;
    }

    this.toastrService.error(this.translate.instant('form.feedback.something_went_wrong'));
    return this.bsModalRef.hide();
  }

  resendCode(event) {
    event.preventDefault();
    this.requestEmailVerificationCode(this.form.get('email').value);
  }
}
