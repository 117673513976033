<div class="body-content">
  <div class="container">
    @if (isLoading) {
      <app-page-loader />
    } @else {

      @if (!appointments.length) {
        <div class="empty-state my-5 my-lg-7 my-xl-9">
          <span [inlineSVG]="'./assets/svg-color/no-notifications-graphic.svg'"></span>
          <h6 class="h7">{{ 'pages.default.calendar.no_appointments_yet' | translate }}</h6>
        </div>
      } @else {
        <div class="list-group">
          <div class="list-group-item list-group-header p-end-5">
            <div class="row w-100">
              <div class="col-4">
                <p>{{ 'pages.default.calendar.heading.datetime' | translate }}</p>
              </div>
              <div class="col-4">
                <p>{{ 'pages.default.calendar.heading.title' | translate }}</p>
              </div>
            </div>
          </div>

          @for (appointment of appointments; track appointment.uid) {
            @if (showHeading($index, appointment, appointments[$index - 1])) {
              <div class="list-group-item list-group-sub-header">
                {{ ((appointment?.start_date)? appointment?.start_date : appointment?.date) | timeZoneDate:'MMMM' }}
              </div>
            }

            @if (appointment.status_of_user !== 'REMOVED') {
              <button
                type="button"
                id="item-{{ appointment.uid }}"
                class="list-group-item clickable"
                (click)="selectAppointment($event, appointment)"
                [ngClass]="{ 'cancelled': appointment.hasCancelledState(), 'highlight': appointment.hasNoneState() }"
              >
                <div class="row w-100">
                  <div class="col-4">
                    <p>
                      <span class="date m-end-1 font-weight-medium">
                        {{ (appointment.date || appointment.start_date) | timeZoneDate }}
                      </span>

                      <span class="time" [ngClass]="{'dir-ltr': languageService.isHebrew}">
                        @if (appointment.start_date && appointment.end_date) {
                          {{ appointment.start_date | time }} - {{ appointment.end_date | time }}
                        } @else {
                          <span class="font-weight-bold" [ngClass]="appointment.hasCancelledState() ? 'text-muted' : 'text-info'">
                            {{ 'pages.default.calendar.full_day' | translate }}
                          </span>
                        }
                      </span>
                    </p>
                  </div>

                  <div class="col">
                    <p class="title font-weight-medium">{{ appointment.title }}</p>
                  </div>
                </div>

                <div class="btn btn-sm btn-tertiary btn-icon">
                  <span class="rtl-mirrored-inline-svg" [inlineSVG]="'./assets/svg/arrow-right-32.svg'"></span>
                </div>
              </button>
            }
          }
        </div>
      }
    }
  </div>
</div>
