<div class="bg-pattern-light">
  <div class="container">
    <div class="layout-sidebar">
      <div class="sidebar-content is-flexed responsive-sidebar">
        <div class="content-part">
          <div class="sidebar-logo d-none d-sm-block" [inlineSVG]="'./assets/svg/logo-polyphonic.svg'"></div>
          <div class="icon icon-lg d-block d-sm-none pb-4" [inlineSVG]="'./assets/svg-color/polyphonic-hexagon.svg'"></div>
        </div>

        <div class="content-part d-block d-sm-none">
          <div class="mb-4">
            <h5 class="m-0">{{ 'components.flow_welcome.welcome_to' | translate }}</h5>
            <h4 class="m-0">
              <span>Polyphonic</span>
              {{ 'components.flow_welcome.care' | translate }}
            </h4>
          </div>
        </div>

        <div class="content-part">
          <h6 class="mb-3">{{ 'pages.flow.self_onboarding_confirm.registering_for' | translate }} <span
              class="text-info" id="text_treatment">{{ careModule }}</span> {{ 'pages.flow.self_onboarding_confirm.at' |
            translate }}
            <span class="text-info" id="text_hospital">{{hospital?.name }}</span> {{
            'pages.flow.self_onboarding_confirm.correct' | translate }}
          </h6>

          <p>
            <a id="link_code_confirm" href="" class="text-link"
              (click)="handleConfirm($event);">
              <span>{{ 'pages.flow.self_onboarding_confirm.yes_correct' | translate }}</span>
              <span class="icon-end rtl-d-none" [inlineSVG]="'./assets/svg/arrow-right-24.svg'"></span>
              <span class="icon-end d-none rtl-d-inline" [inlineSVG]="'./assets/svg/arrow-left-24.svg'"></span>
            </a>
          </p>
          <p>
            <a id="link_code_decline" href="" class="text-link"
              (click)="handleDecline($event);">
              <span>{{ 'pages.flow.self_onboarding_confirm.no_not_correct' | translate }}</span>
              <span class="icon-end rtl-d-none" [inlineSVG]="'./assets/svg/arrow-right-24.svg'"></span>
              <span class="icon-end d-none rtl-d-inline" [inlineSVG]="'./assets/svg/arrow-left-24.svg'"></span>
            </a>
          </p>
        </div>

        <div class="footer-part mt-auto">
          <app-flow-copyrights></app-flow-copyrights>
        </div>
      </div>
    </div>

    <div class="layout-body fixed with-background d-none d-sm-block">
      <div class="body-content">
        <app-flow-welcome></app-flow-welcome>
      </div>

      <div class="layout-background"></div>
    </div>
  </div>
</div>
