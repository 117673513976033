import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { BsModalService } from 'ngx-bootstrap/modal';
import { Router } from '@angular/router';
import { DataService } from './../../../services/data.service';
import { SelfRegistrationService } from './../../../services/self-registration.service';
import { Consent } from './../../../models/consent';
import { Hospital } from './../../../models/hospital';
import { TranslateService } from '@ngx-translate/core';
import { ErrorService } from '../../../services/error.service';
import { OnboardingDetailsFormComponent } from '../../../components/onboarding-details-form/onboarding-details-form.component';
import moment, { Moment } from 'moment';
import 'moment/min/locales';
import { LanguageService } from '../../../services/language.service';
import { LanguageCode } from '../../../models/language-code';
import { OnBoardingType } from '../../../enums/onboarding-type';
import { OnboardingState } from '../../../enums/onboarding-state';

@Component({
  selector: 'app-self-onboarding-details',
  templateUrl: './self-onboarding-details.component.html',
  styleUrls: ['./self-onboarding-details.component.scss']
})
export class SelfOnboardingDetailsComponent implements OnInit {
  @ViewChild('inputProfilePicture') inputProfilePicture: ElementRef;
  @ViewChild('form') formComponent: OnboardingDetailsFormComponent;

  public onboardingCode: string;
  public onboardingLanguage: string;
  public onboardingConsents: Consent[] = [];
  public onboardingHospital: Hospital;

  public onBoardingType = OnBoardingType.SELF;
  public currentState = OnboardingState.PROFILE;

  public form: UntypedFormGroup;
  public validationVisible: boolean;
  public errorToast: any;
  public contactConsented: boolean;
  public isLoading: boolean;
  public formComponentData: any;

  constructor(
    public formBuilder: UntypedFormBuilder,
    public toastService: ToastrService,
    public modalService: BsModalService,
    public dataService: DataService,
    public selfRegistrationService: SelfRegistrationService,
    public router: Router,
    public translate: TranslateService,
    public errorService: ErrorService,
    public languageService: LanguageService
  ) { }

  ngOnInit() {
    this.onboardingCode = this.dataService.get(DataService.KeySelfOnboardingCode);
    this.onboardingConsents = this.dataService.get(DataService.KeySelfOnboardingConsents);
    this.onboardingHospital = this.dataService.get(DataService.KeySelfOnboardingHospital);

    const languageCode: LanguageCode = this.languageService.getCurrentLanguageCode();
    this.onboardingLanguage = languageCode.value;

    if (!this.onboardingCode) {
      this.dataService.clearSelfOnboardingKeys();
      this.router.navigateByUrl('register/code');
      return;
    }

    this.formComponentData = {
      locale: this.onboardingHospital.default_locale
    }

    this.determineContactConsented();
  }

  determineContactConsented() {
    let allPfContactConsents = this.onboardingConsents.filter(consent => consent.isPlatformContactConsent());

    let allConsentedPfContactConsents = allPfContactConsents.filter(consent => consent.isConsented());

    if (allPfContactConsents?.length && allPfContactConsents?.length === allConsentedPfContactConsents?.length) {
      this.contactConsented = true;
    } else {
      this.contactConsented = false;
    }
  }

  onFormInitialized(form: UntypedFormGroup) {
    this.form = form;
    this.form.statusChanges.subscribe(() => this.toggleErrorToast());
  }

  formSubmit() {
    if (!this.form.valid) {
      this.validationVisible = true;
    } else {
      this.validationVisible = false;
      this.processForm();
    }

    this.toggleErrorToast();
  }

  toggleErrorToast() {
    if (this.validationVisible && !this.form.valid && !this.toastService.toasts.includes(this.errorToast)) {
      this.errorToast = this.toastService.error(this.translate.instant('form.feedback.data_error'), null);
    } else if (this.form.valid && this.errorToast) {
      this.toastService.clear(this.errorToast.toastId);
      this.errorToast = undefined;
    }
  }

  processForm() {
    const data = {
      gender: this.form.value.gender,
      first_name: this.form.value.first_name,
      preferred_name: this.form.value.preferred_name,
      last_name: this.form.value.last_name,
      country: this.form.value.country,
      date_of_birth: this.convertDate(moment(this.form.value.date_of_birth)),
      email: this.form.value.email,
      profile_picture: this.form.get('profile_picture').value,
      mobile_verification_code: this.form.get('mobile_verification_code').value,
      phone_number: {
        code: this.form.get('phone_number.code').value,
        number: this.form.get('phone_number.number').value
      },
      notification_channels: [],
      contact_channel: undefined
    };


    if (this.form.get('notifications_method_email').value) {
      data.notification_channels.push('EMAIL');
    }

    if (this.form.get('notifications_method_text').value) {
      data.notification_channels.push('SMS');
    }


    if (data.country === '') {
      delete data.country;
    }

    if (data.phone_number.code === '' || data.phone_number.number === '') {
      delete data.phone_number;
    }

    if (data.contact_channel === '') {
      delete data.contact_channel;
    }

    if (data.preferred_name === '') {
      delete data.preferred_name;
    }

    if (data.profile_picture === '') {
      delete data.profile_picture;
    }

    if (data.contact_channel === '') {
      delete data.contact_channel;
    }

    this.isLoading = true;
    this.selfRegistrationService.postSelfRegistration(
      this.onboardingConsents,
      data,
      this.onboardingCode,
      this.onboardingLanguage).subscribe(
        () => this.onRegistrationSuccess(),
        (error) => this.onRegistrationError(error)
      );
  }

  onRegistrationSuccess() {
    this.isLoading = false;
    this.router.navigateByUrl('/register/approval');
  }

  onRegistrationError(error) {
    this.isLoading = false;
    const errorArray = error?.error?.errors;

    if (errorArray) {
      this.validationVisible = true;

      errorArray.forEach(err => {
        const field = err.field.replace('patient.', '');
        if (this.form.get(field)) {
          this.form.get(field).setErrors({
            backend_errors: true,
            message: err.key
          });
        }
      });
    }
  }

  convertDate(date: Moment): string {
    const current_locale = moment().locale();
    const dateString = moment(date).format('YYYY-MM-DD');
    moment().locale(current_locale);
    return dateString;
  }
}
