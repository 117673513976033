<div class="modal-header">
  <h6 class="h7">{{ 'modals.edit_convo_subject.edit_subject' | translate }}</h6>
  <button type="button" class="btn-close" id="edit-conversation-subject-modal-close" data-dismiss="modal" aria-label="Close" (click)="bsModalRef.hide()"></button>
</div>

<div class="modal-body" [ngClass]="{'validation-visible': validationVisible}">
  <form [formGroup]="form">

    <div class="form-group required">
      <div class="d-flex justify-content-between mb-1">
        <label for="inputSubject">{{ 'modals.edit_convo_subject.subject' | translate }}</label>
        <p class="small text-muted m-0">{{form?.controls?.subject?.value?.length}}/{{subjectMaxLength}}</p>
      </div>
      <input type="text" id="inputSubject" class="form-control" formControlName="subject" maxlength="{{subjectMaxLength}}" required>
      <app-feedback-field [field]="form.get('subject')"></app-feedback-field>
    </div>
  </form>
</div>

<div class="modal-footer">
  <div class="m-start-auto">
    <button class="btn btn-secondary" (click)="handleCancel()">{{ 'action.cancel' | translate }}</button>

    <button class="btn btn-primary m-start-2" [ngClass]="{'loader': isSaving}" (click)="handleSubmit()">
      <span class="loader" *ngIf="isSaving" [inlineSVG]="'./assets/svg/loader-dark-sm.svg'"></span>
      <span>{{ 'action.save' | translate }}</span>
    </button>
  </div>
</div>
