<div class="panes-view">

  <div class="pane-list">

    <!-- -->
    <!-- List Header Start -->
    <!-- -->
    <div class="layout-header header-auto">
      <div class="header-content">
        <div class="title-button">
          <h6 class="p-end-3">{{ 'pages.default.conversations.my_convos' | translate }}</h6>
          <button class="btn btn-primary" id="conversations-add-new" (click)="showNewConversationModal()">
            <span [inlineSVG]="'./assets/svg/add-24.svg'"></span>
            <span>{{ 'pages.default.conversations.new' | translate }}</span>
          </button>
        </div>
      </div>
    </div>
    <!-- -->
    <!-- List Header End -->
    <!-- -->




    <!-- -->
    <!-- List Empty State Start -->
    <!-- -->
    <!-- <div class="empty-state pb-4" *ngIf="!loadingInitialConversations && !conversations?.length">
      <p>{{ 'pages.default.conversations.no_convos_yet' | translate }}</p>
    </div> -->
    <!-- -->
    <!-- List Empty State End -->
    <!-- -->



    <!-- -->
    <!-- List Items Start -->
    <!-- -->
    <div infiniteScroll
    [infiniteScrollDistance]="2"
    [infiniteScrollThrottle]="50"
    (scrolled)="onScroll($event)"
    [scrollWindow]="false"
    class="items"
    #conversationsList >


      <!-- -->
      <!-- List Loader Start -->
      <!-- -->
      <app-page-loader *ngIf="loadingInitialConversations"></app-page-loader>

      <app-page-loader *ngIf="!loadingInitialConversations && isLoading && !conversations?.length"></app-page-loader>
      <!-- -->
      <!-- List Loader End -->
      <!-- -->


      <button id="convo-item-{{index}}" class="convo-item"
        (click)="selectConversation(conversation)" *ngFor="let conversation of conversations; index as index"
        [ngClass]="{
          'draft': conversation?.draft,
          'has-unreads': (!conversation?.draft && conversation?.unread_messages),
          'selected': (currentConversation == conversation),
          'convo-with-patient': conversation?.patient,
          'convo-without-patient': !conversation?.patient
        }" >

        <div class="item-wrapper">
          <div class="avatar-wrapper" *ngIf="conversation?.activeParticipants()?.length > 1">
            <app-avatar [profilePicture]="conversation?.activeParticipants()[0]?.profile_picture"></app-avatar>

            <div class="others-counter">
              +{{conversation?.activeParticipants()?.length-1}}
            </div>
          </div>

          <div class="avatar-wrapper" *ngIf="conversation?.activeParticipants()?.length <= 1">
            <app-avatar [profilePicture]="conversation?.activeParticipants()[0]?.profile_picture"></app-avatar>
          </div>


          <div class="content-wrapper">

            <p class="title">{{ conversation?.subject }}</p>

            <div class="content" *ngIf='!conversation?.draft' [ngSwitch]="conversation?.latest_message_type">
              <p *ngSwitchCase="'FILE_MESSAGE'">{{ conversation?.latest_message? conversation?.latest_message :
                'pages.default.conversations.event_file_message' | translate }}</p>
              <p *ngSwitchCase="'AUDIO_MESSAGE'">{{ conversation?.latest_message? conversation?.latest_message :
                'pages.default.conversations.event_audio_message' | translate }}</p>
              <p *ngSwitchCase="'MESSAGE_REMOVED'">{{ 'pages.default.conversations.event_message_removed' | translate }}
              </p>
              <p *ngSwitchCase="'CHANGED_SUBJECT'">{{ 'pages.default.conversations.event_changed_subject' |
                translate:{newValue: conversation?.subject} }}</p>
              <p *ngSwitchCase="'HCP_LEFT'">{{ 'pages.default.conversations.event_hcp_left' | translate }}</p>
              <p *ngSwitchCase="'HCP_JOINED'">{{ 'pages.default.conversations.event_hcp_joined' | translate }}</p>
              <p *ngSwitchCase="'HCP_REMOVED'">{{ 'pages.default.conversations.event_hcp_removed' | translate }}</p>
              <p *ngSwitchDefault>{{ conversation?.latest_message }}</p>
            </div>
            <div class="content" *ngIf='conversation?.draft'>
              <p>{{ 'pages.default.conversations.draft_preview_text' | translate }}</p>
            </div>

            <p class="names" *ngIf="conversation?.fullNames()">{{conversation.fullNames()}}</p>

          </div>

          <div class="meta-wrapper">
            <p class="date" *ngIf='!conversation?.draft'>{{ conversation?.latest_message_time | simpleDate }}</p>
            <p class="date" *ngIf='conversation?.draft'>{{ conversation?.created_at | simpleDate }}</p>

            <div class="unread-marker"></div>
          </div>
        </div>

        <hr/>
      </button>


      <!-- -->
      <!-- List Empty State Start -->
      <!-- -->
      <div class="empty-state mt-0 py-5" *ngIf="!loadingInitialConversations && !conversations?.length && !isLoading">
        <p class="small text-muted">{{ 'pages.default.conversations.no_convos_yet' | translate }}</p>
      </div>
      <!-- -->
      <!-- List Empty State End -->
      <!-- -->

    </div>
  </div>

  <!-- -->
  <!-- Conversation Empty States Start -->
  <!-- -->
  <div class="pane-detail empty" *ngIf="!currentConversation">
    <div class="empty-state">
      <span [inlineSVG]="'./assets/svg-color/no-notifications-graphic.svg'"></span>
      <h6 class="h7">{{ 'pages.default.conversations.convo_empty_title' | translate }}</h6>
      <p class="mx-lg-9">{{ 'pages.default.conversations.convo_empty_text' | translate }}</p>
    </div>
  </div>
  <!-- -->
  <!-- Conversation Empty States End -->
  <!-- -->

  <div class="pane-detail conversation" *ngIf="currentConversation">

    <!-- -->
    <!-- Conversation Header Start -->
    <!-- -->
    <div class="layout-header-pusher header-conversation"></div>
    <div class="layout-header header-conversation">
      <div class="header-content">
        <div class="title-button">
          <h6 class="text-truncate">{{ currentConversation.subject }}</h6>

          <div class="dropdown" dropdown placement="bottom right" *ngIf="currentConversation.owner">
            <button id="button-convo-settings" dropdownToggle type="button" class="dropdown-toggle btn btn-icon btn-tertiary"
                    aria-controls="dropdown-convo-settings">
                    <span [inlineSVG]="'./assets/svg/more-24.svg'"></span>
            </button>
            <ul id="dropdown-convo-settings" *dropdownMenu class="dropdown-menu dropdown-menu-right" role="menu" aria-labelledby="button-convo-settings">
              <li role="menuitem">
                <a class="dropdown-item" href="" id="button-convo-edit-subject" (click)="showEditSubjectModal($event)">{{ 'pages.default.conversations.edit_subject' | translate }}</a>
              </li>
            </ul>
          </div>
        </div>

        <div class="participants">
          <div class="participants-group">
            <p class="title">{{ 'pages.default.conversations.participants' | translate }}</p>

            <div class="group-items">

              <ng-container *ngIf="(currentConversation.activeParticipants().length <= 3)">
                <div class="item" *ngFor="let participant of currentConversation.activeParticipants()">
                  <app-avatar [profilePicture]="participant?.profile_picture"></app-avatar>
                  <span class="label">{{ participant.getFullName() }}</span>
                </div>
              </ng-container>

              <ng-container *ngIf="(currentConversation.activeParticipants().length > 3)">
                <div class="item" *ngFor="let participant of currentConversation.activeParticipants().slice(0,2)">
                  <app-avatar [profilePicture]="participant?.profile_picture"></app-avatar>
                  <span class="label">{{ participant.getFullName() }}</span>
                </div>
                <a href="" class="item" id="button-convo-show-other-participants" (click)="showParticipantsModal($event, currentConversation.activeParticipants().slice(2))">
                  <span class="avatar" [inlineSVG]="'./assets/svg/participants-outline-24.svg'"></span>
                  <span class="label">{{ 'pages.default.conversations.and' | translate }} {{ currentConversation.activeParticipants().length - 2 }} {{ 'pages.default.conversations.others' | translate }}</span>
                </a>
              </ng-container>

            </div>
          </div>

        </div>
      </div>
    </div>
    <!-- -->
    <!-- Conversation Header End -->
    <!-- -->

    <!-- -->
    <!-- Scroll 'End' Position Start -->
    <!-- -->
    <div class="scroll-end-position"></div>
    <!-- -->
    <!-- Scroll 'End' Position End -->
    <!-- -->

    <!-- -->
    <!-- Conversation Start -->
    <!-- -->
    <div class="body-content pb-0 conversation_items" infiniteScroll
    [infiniteScrollDistance]="0"
    [infiniteScrollThrottle]="50"
    (scrolledUp)="onScrollItems($event)"
    [scrollWindow]="false" >

      <div class="conversation-note note-primary" *ngIf="currentConversation.standard_reply_in_days">
        <div class="note-content">
          {{ 'pages.default.conversations.expect_response_in_days' | translate:{days:currentConversation.standard_reply_in_days} }}
        </div>
      </div>

      <div class="text-center text-muted py-3" *ngIf="isConversationLoading">
        <div class="spinner spinner-sm my-0 mx-auto" [inlineSVG]="'./assets/svg/loader-light-sm.svg'"></div>
      </div>

      <div>
        <div *ngFor="let item of currentConversation.items; let i = index; let last = last; trackBy: trackByFn" >

          <div *ngIf="(i == 0 || differentPage(item, currentConversation.items[i-1]))" [attr.id]="'page' + item.page">
            <div class="text-center text-muted py-3" *ngIf="loadingPage(item.page)">
              <div class="spinner spinner-sm my-0 mx-auto" [inlineSVG]="'./assets/svg/loader-light-sm.svg'"></div>
            </div>
          </div>

          <div class="conversation-day" *ngIf="i == 0 || differentDate(item, currentConversation.items[i-1])">
            <p>{{ item.created_at | simpleDate }}</p>
          </div>

          <div class="convo-message"
          [ngClass]="{
            'side-sender': isSender(item),
            'side-receiver': !isSender(item),
            'last': last
          }"
          *ngIf="['MESSAGE', 'FILE_MESSAGE', 'AUDIO_MESSAGE', 'MESSAGE_REMOVED'].includes(item.type)"
          (mouseleave)="onEntryMouseLeave(item)"
          >

            <div class="wrapper wrapper-{{ item.type | lowercase }}"
              [ngClass]="{'wrapper-muted': ['PENDING', 'TOO_LARGE', 'VIRUS_DETECTED', 'ERROR', 'INCONSISTENT_MIME_TYPE'].includes(item.file_status) }"
              (mouseenter)="onEntryMouseEnter(item)">

              <div class="dropdown options-button" dropdown placement="top right" *ngIf="mustShowItemOptions(item)">
                <button id="button_convo_entry_dropdown" dropdownToggle type="button" class="dropdown-toggle" aria-controls="note_dropdown">
                  <span [inlineSVG]="'./assets/svg/more-24.svg'"></span>
                </button>

                <ul id="note_dropdown" *dropdownMenu class="dropdown-menu dropdown-menu-right" role="menu" aria-labelledby="button_convo_entry_dropdown">
                  <li role="menuitem">
                    <a class="dropdown-item text-danger" href="" (click)="deleteMessage($event, item)" >{{ 'pages.default.conversations.delete_message' | translate }}</a>
                  </li>
                </ul>
              </div>

              <app-avatar [profilePicture]="currentConversation.getParticipantForUid(item.created_by)?.profile_picture"></app-avatar>

              <div class="name" *ngIf="!isSender(item)">{{ currentConversation.getParticipantForUid(item.created_by)?.getFullName() }}</div>

              <!--  -->
              <!-- Text Message - Start -->
              <!--  -->
              <div class="message" *ngIf="item.type === 'MESSAGE'">
                <p>{{ item.message }}</p>
                <div class="timestamp">{{ item.created_at | time }}</div>
              </div>
              <!--  -->
              <!-- Text Message - End -->
              <!--  -->

              <!--  -->
              <!-- File Message - Start -->
              <!--  -->
              <div class="attachment" *ngIf="item.type === 'FILE_MESSAGE'">
                <a href="" class="preview" (click)="openAttachment($event, item)" *ngIf="item?.file_status === 'SAFE'">
                  <span [inlineSVG]="'./assets/svg/file-general-24.svg'" class="icon"></span>

                  <span class="info">
                    <p class="file-name">{{ item?.file?.file_name}} {{ item?.file?.created_at | timeZoneDate }} {{ 'general.at' | translate }} {{ item?.file?.created_at | time }}</p>
                    <p class="meta"><span class="text-uppercase">{{ item?.file?.extension }}</span> {{ 'pages.default.conversations.file' | translate }}<span *ngIf="item?.file?.size"> ({{ item?.file?.size | bytes}})</span></p>
                  </span>

                  <div class="timestamp" *ngIf="!item?.message">{{ item.created_at | time }}</div>
                </a>

                <div *ngIf="item?.file_status !== 'SAFE'">
                  <div class="preview" [ngSwitch]="item?.file_status">
                    <span [inlineSVG]="'./assets/svg/file-general-24.svg'"></span>

                    <p class="file-name" *ngIf="item?.file">{{ item.file?.file_name}} {{ item.file?.created_at | timeZoneDate }} {{ 'general.at' | translate }} {{ item.file?.created_at | time }}</p>

                    <p class="error-message" *ngSwitchCase="'INCONSISTENT_MIME_TYPE'">{{ 'pages.default.conversations.upload_failed_inconistent_mime_type' | translate }}</p>
                    <p class="error-message" *ngSwitchCase="'VIRUS_DETECTED'">{{ 'pages.default.conversations.upload_failed_virus_detected' | translate }}</p>
                    <p class="error-message" *ngSwitchCase="'PENDING'">{{ 'pages.default.conversations.processing' | translate }}</p>
                    <p class="error-message" *ngSwitchDefault>{{ 'pages.default.conversations.upload_failed' | translate }}</p>

                    <div class="pb-1"></div>
                  </div>
                </div>

                <div class="message" *ngIf="item?.message">
                  <p>{{ item.message }}</p>
                  <div class="timestamp">{{ item.created_at | time }}</div>
                </div>
              </div>
              <!--  -->
              <!-- File Message - End -->
              <!--  -->

              <!--  -->
              <!-- Removed Message - Start -->
              <!--  -->
              <div class="conversation-note" *ngIf="item.type === 'MESSAGE_REMOVED'">
                <div class="note-content">
                  {{ 'pages.default.conversations.message_removed' | translate }}
                </div>
              </div>
              <!--  -->
              <!-- Removed Message - End -->
              <!--  -->

              <!--  -->
              <!-- Audio Message - Start -->
              <!--  -->
              <div class="sound" *ngIf="item.type === 'AUDIO_MESSAGE'">
                <app-audio-player
                  [convo]="currentConversation"
                  [convoItem]="item"
                  [inputClass]="!isSender(item) ? '' : 'blue'"
                  [timeStamp]='item.created_at | time'
                ></app-audio-player>
              </div>
              <!--  -->
              <!-- Audio Message - End -->
              <!--  -->
            </div>
          </div>

          <!-- Conversation notes Start -->
          <div class="conversation-note" *ngIf="item.type === 'HCP_JOINED'">
            <div class="note-content">
              {{ 'pages.default.conversations.added_to_convo' | translate:{ firstPerson: currentConversation.getParticipantForUid(item.created_by)?.getFullName(), secondPerson: currentConversation.getParticipantForUid(item.hcp)?.getFullName() } }}
            </div>
          </div>

          <div class="conversation-note" *ngIf="item.type === 'HCP_LEFT'">
            <div class="note-content">
              {{ 'pages.default.conversations.left_the_convo' | translate:{ person: currentConversation.getParticipantForUid(item.created_by)?.getFullName() } }}
            </div>
          </div>

          <div class="conversation-note" *ngIf="item.type === 'HCP_REMOVED' && item.created_by">
            <div class="note-content">
              {{ 'pages.default.conversations.removed_from_convo_by_hcp' | translate:{ firstPerson: currentConversation.getParticipantForUid(item.created_by)?.getFullName(), secondPerson: currentConversation.getParticipantForUid(item.hcp)?.getFullName() } }}
            </div>
          </div>

         <div class="conversation-note" *ngIf="item.type === 'HCP_REMOVED' && !item.created_by">
            <div class="note-content">
              {{ 'pages.default.conversations.removed_from_convo_by_system' | translate:{ person: currentConversation.getParticipantForUid(item.hcp)?.getFullName() } }}
            </div>
          </div>

          <div class="conversation-note" *ngIf="item.type === 'CHANGED_SUBJECT' || item.type === 'SUBJECT'">
            <div class="note-content">
              {{ 'pages.default.conversations.subject_change' | translate:{ person: currentConversation.getParticipantForUid(item.created_by)?.getFullName(), newValue: item.new_message } }}
            </div>
          </div>

        </div>
      </div>
      <div class="scroll-start-position body-content pt-0" #end></div>
    </div>
    <!-- -->
    <!-- Conversation End -->
    <!-- -->

    <!-- -->
    <!-- Scroll 'Start' Position Start -->
    <!-- -->

    <!-- -->
    <!-- Scroll 'Start' Position End -->
    <!-- -->

    <!-- -->
    <!-- Conversation Footer Start -->
    <!-- -->
    <div class="layout-footer-pusher footer-conversation"></div>
    <div class="layout-footer footer-conversation" *ngIf="(currentConversation.activeParticipants().length >= 1)">
      <div class="notice">{{ 'pages.default.conversations.dont_share_sensitive_info' | translate }}</div>

      <div class="footer-pending-attachments" *ngIf="pendingAttachments?.length">
        <!-- attachment - start -->
        <div class="single-item-block my-2 attachment" [ngClass]="{'block-danger': ['TOO_LARGE', 'VIRUS_DETECTED', 'ERROR', 'INCONSISTENT_MIME_TYPE'].includes(attachment.status) }" *ngFor="let attachment of pendingAttachments">
          <div class="block-icon icon-only attachment-icon">
            <span class="icon rtl-mirrored-inline-svg" [inlineSVG]="'./assets/svg/file-general-24.svg'"></span>
          </div>
          <div class="block-wrapper">
            <p class="label attachment-name">{{ attachment.meta.file_name}} {{ 'general.at' | translate }} {{ attachment.meta?.created_at | timeZoneDate }} {{ attachment.meta?.created_at | time }}</p>
            <p class="value attachment-info"><span class="text-uppercase">{{ attachment?.meta?.extension }}</span> {{  'pages.default.conversations.file' | translate }}<span *ngIf="attachment.meta.size"> ({{ attachment.meta.size | bytes}})</span></p>
          </div>

          <div class="block-wrapper no-flex text-nowrap m-start-auto attachment-processing" *ngIf="attachment.status === 'WAITING'">
            <p class="status-badge">{{ 'pages.default.conversations.processing' | translate }}</p>
          </div>

          <div class="block-wrapper no-flex text-nowrap m-start-auto attachment-exceeds" *ngIf="(attachment.status === 'TOO_LARGE')">
            <p class="status-badge">{{ 'pages.default.conversations.size_exceeds' | translate }}</p>
          </div>

          <div class="block-wrapper no-flex text-nowrap m-start-auto attachment-unsafe" *ngIf="(attachment.status === 'VIRUS_DETECTED')">
            <p class="status-badge">{{ 'pages.default.conversations.upload_failed_virus_detected' | translate }}</p>
          </div>

          <div class="block-wrapper no-flex text-nowrap m-start-auto attachment-error" *ngIf="(attachment.status === 'ERROR')">
            <p class="status-badge">{{ 'pages.default.conversations.upload_failed' | translate }}</p>
          </div>

          <div class="block-wrapper no-flex text-nowrap m-start-auto attachment-incosistent-mime-type" *ngIf="(attachment.status === 'INCONSISTENT_MIME_TYPE')">
            <p class="status-badge">{{ 'pages.default.conversations.upload_failed_inconistent_mime_type' | translate }}</p>
          </div>

          <div class="block-btn-wrapper attachment-done m-start-auto my-auto m-end-2" *ngIf="attachment.status !== 'WAITING'">
            <a href="" class="block-action btn btn-tertiary" (click)="setStateToDeleted($event, attachment)">
              <span class="icon" [inlineSVG]="'./assets/svg/close-24.svg'"></span>
            </a>
          </div>
        </div>

        <!-- attachment - start -->
      </div>

      <div class="footer-content">
        <input type="text" #newMsg id="newMsg" [(ngModel)]="newMsgInput" [placeholder]="('pages.default.conversations.type_a_message' | translate)" maxlength="{{newMsgMaxLength}}"
          (keyup.enter)="addMessage()" required>

        <label class="input-button" for="file-input" id="conversation-new-attachment">
          <span class="rtl-mirrored-inline-svg" [inlineSVG]="'./assets/svg/attachment-24.svg'"></span>
          <input #fileInput id="file-input" type="file" class="invisible position-absolute" accept="{{ extensionList }}" (change)="handleAttachmentInput($event.target.files)" multiple>
        </label>

        <button class="input-button" id="conversation-send-new-message" (click)="addMessage()" [disabled]="!canAddMessage">
          <span class="rtl-mirrored-inline-svg" [inlineSVG]="'./assets/svg/send-outline-24.svg'"></span>
        </button>
      </div>
    </div>
    <!-- -->
    <!-- Conversation Footer End -->
    <!-- -->
  </div>
</div>
