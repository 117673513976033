import { Component, EventEmitter, inject, OnInit, Output } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { UserTask } from '../../models/user-task';
import { UserTaskUiConfig, WizardOption } from '../../models/user-task-ui-config';
import { LocaleService } from '../../services/locale.service';
import { BsDatepickerConfig } from 'ngx-bootstrap/datepicker';
import { DateFormat } from '../../models/date-format';
import { FormControl, FormGroup } from '@angular/forms';
import { FormField } from '../../models/form-field';
import { UserTaskService } from '../../services/user-task.service';
import { UserTaskSection } from '../../models/user-task-section';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { ModalService } from '../../services/modal.service';
import { debounceTime } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { QueryList } from '../../models/query-list';


export class Intro {
  intro: string;
  intro_button: string;
}

export class Wrapper {
  sections: Array<UserTaskSection>;
  form_fields: Array<FormField>;
}

@Component({
  selector: 'app-user-task-visual-modal',
  templateUrl: './user-task-visual-modal.component.html',
  styleUrl: './user-task-visual-modal.component.scss',
})
export class UserTaskVisualModalComponent implements OnInit {
  @Output() taskSubmitSuccess: EventEmitter<void> = new EventEmitter();

  public toastrService = inject(ToastrService);
  public userTask: UserTask | QueryList;
  public ui_config: UserTaskUiConfig;
  public form: FormGroup = new FormGroup({});
  public steps: Array<Intro | FormField | UserTaskSection | Wrapper> = [];
  public currentIndex = 0;
  public isLoading = false;
  public isEvaluating = false;
  public isSubmitting = false;
  public isReadOnly: boolean;
  public dateFormat: DateFormat;
  protected translate = inject(TranslateService);
  private _closeAnyway = false;

  constructor(
    public bsModalRef: BsModalRef,
    public modalService: ModalService,
    public localeService: LocaleService,
    public bsDatepickerConfig: BsDatepickerConfig,
    public userTaskService: UserTaskService,
  ) {
  }

  public get currentStep(): FormField | UserTaskSection | Intro | Wrapper {
    return this.steps[this.currentIndex];
  }

  public get modalTitle(): string | null {
    if (this.currentStep.hasOwnProperty('intro')) {
      return null;
    }

    if (this.ui_config?.wizard === WizardOption.SECTIONS) {
      // Can return undefined when using virtual-section for questions not linked to a section
      if (this.currentStep['translationKey'] === 'shared.undefined.undefined') {
        return null;
      }

      return this.currentStep['translationKey'];
    }

    return this.userTask?.translationTitleKey;
  }

  public get showProgressBar(): boolean {
    if (this.currentStep.hasOwnProperty('intro')) {
      return false;
    }

    if (
      (this.steps.length > 2 && this.steps[0].hasOwnProperty('intro')) ||
      (this.steps.length > 1 && !this.steps[0].hasOwnProperty('intro'))
    ) {
      return this.currentIndex >= 0;
    }

    return false;
  }

  public get showBackBtn(): boolean {
    return this.currentIndex > 0;
  }

  public get percentage(): string {
    let current = this.currentIndex + 1;
    let total = this.steps.length;

    if ('intro' in this.steps[0]) {
      current--;
      total--;
    }

    return `${current / total * 100}%`;
  }

  public get isIntroStep(): boolean {
    if (this.currentStep) {
      return 'intro' in this.currentStep;
    }

    return false;
  }

  public get canProceed(): boolean {
    if (this.isIntroStep) {
      return true;
    }

    if (this.ui_config?.wizard === WizardOption.QUESTIONS) {
      return this.form.get(this.currentStep['id'])?.valid;
    }

    if (this.ui_config?.wizard === WizardOption.SECTIONS) {
      for (const formField of this.currentStep['form_fields']) {
        if (this.form.get(formField['id'])?.invalid) {
          return false;
        }
      }

      return true;
    }

    return false;
  }

  // Used by modal.showWithInterceptor
  public get showModalInterceptor(): boolean {
    if (this._closeAnyway) {
      return false;
    }

    return this.form?.dirty;
  }

  // Used by modal.showWithInterceptor
  // This function is called and must finish before close with interceptor
  onBeforeModalHide(): Observable<void> {
    return new Observable<void>(observer => {
      this.userTaskService.saveUserTask(this.userTask.uid, this.userTask.patient.uid, this.form.value).subscribe({
        next: () => {
          observer.next();
          observer.complete();
        },
      });
    });
  }

  ngOnInit(): void {
    this.initialiseUiConfig();
    this.initialiseLocale();
    this.initForm();

    this.initCurrentIndex();
  }

  initCurrentIndex() {
    // AC:
    // When I open the questionnaire
    // Then I expect to see the next question which requires an answer

    let hasValues = false;
    let lastAnsweredIndex = -1;

    for (const [stepIndex, step] of this.steps.entries()) {

      // Handle single fields
      if (step instanceof FormField) {

        if (step.field_value.value) {
          hasValues = true;
          lastAnsweredIndex = stepIndex;
        }

        // Find next required unanswered or invalid question
        if (hasValues && step.required && (!step.field_value.value || this.form.get(step.id)?.invalid)) {
          this.currentIndex = stepIndex;
          return;
        }
      }

      //   Handle sections
      if (step instanceof UserTaskSection || this.isWrapper(step)) {
        // Loop over all form fields in the section
        for (const field of step.form_fields) {
          if (field.field_value.value) {
            hasValues = true;
            lastAnsweredIndex = stepIndex;
          }

          // Find next section that has required unanswered question
          if (hasValues && field.required && (!field.field_value.value || this.form.get(field.id)?.invalid)) {
            this.currentIndex = stepIndex;
            return;
          }
        }
      }

    }

    // Show intro/first step if no fields have a value
    if (!hasValues) {
      this.currentIndex = 0;
      return;
    }

    // If all required questions are answered,
    // show the first unanswered question/section after the last answered one
    // (whether optional or required)
    for (let i = lastAnsweredIndex + 1; i < this.steps.length; i++) {
      const step = this.steps[i];

      if (step instanceof FormField && !step.field_value.value) {
        this.currentIndex = i;
        return;
      }

      if (step instanceof UserTaskSection) {
        for (const field of step.form_fields) {
          if (!field.field_value.value) {
            this.currentIndex = i;
            return;
          }
        }
      }
    }

    // If we get here, all questions (required and optional) are answered
    this.currentIndex = this.steps.length - 1;
  }

  isWrapper(obj): obj is Wrapper {
    return (
      obj &&
      Array.isArray(obj.sections) &&
      Array.isArray(obj.form_fields)
    );
  }


  validationVisible(formControl: FormControl): boolean {
    return formControl.invalid && formControl.dirty;
  }

  initialiseLocale() {
    const preferences = this.localeService.getLocalePreferences();

    this.bsDatepickerConfig.dateInputFormat = this.localeService.getBsDatePickerInputFormat(preferences.locale);
    this.bsDatepickerConfig.adaptivePosition = true;

    this.dateFormat = this.localeService.getLocalePreferences().dateFormat;
  }

  initialiseUiConfig() {
    this.steps = [];
    this.ui_config = this.userTask?.ui_config;

    if (this.ui_config?.wizard === WizardOption.QUESTIONS) {
      this.steps = this.userTask.form_fields.filter(field => field.visible);
    }

    if (this.ui_config?.wizard === WizardOption.SECTIONS) {
      // If there are questions not linked to a section,
      // They are added to a virtual section, so we don't miss them
      if (this.userTask.form_fields?.length) {
        const section = new UserTaskSection({
          id: 'virtual-section',
          visible: true
        });

        section.form_fields = this.userTask.form_fields.filter(field => field.visible);

        this.steps.push(section);
      }

      this.steps.push(...this.userTask.sections.filter(section => section.visible));
    }

    if (!this.ui_config?.hasOwnProperty('wizard') || this.ui_config?.wizard === undefined) {
      const wrapper = {
        sections: this.userTask?.sections.filter(section => section.visible),
        form_fields: this.userTask?.form_fields.filter(field => field.visible)
      } as Wrapper;

      this.steps.push(wrapper);
    }

    if (this.ui_config?.hasIntro) {
      const intro: Intro = {
        intro: this.ui_config.translationIntroKey,
        intro_button: this.ui_config.translationIntroButtonKey,
      };

      this.steps.unshift(intro);
    }
  }

  handleSubmit() {
    if (this.form.invalid) {
      return;
    }

    this.isSubmitting = true;
    this.userTaskService.completeUserTask(this.userTask.uid, this.userTask.patient.uid, { variables: this.form.value }).subscribe(() => {
      this.submitResultHandler();
    }, error => {
      this.errorHandler(error);
    });
  }

  submitResultHandler() {
    this.form.markAsPristine();
    this.isSubmitting = false;

    this.taskSubmitSuccess.emit();

    this.bsModalRef.hide();

    this.toastrService.success(this.translate.instant('pages.default.query_lists.submit_success'));
  }

  errorHandler(error) {
    this.isSubmitting = false;

    const errorArray = error?.error?.errors;

    if (errorArray) {
      errorArray.forEach(err => {

        this.form.get(err.field).setErrors({
          backend_errors: true,
          message: err.key
        });

        this.form.updateValueAndValidity();
      });
    }
  }

  stepForward() {
    if (this.currentIndex < this.steps.length - 1) {
      this.currentIndex++;
    }

    this.form.get(this.currentStep['id'])?.updateValueAndValidity();

    document.querySelector('.modal-body').scrollTo({ top: 0, behavior: 'smooth' });
  }

  stepBack() {
    if (this.currentIndex > 0) {
      this.currentIndex--;
    }
  }

  // FORM FUNCTIONS
  // FORM FUNCTIONS
  // FORM FUNCTIONS
  // FORM FUNCTIONS
  // FORM FUNCTIONS
  // FORM FUNCTIONS
  // FORM FUNCTIONS
  // FORM FUNCTIONS
  initForm() {
    if (this.userTask?.form_fields) {
      this.userTask.form_fields.forEach(field => {
        this.createControl(field);
      });
    }

    if (this.userTask?.sections?.length) {
      this.userTask.sections?.forEach(section => {
        section.form_fields?.forEach(field => {
          this.createControl(field);
        });
      });
    }

    if (this.isReadOnly) {
      this.form.disable();
    } else if (this.userTask.hasDff) {
      this.evaluateControls();
    }
  }

  createControl(field: FormField) {
    const control = new FormControl(null, []);

    if (field.read_only) {
      control.disable();
    }

    control.setValidators(field.validators);

    if (field.field_value?.value !== undefined && field.field_value?.value !== null) {
      control.setValue(field.field_value?.value);
    }

    if (field.evaluation_required) {
      // Trigger evaluateControls() when value is updated
      control?.valueChanges.pipe(
        debounceTime(400)
      ).subscribe(() => {
        if (this.form.get(field.id)?.dirty) {
          this.evaluateControls();
        }
      });
    }

    this.form.addControl(field.id, control);
    this.form.get(field.id)?.updateValueAndValidity();
  }

  // DFF
  // DFF
  // DFF
  // DFF
  // DFF
  // DFF
  // DFF
  evaluateControls() {
    const data = this.userTaskService.mapPayload(this.form.value);

    if (Object.keys(data).length <= 0) {
      return;
    }

    this.isEvaluating = true;
    this.userTaskService.evaluateUserTask(this.userTask.uid, this.userTask.patient.uid, data).subscribe({
      next: result => {
        this.handleEvaluateControls(result);
      },
      error: () => {
        this.isEvaluating = false;
      },
      complete: () => {
        // Timeout to make the animation smoother
        setTimeout(() => this.isEvaluating = false, 500);
      }
    });
  }

  handleEvaluateControls(result) {
    this.userTask.form_fields.forEach(field => {
      field.visible = result.visible_fields.find(f => f.field_id === field.id && f.visible) !== undefined;
      if (!field.visible) {
        this.resetControl(field);
      }
    });

    this.userTask.sections.forEach(section => {
      section.visible = result.visible_sections.find(s => s.section_id === section.id && s.visible) !== undefined;

      section.form_fields.forEach(field => {
        field.visible = result.visible_fields.find(f => f.field_id === field.id && f.visible) !== undefined;
        if (!field.visible) {
          this.resetControl(field);
        }
      });
    });

    this.initialiseUiConfig();
  }

  resetControl(field: FormField) {
    if (!field.visible) {
      this.form.get(field.id)?.reset();
      this.form.get(field.id)?.clearValidators();
    } else {
      this.form.get(field.id)?.setValidators(field.validators);
    }

    this.form.get(field.id)?.updateValueAndValidity();
  }
}
