import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { forkJoin, Observable } from 'rxjs';
import { Pathway } from '../../models/pathway';
import { PathwayMessage } from '../../models/pathway-message';
import { Reminder } from '../../models/reminder';
import { UserTaskBasic } from '../../models/user-task-basic';
import { PatientService } from '../../services/patient.service';
import { ScopeService } from '../../services/scope.service';
import { UserTaskService } from '../../services/user-task.service';

@Component({
  selector: 'app-patient-reminders',
  templateUrl: './patient-reminders.component.html',
  styleUrls: ['./patient-reminders.component.scss']
})
export class PatientRemindersComponent implements OnInit {

  @Input('pathway')
  public set pathway(pathway: Pathway) {
    if (this._pathway !== pathway) {
      this._pathway = pathway;

      if (this._pathway) {
        this.loadReminders();
      }
    }
  }
  public get pathway(): Pathway {
    return this._pathway;
  }
  private _pathway: Pathway;


  @Output() onUpdate: EventEmitter<void> = new EventEmitter();
  @Output() onCountChange: EventEmitter<number> = new EventEmitter();

  public patientUid: string;

  public reminders: Array<Reminder | UserTaskBasic | PathwayMessage>;

  public pathwayReminders: Array<Reminder | UserTaskBasic | PathwayMessage>;

  // Action Reminders
  public actionReminders: Array<Reminder | UserTaskBasic>;
  public actionRemindersShowMore: boolean = false;
  public actionRemindersPreview: number = 4;

  // Information Reminders
  public informationReminders: Array<Reminder | PathwayMessage>;
  public informationRemindersShowMore: boolean = false;
  public informationRemindersPreview: number = 0;

  // Older Information Reminders
  public oldInformationReminders: Array<PathwayMessage>;


  constructor(
    public patientService: PatientService,
    public userTaskService: UserTaskService,
    public scopeService: ScopeService
  ) { }

  ngOnInit(): void {
    this.patientUid = this.patientService.getCurrentStoredPatientUid();
  }

  //
  // Getter vars
  //

  public get hasReminders(): boolean {
    return this.hasActionReminders || this.hasInfoReminders;
  }

  public get hasActionReminders(): boolean {
    return this.actionReminders?.length > 0;
  }

  public get hasInfoReminders(): boolean {
    return (this.informationReminders?.length + this.oldInformationReminders?.length) > 0;
  }

  public get otherInfoRemindersCount(): number {
    let count: number = this.informationReminders?.length;

    count += this.oldInformationReminders?.length;

    return count;
  }

  public get visibleActionReminders(): Array<Reminder | UserTaskBasic> {
    if (this.actionRemindersShowMore) {
      return this.actionReminders;
    } else {
      return this.actionReminders.slice(0, this.actionRemindersPreview);
    }
  }

  public get visibleInformationReminders(): Array<Reminder | PathwayMessage> {
    if (this.informationRemindersShowMore) {
      return this.informationReminders;
    } else {
      return this.informationReminders.slice(0, this.informationRemindersPreview);
    }
  }

  //
  // Get/Set the total count
  //

  private _totalCount: number;
  public setTotalCount() {
    const count = this.actionReminders?.length + this.informationReminders?.length + this.oldInformationReminders?.length;

    if (count !== this._totalCount) {
      this._totalCount = count;
      this.onCountChange.emit(this._totalCount);
    }
  }


  //
  // Methods
  //

  public loadReminders() {
    this.reminders = this.pathway?.reminders;

    forkJoin([
      this.loadTasks(),
      this.loadPathwayMessages(),
    ]).subscribe(() => {
      this.populateGroups();
    });
  }

  public loadTasks(): Observable<void> {
    const patientUid = this.patientService.getCurrentStoredPatientUid();

    const filters = {
      patient_pathway_id: this.pathway.patient_pathway_id,
      max_priority: 90
    };

    return new Observable(observer => {
      this.userTaskService.getUserTasks(patientUid, filters, 0, 100, ['created_at,asc']).subscribe(result => {
        if (result?.tasks) {
          this.reminders = this.reminders.concat(result.tasks);
        }
        observer.next();
        observer.complete();
      });
    });
  }

  public loadPathwayMessages(): Observable<void> {
    return new Observable(observer => {
      this.patientService.getPathwayMessages(this.pathway.patient_pathway_id).subscribe(result => {
        if (result?.messages) {
          this.reminders = this.reminders.concat(result?.messages);
        }
        observer.next();
        observer.complete();
      });
    });
  }

  public populateGroups(): void {
    if (!this.reminders) {
      this.reminders = [];
    }

    //
    // Action reminders
    //

    this.actionReminders = this.sortReminders(this.reminders.filter((reminder: any) => {
      return (reminder.isActionReminder || reminder.className === 'UserTaskBasic');
    }));

    //
    // Info reminders
    //

    const appointmentReminders: Array<any> = this.reminders.filter((reminder: any) => {
      return (reminder.className === 'Reminder' && reminder.type === 'APPOINTMENT');
    });

    const unreadPathwayMessages: Array<any> = this.reminders.filter((reminder: any) => {
      return (reminder.className === 'PathwayMessage' && !reminder.isRead);
    });

    const readRecentPathwayMessages: Array<any> = this.reminders.filter((reminder: any) => {
      return (reminder.className === 'PathwayMessage' && reminder.isRead && reminder.isRecent);
    });

    this.informationReminders = appointmentReminders; // adding appointmentReminders
    this.informationReminders.push(...unreadPathwayMessages); // adding unread pathwayMessages

    this.oldInformationReminders = readRecentPathwayMessages;

    if (this.informationReminders?.length < 1) {
      this.informationRemindersPreview = 0;
    } else {
      this.informationRemindersPreview = 2;
    }

    this.setTotalCount();
  }

  public sortReminders(items): Reminder[] {

    return items.sort((a, b) => {
      let dateA: Date = a.contextTiming;
      let dateB: Date = b.contextTiming;

      return Number(new Date(dateB)) - Number(new Date(dateA));
    });
  }

  showMoreToggle(event: MouseEvent, type: string) {
    event?.preventDefault();

    if (type === "action") {
      this.actionRemindersShowMore = !this.actionRemindersShowMore;
    } else if (type === "information") {
      this.informationRemindersShowMore = !this.informationRemindersShowMore;
    }
  }

  anyReminderUpdated() {
    this.onUpdate.emit();
  }
}
