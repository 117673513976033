<div class="layout-sidebar container">
  <div class="sidebar-content is-flexed">
    <div class="content-part d-none d-sm-block">
      <app-flow-welcome [inSidebar]="true"></app-flow-welcome>
    </div>

    <div class="content-part">
      <p class="text-uppercase text-muted fw-bold small">{{ 'action.create_acc' | translate }}</p>
      <app-onboaring-icon-steps [onBoardingType]="onBoardingType" [currentState]="currentState"></app-onboaring-icon-steps>
    </div>

    <div class="footer-part d-none d-sm-block mt-auto">
      <app-flow-copyrights></app-flow-copyrights>
    </div>
  </div>
</div>

<div class="layout-body">

  <div class="body-content">
    <div class="container">
      <h3 class="mb-3">{{ 'pages.flow.self_onboarding_details.your_details' | translate }}</h3>
      <app-onboarding-details-form #form (onFormInitialized)="onFormInitialized($event)" [contactConsented]="contactConsented" [mode]="'self-onboarding'" [data]="formComponentData"  [validationVisible]="validationVisible" [code]="onboardingCode" [language]="onboardingLanguage"></app-onboarding-details-form>

      <div class="mt-4 d-block d-sm-none">
        <hr/>
        <app-flow-copyrights></app-flow-copyrights>
      </div>
    </div>
  </div>

  <div class="layout-footer-pusher"></div>
  <div class="layout-footer">
    <div class="footer-content">
      <div class="container">
        <button id="button_onboarding_details_confirm" class="btn btn-lg btn-primary m-start-auto" (click)="formSubmit()" [ngClass]="{'loader': isLoading}">
          <span class="loader" *ngIf="isLoading" [inlineSVG]="'./assets/svg/loader-dark-sm.svg'"></span>
          <span>{{ 'action.continue' | translate }}</span>
        </button>
      </div>
    </div>
  </div>

</div>
