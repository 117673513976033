<div class="enum-component">
  <div class="enum-options">
    @for (enum_value of field?.field_value?.enum_values | sortBy: 'asc': 'order'; track enum_value.value) {
    <div class="form-check custom-{{field?.inputType}}">

      @if (field?.inputType === 'checkbox') {
        <input class="form-check-input" type="checkbox" [name]="field.id + enum_value.value"
               [id]="field.id + enum_value.value"
               [value]="enum_value.value" [checked]="field?.enumChecked(enum_value.value, value)"
               (change)="onMultiValueChange(enum_value.value)">
      } @else {
        <input class="form-check-input" type="radio" [name]="field.id + enum_value.value"
               [id]="field.id + enum_value.value"
               [value]="enum_value.value"
               [ngModel]="value" (ngModelChange)="onValueChange($event)" [ngModelOptions]="{standalone: true}">
      }

      <label class="form-check-label enum-option w-100" [for]="field.id + enum_value.value"
             [ngClass]="{'enum-checked': field?.enumChecked(enum_value.value, value), 'enum-has-color': !!getColorFor(enum_value.value)}"
             [style]="{'border-color': getColorFor(enum_value.value), 'color': getColorFor(enum_value.value)}">

        <div class="enum-background-layer" [style]="{'background-color': getColorFor(enum_value.value)}"></div>

        <div class="content d-flex align-items-center position-relative"
             [ngClass]="{'justify-content-center': field.ui_config.showIcon && !field.ui_config.showValue}">
          @if (field.ui_config.showIcon) {
            @switch (getIconFor(enum_value.value)?.type) {
              @case (IconSource.MDI) {
                <p class="mb-0 icon-font mdi {{ 'mdi-' + getIconFor(enum_value.value)?.icon.toLowerCase() }}"
                   [ngClass]="{'m-end-2': field.ui_config.showValue}"></p>
              }
              @case (IconSource.MS) {
                <p class="mb-0 icon-font material-symbols-outlined"
                   [ngClass]="{'m-end-2': field.ui_config.showValue}">{{ getIconFor(enum_value.value)?.icon }}</p>
              }
              @case (IconSource.U) {

                <p class="mb-0 icon-font" [innerHTML]="getIconFor(enum_value.value)?.icon"
                   [ngClass]="{'m-end-2': field.ui_config.showValue}"></p>
              }
            }
          }

          @if (field.ui_config.showValue) {
            <p class="mb-0">{{ enum_value.label | translate }}</p>
          }
        </div>
      </label>
    </div>

      @if(!$last){
        <div class="mb-1"></div>
      }
    }
  </div>
</div>
