import { Image } from "./image";

export class Invitee {
  public uid: string;
  public first_name: string;
  public last_name: string;
  public profile_picture: Image;
  public status: string;
  public job_title: string;
  public type: string;

  constructor(item?) {
    if (item) {
      this.fillFromJson(item);
    }
  }

  fillFromJson(item) {
    this.uid = item.uid ? item.uid : '';
    this.first_name = item.first_name;
    this.last_name = item.last_name;
    this.status = item.status;
    this.type = item.type;
    this.job_title = item.job_title;

    if(item.profile_picture) {
      this.profile_picture  = new Image(item.profile_picture);
    }
  }

  public getFullName() {
    return [this.first_name, this.last_name].join(' ');
  }
}
