<div class="modal-header">
  <h6 class="h7">{{ 'modals.convo_participants.participants' | translate }}</h6>
  <button type="button" class="btn-close" id="conversation-participants-modal-close" data-dismiss="modal" aria-label="Close" (click)="bsModalRef.hide()"></button>
</div>

<div class="modal-body">
  <div class="participant-item" *ngFor="let participant of participants">
    <app-avatar [profilePicture]="participant?.profile_picture"></app-avatar>

    <div class="meta">
      <p class="title">{{ participant.first_name }} {{ participant.last_name }}</p>
    </div>
  </div>
</div>

<div class="modal-footer py-1">
  <div class="m-start-auto">
    <button class="btn btn-primary" (click)="handleClose()">{{ 'action.close' | translate }}</button>
  </div>
</div>
