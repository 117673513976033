import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { PatientService } from '../../services/patient.service';
import { HomeFlowService } from '../../services/home-flow.service';
import { ConversationService } from '../../services/conversation.service';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { Pathway } from '../../models/pathway';
import { GeneralService } from '../../services/general.service';
import { HealthCareProfessional } from '../../models/health-care-professional';
import { Conversation } from '../../models/conversation';
@Component({
  selector: 'app-start-new-conversation-modal',
  templateUrl: './start-new-conversation-modal.component.html',
  styleUrls: ['./start-new-conversation-modal.component.scss']
})
export class StartNewConversationModalComponent implements OnInit {
  @Output() public isNewConversationStarted: EventEmitter<Conversation> = new EventEmitter();

  public validationVisible = false;
  public form: UntypedFormGroup;
  public subjectMaxLength = 48;
  public isCreating: boolean;
  public isLoading: boolean;

  public isLoadingParticipants = false;
  public pathways: Pathway[] = [];
  public participants: HealthCareProfessional[];

  public all_hcps = false;
  public selectedParticipant: any;

  constructor(
    public bsModalRef: BsModalRef,
    public conversationService: ConversationService,
    public formBuilder: UntypedFormBuilder,
    public homeFlowService: HomeFlowService,
    public patientService: PatientService,
    public toastrService: ToastrService,
    public translateService: TranslateService,
    public generalService: GeneralService
  ) {
  }

  ngOnInit(): void {
    this.isLoading = true;
    this.formSetup();
    this.getPathways();
  }

  formSetup() {
    if (this.form) {
      return;
    }

    this.form = this.formBuilder.group({
      pathway: ['', [Validators.required]],
      subject: ['', [Validators.required, Validators.maxLength(this.subjectMaxLength)]]
    });
  }

  getPathways() {
    this.patientService.getPathways().subscribe((pathways: Pathway[]) => {
      this.pathways = pathways;

      if (this.pathways.length === 1) {
        this.form.get('pathway').setValue(this.pathways[0]);
        this.form.updateValueAndValidity();

        this.loadPossibleParticipants();
      } else {

        setTimeout(() => {
          this.isLoading = false;
        }, 300);

      }
    });
  }

  loadPossibleParticipants(): any {
    this.isLoadingParticipants = true;
    this.participants = undefined;

    this.patientService.getPathway(this.form.get('pathway').value?.id).subscribe(pathway => {
      this.onLoadPossibleParticipantsSuccess(pathway.getHcps(true, true));
      this.isLoading = false;
    });
  }

  onLoadPossibleParticipantsSuccess(hcps: any[]) {
    this.isLoadingParticipants = false;
    this.participants = hcps;
    this.generalService.sortByKey(this.participants, 'last_name');
  }

  getAllParticipantNames(): string {
    const names = this.participants?.map(_part => _part.getFullName());
    return names?.join(', ');
  }

  selectTeam(): void {
    this.selectedParticipant = null;
    this.all_hcps = !this.all_hcps;
  }

  setSelectedParticipant(participant): void {
    this.all_hcps = false;
    this.selectedParticipant = (this.selectedParticipant === participant ? null : participant);
  }

  isParticipantChosen(): boolean {
    return (this.selectedParticipant && !this.all_hcps || (!this.selectedParticipant && this.all_hcps));
  }

  isModalValid(): boolean {
    return this.isParticipantChosen() && this.form.valid;
  }

  handleCancel() {
    return this.bsModalRef.hide();
  }

  handleSubmit() {
    if (!this.isCreating) {
      if (!this.isModalValid()) {
        this.validationVisible = true;
      } else {
        this.validationVisible = false;
        this.isCreating = true;

        const data = {
          patient_pathway_id: this.form.get('pathway').value?.id,
          subject: this.form.get('subject').value,
          all_hcps: this.all_hcps,
          hcp_uid: (this.all_hcps ? null : this.selectedParticipant?.uid)
        };

        this.conversationService.addConversation(data).subscribe(
          newConversation => this.onAddConversationSuccess(newConversation),
          error => this.onAddConversationError(error));
      }
    }
  }

  onAddConversationSuccess(newConversation: Conversation) {
    const conversation  = newConversation;
    this.isNewConversationStarted.emit(conversation);
    this.isCreating = false;
    this.bsModalRef.hide();
  }

  onAddConversationError(error) {
    this.toastrService.error(this.translateService.instant('form.feedback.something_went_wrong'));
    this.isCreating = false;

    if (error?.error?.errors) {
      this.onRequestError(error);
    } else {
      this.bsModalRef.hide();
    }

  }

  onRequestError(error) {
    const errorArray = error?.error?.errors;
    this.isCreating = false;

    if (errorArray) {
      this.validationVisible = true;

      errorArray.forEach(err => {
        this.form.get(err.field).setErrors({
          backend_errors: true,
          message: err.key
        });
      });
    }
  }
}
