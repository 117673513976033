<div class="layout-header-pusher header-back"></div>

<div class="layout-header header-back">
  <div class="header-content">
    <div class="container">
      <app-back-link route="/settings" label="action.back_to_settings" id="settings-consent-overview-back" />
    </div>

    <div class="container">
      <h5>{{ 'pages.default.settings.consents_overview.consents' | translate }}</h5>
    </div>
  </div>
</div>

<div class="body-content">
  <div class="container" *ngIf="!consentsLoaded">
    <app-page-loader></app-page-loader>
  </div>

  <div class="container" *ngIf="consentsLoaded">
    <div
      *ngFor="let consent of consents"
      (click)="gotoConsent($event,consent)"
      class="single-item-block item-clickable mb-2"
    >
      <div class="block-icon">
        <span class="icon rtl-mirrored-inline-svg" [inlineSVG]="consent?.icon()"></span>
      </div>
      <div class="block-wrapper">
        <p class="label">{{ consent.title }}</p>
        <p class="value" *ngIf="consent.isConsented()">{{ 'pages.default.settings.consents_overview.consented_on' | translate }} {{ consent.status_at | timeZoneDate:dateFormat.format }}</p>
      </div>
      <div class="block-btn-wrapper">
        <div class="block-action btn btn-lg btn-tertiary btn-icon">
          <span [inlineSVG]="'./assets/svg/arrow-right-32.svg'" class="rtl-mirrored-inline-svg"></span>
        </div>
      </div>
    </div>
  </div>
</div>
