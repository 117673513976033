<div class="layout-sidebar">
  <div class="sidebar-content is-flexed">
    <div class="content-part">
      <div class="sidebar-logo text-jnj" [inlineSVG]="'./assets/svg/logo-polyphonic.svg'"></div>
    </div>

    <div class="content-part">
      <h6>{{ 'pages.flow.device_not_supported.device_no_support' | translate }}</h6>
      <p>{{ 'pages.flow.device_not_supported.open_link_on_mobile' | translate }}</p>
    </div>

    <div class="footer-part mt-auto">
      <app-flow-copyrights></app-flow-copyrights>
    </div>
  </div>
</div>

<div class="layout-body with-background fixed">
  <div class="body-content">
    <app-flow-welcome></app-flow-welcome>
  </div>

  <div class="layout-background"></div>
</div>
