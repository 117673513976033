import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { BsModalService } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { PasswordService } from './../../../services/password.service';
import { HelpCenterModalComponent } from '../../../modals/help-center-modal/help-center-modal.component';
import { GeneralService } from '../../../services/general.service';


@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent implements OnInit {
  public form: UntypedFormGroup;
  public validationVisible: boolean;
  public isLoading: boolean;

  constructor(
    public formBuilder: UntypedFormBuilder,
    public router: Router,
    public passwordService: PasswordService,
    public toastrService: ToastrService,
    public translate: TranslateService,
    public modalService: BsModalService
  ) {
  }

  ngOnInit() {
    this.formSetup();
  }

  formSetup() {
    this.form = this.formBuilder.group({
      email: ['', [Validators.required, Validators.email]],
    });
  }

  formSubmit() {
    if (this.form.valid) {
      this.doRequestPasswordReset();
      this.validationVisible = false;
    } else {
      this.validationVisible = true;
    }
  }

  doRequestPasswordReset() {
    if (!this.form.valid || this.isLoading) {
      return;
    }

    this.isLoading = true;
    this.passwordService.forgotPassword(this.form.value.email).subscribe(
      () => this.onForgotPasswordSuccess(this.form.value.email),
      () => this.onForgotPasswordError()
    )
  }

  onForgotPasswordSuccess(email) {
    this.isLoading = false;

    const msg = this.translate.instant('pages.flow.forgot_password.sent_mail_to_reset', {
      email: email
    });

    this.toastrService.success(msg);
    this.router.navigateByUrl('/');
  }

  onForgotPasswordError() {
    this.isLoading = false;
    this.toastrService.clear();
    this.toastrService.error(this.translate.instant('pages.flow.forgot_password.pw_reset_error'));
  }

  openHelpCenter() {
    this.modalService.show(HelpCenterModalComponent,
      GeneralService.BsModalOptions({
        class: 'modal-dialog-centered modal-lg modal-compact'
      })
    );
  }
}
