import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { GeneralService } from '../../services/general.service';
import { NgSelectComponent } from '@ng-select/ng-select';
import { TranslateService } from '@ngx-translate/core';
import { Profile } from '../../models/profile';
import { UserService } from '../../services/user.service';
import { cloneDeep } from 'lodash';
import { MobileNumber } from '../../interfaces/mobile-number.interface';
import { PhoneValidator } from '../../validators/phone-validator';
import { DialCode } from '../../models/dial-code';
import { LanguageService } from '../../services/language.service';

@Component({
  selector: 'app-edit-phone-modal',
  templateUrl: './edit-phone-modal.component.html',
  styleUrls: ['./edit-phone-modal.component.scss']
})
export class EditPhoneModalComponent implements OnInit {
  @ViewChild(NgSelectComponent) ngSelect: NgSelectComponent;

  @Output() onProfileUpdated: EventEmitter<any> = new EventEmitter();

  public form: UntypedFormGroup;
  public profile: Profile;

  public isLoading = false;
  public validationVisible = false;
  public step: 'phone' | 'code' = 'phone';
  public dialCodeOptions: DialCode[] = [];

  constructor(
    public bsModalRef: BsModalRef,
    public formBuilder: UntypedFormBuilder,
    public generalService: GeneralService,
    public userService: UserService,
    public router: Router,
    public toastrService: ToastrService,
    public translate: TranslateService,
    public languageService: LanguageService
  ) {
  }

  ngOnInit() {
    this.formSetup();
    this.dialCodeOptions = this.generalService.dialCodes;
  }

  formSetup() {
    if (this.form) {
      return;
    }

    this.form = this.formBuilder.group({
      country_code: [this.profile?.mobile_number?.code, Validators.required],
      phone: [this.profile?.mobile_number?.number, [Validators.required, PhoneValidator.validate()]],
      notifications_method_sms: [''],
      mobile_verification_code: ['', [Validators.required]]
    });

    if (this.profile.locale.notification_channels.includes('SMS')) {
      this.form.get('notifications_method_sms').setValue(true);
    }
  }

  handleCancel() {
    return this.bsModalRef.hide();
  }

  handleSubmit() {
    this.validationVisible = false;

    // On the mobile phone step
    if (this.step === 'phone') {
      // phone is not valid
      if (this.form.get('phone').errors) {
        this.validationVisible = true;
        return;
      }

      // verification code is required
      if (this.verificationCodeRequired()) {
        this.requestMobileVerificationCode();
        this.step = 'code';
        return;
      } else {
        this.updateProfile();
      }
    }

    // On the mfa step
    if (this.step === 'code') {
      if (this.form.get('mobile_verification_code').errors) {
        this.validationVisible = true;
        return;
      } else {
        this.updateProfile(this.form.get('mobile_verification_code').value);
      }
    }
  }

  requestMobileVerificationCode() {
    const mobile_number: MobileNumber = {
      code: this.form.value.country_code,
      number: this.form.value.phone
    };

    this.userService.verifyMobileNumber(mobile_number).subscribe(() => {
      this.toastrService.info(this.translate.instant('modals.edit_contact_details.verification_code_sent'));
    });
  }

  verificationCodeRequired() {
    const smsNotificationSelected = (this.form.value.notifications_method_sms && !this.profile.locale.notification_channels.includes('SMS'));
    const phoneNumberChanged = (this.form.value.phone !== this.profile.mobile_number?.number || this.form.value.country_code !== this.profile.mobile_number?.code);


    if ((smsNotificationSelected || phoneNumberChanged) && this.form.value.notifications_method_sms) {
      return true;
    }
    return false;
  }

  updateProfile(code?) {
    this.isLoading = true;

    const profile = this.createProfilePayLoad(code);


    this.userService.updateProfile(profile).subscribe(
      () => this.onUpdateSuccess(),
      (error) => this.onUpdateError(error)
    );
  }

  createProfilePayLoad(code?): Profile {
    const profile: Profile = cloneDeep(this.profile, true);

    if (this.form.value.country_code && this.form.value.phone) {
      if (!profile.mobile_number) {
        profile.mobile_number = {code: '', number: ''};
      }

      profile.mobile_number.code = this.form.value.country_code;
      profile.mobile_number.number = this.form.value.phone;
    }

    if (code) {
      profile.mobile_verification_code = code;
    }

    if (profile.locale) {
      profile.locale.notification_channels = [];

      if (this.form.value.notifications_method_sms) {
        profile.locale.notification_channels.push('SMS');
      }
    }

    return profile;
  }

  onUpdateSuccess() {
    this.isLoading = false;
    this.toastrService.success(this.translate.instant('modals.edit_contact_details.successfully_updated'));

    // visual update
    const mobile_number: MobileNumber = {
      code: this.form.value.country_code,
      number: this.form.value.phone
    };

    this.onProfileUpdated.emit({ mobile_number, notifications_method_sms: this.form.value.notifications_method_sms });

    this.bsModalRef.hide();
  }

  onUpdateError(error) {
    const errorArray = error?.error?.errors;
    this.isLoading = false;

    if (errorArray) {
      this.validationVisible = true;

      errorArray.forEach( err => {
        this.form.get(err.field)?.setErrors({
          backend_errors: true,
          message: err.key
        });
      });
    }
  }

  resendCode(event) {
    event.preventDefault();
    this.requestMobileVerificationCode();
  }

  isLocaleHebrew(): boolean {
    return this.languageService.getCurrentLanguageCode().isHebrew;
  }
}
