import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { MobileNumber } from '../../interfaces/mobile-number.interface';
import { DialCode } from '../../models/dial-code';
import { MfaMethod } from '../../models/mfa-method';
import { Profile } from '../../models/profile';
import { GeneralService } from '../../services/general.service';
import { LanguageService } from '../../services/language.service';
import { UserService } from '../../services/user.service';

@Component({
  selector: 'app-add-mfa-backup-modal',
  templateUrl: './add-mfa-backup-modal.component.html',
  styleUrls: ['./add-mfa-backup-modal.component.scss']
})
export class AddMfaBackupModalComponent implements OnInit {

  public form: UntypedFormGroup;

  public isSaving: boolean;
  public isSendingCode: boolean;
  public useCurrentNumber: boolean;
  public validationVisible: boolean;
  public step: string;

  public profile: Profile;
  public currentDialCode: DialCode;
  public dialCodeOptions: DialCode[] = [];

  @Output() onSuccess: EventEmitter<void> = new EventEmitter();


  constructor(
    public bsModalRef: BsModalRef,
    public formBuilder: UntypedFormBuilder,
    public toastrService: ToastrService,
    public userService: UserService,
    public generalService: GeneralService,
    public translate: TranslateService,
    public languageService: LanguageService
  ) { }

  ngOnInit(): void {
    this.isSaving = false;
    this.isSendingCode = false;
    this.useCurrentNumber = true;

    this.profile = this.userService.getStoredProfile();

    this.dialCodeOptions = this.generalService.dialCodes;

    this.form = this.formBuilder.group({
      mobile_verification_code: ['', [Validators.required]],
      country_code: ['', [Validators.required]],
      phone: ['', [Validators.required]]
    });

    const mobile_number: MobileNumber = this.profile?.mobile_number;
    if(mobile_number && mobile_number?.number && mobile_number?.code) {
      this.useCurrentPhoneData();
      this.step = "choice";
    } else {
      this.step = "input";
    }
  }

  useCurrentPhoneData(): void {
    const mobile_number: MobileNumber = this.profile.mobile_number;
    this.form.get('country_code').setValue(mobile_number.code);
    this.form.get('phone').setValue(mobile_number.number);
    this.currentDialCode = this.generalService.mapPatientPhoneNumberCodeToDialCode(mobile_number.code);
  }

  clearPhoneData(): void {
    this.form.get('country_code').setValue(null);
    this.form.get('phone').setValue(null);
    this.currentDialCode = null;
  }

  get showNextStepBtn(): boolean {
    return ['choice', 'input'].includes(this.step);
  }

  get showConfirmStepBtn(): boolean {
    return ['code'].includes(this.step);
  }

  handleNextStep(): void {
    if(this.step === 'choice') {
      if(this.useCurrentNumber) {
        this.useCurrentPhoneData();
        this.stepRequestCode();
      } else {
        this.clearPhoneData();
        this.stepNewInput();
      }
      return;
    }

    if(this.step === 'input') {
      if(this.form.get('country_code').valid && this.form.get('phone').valid) {
        this.validationVisible = false;
        this.stepRequestCode();
      } else {
        this.validationVisible = true;
      }
      return;
    }
  }

  stepRequestCode(): void {
    this.validationVisible = false;
    this.step = 'code';
    this.requestVerificationCode();
  }

  stepNewInput(): void {
    this.validationVisible = false;
    this.step = 'input';
  }

  handleConfirm(): void {
    if(this.form.valid) {
      this.validationVisible = false;
      this.stepAddToProfile();
    } else {
      this.validationVisible = true;
    }
  }

  requestVerificationCode(event?: MouseEvent, showLoading: boolean = false): void {
    this.isSendingCode = showLoading;

    this.profile.mobile_number = {
      code: this.form.get('country_code').value,
      number: this.form.get('phone').value
    }

    event?.preventDefault();
    this.userService.verifyMobileNumber(this.profile.mobile_number).subscribe(
      () => {
        this.isSendingCode = false;
        this.toastrService.info(this.translate.instant('modals.add_mfa_backup.verification_code_sent'));
      },
      () => {
        this.isSendingCode = false;
      }
    );
  }

  stepAddToProfile(): void {
    let hasSmsMethod:boolean = false;

    if(this.profile?.mfas) {
      hasSmsMethod = this.profile.mfas.filter(_mfa => _mfa.channel === 'SMS').length > 0;
    } else {
      this.profile.mfas = [];
    }

    if(!hasSmsMethod) {
      this.profile.mfas.push(new MfaMethod({
        channel: 'SMS',
        primary: false
      }));
    }

    this.profile.mobile_verification_code = this.form.get('mobile_verification_code').value;

    this.isSaving = true;
    this.userService.updateProfile(this.profile).subscribe(
      () => this.onUpdateProfileSuccess(),
      () => this.onUpdateProfileError()
    );
  }

  onUpdateProfileSuccess(): void {
    this.isSaving = false;
    this.onSuccess.emit();
    this.bsModalRef.hide();

    this.toastrService.success(this.translate.instant('modals.add_mfa_backup.method_added_successfully'));
  }

  onUpdateProfileError(): void {
    this.isSaving = false;
    this.form.get('mobile_verification_code').setValue(null);
  }

  handleCancel(): void {
    return this.bsModalRef.hide();
  }
}
