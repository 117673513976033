import { Component, OnInit } from '@angular/core';
import { PatientService } from '../../../services/patient.service';
import { DataService } from '../../../services/data.service';
import { PageTabItem } from './../../../models/page-tab-item';
import { Pathway } from '../../../models/pathway';
import { LocaleService } from '../../../services/locale.service';
import { DateFormat } from '../../../models/date-format';
import { AppointmentService } from '../../../services/appointment.service';
import { BsModalService } from 'ngx-bootstrap/modal';
import { GeneralService } from '../../../services/general.service';
import { Router } from '@angular/router';
import { UserTaskService } from '../../../services/user-task.service';
import { TranslateHelp } from '../../../pipes/translate-help.pipe';
import { ScopeService } from '../../../services/scope.service';

@Component({
  selector: 'app-dashboard-page',
  templateUrl: './dashboard-page.component.html',
  styleUrls: ['./dashboard-page.component.scss']
})
export class DashboardPageComponent implements OnInit {
  public pageTabItems: PageTabItem[];
  public isLoading = false;
  public pathwayId;
  public patientUid;
  public pathways: Pathway[];
  public selectedPathway: Pathway;
  public time_24_hours: boolean;
  public dateFormat: DateFormat;
  public remindersCount: number = 0;

  constructor(
    public patientService: PatientService,
    public dataService: DataService,
    public localeService: LocaleService,
    public generalService: GeneralService,
    public appointmentService: AppointmentService,
    public modalService: BsModalService,
    public router: Router,
    public userTaskService: UserTaskService,
    public translateHelp: TranslateHelp,
    public scopeService: ScopeService
  ) { }

  ngOnInit() {
    this.patientUid = this.patientService.getCurrentStoredPatientUid();

    const preferences = this.localeService.getLocalePreferences();
    this.time_24_hours = preferences.locale.time_24_hours;
    this.dateFormat = preferences.dateFormat;

    this.pageTabItems = [new PageTabItem('...')];

    this.getPathways();
  }

  getPathways() {
    this.isLoading = true;
    this.patientService.getPathways().subscribe(pathways => {
      this.pathwaysResultHandler(pathways);
    });
  }

  pathwaysResultHandler(pathways: Pathway[]): void {
    this.isLoading = false;

    if (!pathways?.length) {
      this.pageTabItems = [];
      return;
    }

    this.pathways = pathways;
    this.sortByTitle(pathways);

    const pageTabItems: PageTabItem[] = [];

    const cachedSelectedPathway: Pathway = this.dataService.get('selectedPathway') as Pathway;
    let cacheSelectedPageTabItem = undefined;

    this.pathways.forEach(pathway => {
      const pageTabItem = new PageTabItem(this.translateHelp.transform(pathway.care_module.name) , null, pathway);

      if (cachedSelectedPathway && cachedSelectedPathway.id === pathway.id) {
        cacheSelectedPageTabItem = pageTabItem;
      }

      pageTabItems.push(pageTabItem);
    });

    this.pageTabItems = pageTabItems;

    if (cacheSelectedPageTabItem) {
      this.generalService.scrollToPageTabItem(cacheSelectedPageTabItem);
      this.pathwayId = cacheSelectedPageTabItem.data.id;
      this.getPathwayDashboard();
    } else {
      this.onTabChangeHandler(this.pageTabItems[0]);
    }
  }

  sortByTitle(items: Pathway[]): Pathway[] {
    items.sort((a, b) => {
      const titleA = a?.label?.toUpperCase();
      const titleB = b?.label?.toUpperCase();
      if (titleA < titleB) {
        return -1;
      }
      if (titleA > titleB) {
        return 1;
      }

      return 0;
    });

    return items;
  }

  onTabChangeHandler(pageTabItem: PageTabItem): void {
    this.dataService.set('selectedPathway', pageTabItem.data);
    this.pathwayId = pageTabItem.data.id;
    this.getPathwayDashboard();
  }

  getPathwayDashboard(showLoading: boolean = true) {
    this.isLoading = showLoading;

    if (showLoading) {
      this.remindersCount = 0;
      this.selectedPathway = undefined;
    }

    this.patientService.getPathwayDashboard(this.pathwayId).subscribe(result => {
      this.selectedPathway = result;
      this.isLoading = false;
    }, () => {
      this.isLoading = false;
    });
  }

  onRemindersUpdate() {
    this.getPathwayDashboard(false);
  }

  onRemindersCountchange(count: number) {
    this.remindersCount = count;
  }

  public get hasReminders(): boolean {
    return this.remindersCount > 0;
  }
}
