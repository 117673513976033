import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FaqCategory } from '../../../models/faq-category';
import { DataService } from '../../../services/data.service';
import { FaqService } from '../../../services/faq.service';

@Component({
  selector: 'app-faq-overview',
  templateUrl: './faq-overview.component.html',
  styleUrls: ['./faq-overview.component.scss']
})
export class FaqOverviewComponent implements OnInit {
  public patientUid: string;
  public patientPathwayUid: string;
  public faqCategories: Array<FaqCategory>;

  isLoading = true;

  constructor(
    public activatedRoute: ActivatedRoute,
    public faqService: FaqService,
    public dataService: DataService,
    public router: Router
  ) { }

  ngOnInit(): void {
    this.activatedRoute.params.subscribe(params => {
      this.patientUid = params.patientUid;
      this.patientPathwayUid = params.patientPathwayUid;

      this.loadFaqs();
    });
  }

  loadFaqs(): void {
    this.faqService.getFaqCategoriess(this.patientUid, this.patientPathwayUid).subscribe(result => {
      this.faqCategories = result;
      this.isLoading = false;
    });
  }

  goToFaq(event): void {
    this.dataService.set('selectedFaq', event.faq);
    this.dataService.set('selectedFaqCategory', event.category);
    this.router.navigateByUrl(`patient/${this.patientUid}/${this.patientPathwayUid}/faq/${event.faq.uid}`);
  }
}
