<div class="modal-body" [ngClass]="{'validation-visible': validationVisible}">
  <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="bsModalRef.hide()">
    <span [inlineSVG]="'./assets/svg/close-24.svg'"></span>
  </button>

  <h2>{{ 'modals.edit_contact_details.edit_contact_details' | translate }}</h2>

  <form [formGroup]="form">
    <p>{{ 'modals.edit_contact_details.enter_code' | translate }}</p>
    <div class="form-group styled floating-label">
      <input type="text" id="inputCode" class="form-control w-100" [placeholder]="('modals.edit_contact_details.verification_code' | translate)" formControlName="code" required>
      <label for="inputCode">{{ 'modals.edit_contact_details.verification_code' | translate }}</label>

      <p class="validation-feedback general-feedback" *ngIf="form?.controls['code']?.errors?.required">{{ 'form.feedback.field_required' | translate }}</p>

      <p class="mt-1"><a href="" (click)="resendCode($event)">Resend code</a></p>

      <p class="text-muted mt-1">{{ 'modals.edit_contact_details.not_mobile_number' | translate }}</p>

    </div>
  </form>
</div>

<div class="modal-footer">
  <div class="m-start-auto">
    <button class="btn btn-link" (click)="handleCancel()">{{ 'action.cancel' | translate }}</button>

    <button class="btn btn-secondary m-start-3" [ngClass]="{'loader': isLoading}" (click)="handleSubmit()">
      <span class="loader"><em class="spinner-border spinner-border-sm" *ngIf="isLoading"></em></span>
      <span>{{ 'action.confirm' | translate }}</span>
    </button>
  </div>
</div>
