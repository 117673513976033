import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Faq } from '../../../models/faq';
import { FaqCategory } from '../../../models/faq-category';
import { DataService } from '../../../services/data.service';

@Component({
  selector: 'app-faq-detail',
  templateUrl: './faq-detail.component.html',
  styleUrls: ['./faq-detail.component.scss']
})
export class FaqDetailComponent implements OnInit {
  public faq: Faq;
  public category: FaqCategory;
  public otherFaqs: Faq[] = [];
  public patientUid: string;
  public patientPathwayUid: string;

  constructor(
    public dataService: DataService,
    public activatedRoute: ActivatedRoute,
    public router: Router
  ) { }

  ngOnInit(): void {
    this.activatedRoute.params.subscribe(params => {
      this.patientUid = params.patientUid;
      this.patientPathwayUid = params.patientPathwayUid;

      this.faq = this.dataService.get('selectedFaq');
      this.category = this.dataService.get('selectedFaqCategory');
      this.otherFaqs = this.category.items?.filter((faq: Faq) => faq.uid !== this.faq.uid);
    });
  }

  goToFaq(event) {
    this.dataService.set('selectedFaq', event.faq);
    this.dataService.set('selectedFaqCategory', event.category);

    this.router.navigateByUrl(`patient/${this.patientUid}/${this.patientPathwayUid}/faq/${event.faq.uid}`);
  }
}
