import { Directive, ElementRef, Renderer2, OnInit } from '@angular/core';

@Directive({
  selector: 'input'
})
export class InputDirective implements OnInit {
  protected inputTypes = ['text', 'search', 'url', 'tel', 'email', 'password', 'number' ];
  constructor(private el: ElementRef, private renderer: Renderer2) {}

  ngOnInit(): void {
    const element = this.el.nativeElement;

    if (element.tagName.toLowerCase() === 'input' && this.inputTypes.includes(element.type)) {
      const placeholder = element.getAttribute('placeholder');

      if (placeholder === null || placeholder.trim() === '') {
        this.renderer.setAttribute(element, 'placeholder', '');
      }
    }
  }
}
