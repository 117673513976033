<div class="layout-sidebar container">
  <div class="sidebar-content is-flexed ">
    <div class="content-part d-none d-sm-block">
      <app-flow-welcome [inSidebar]="true"></app-flow-welcome>
    </div>

    <div class="content-part">
      <p class="text-uppercase text-muted fw-bold small">{{ 'action.create_acc' | translate }}</p>
      <app-onboaring-icon-steps [onBoardingType]="onBoardingType"
        [currentState]="currentState"></app-onboaring-icon-steps>
    </div>

    <div class="footer-part mt-auto d-none d-sm-block">
      <app-flow-copyrights></app-flow-copyrights>
    </div>
  </div>
</div>

<div class="layout-body">
  <div class="body-content">
    <div class="container">
      <h5 class="mb-3">{{ 'pages.flow.self_onboarding_approval.approval' | translate }}</h5>
      <h6 class="h7 text-info">{{ 'pages.flow.self_onboarding_approval.hcp_verification' | translate }}</h6>
      <p>{{ 'pages.flow.self_onboarding_approval.hcp_verification2' | translate }}</p>

      <div class="footer-part mt-8 d-block d-sm-none">
        <app-flow-copyrights></app-flow-copyrights>
      </div>
    </div>
  </div>
</div>
