import { HelpcenterParagraph } from './helpcenter-paragraph';

export class HelpcenterArticle {
  public uid: string;
  public title: string;
  public description: string;
  public thumbnail: any;
  public paragraphs: Array<HelpcenterParagraph>;
  public disclaimer: string;
  public referenceUids: Array<string>;
  public referenceArticles: Array<HelpcenterArticle>;

  constructor(item?) {
    if (item) {
      this.fillFromJson(item);
    }
  }

  fillFromJson(item) {
    this.uid = item.uid || item.id;
    this.title = item.title;
    this.description = item.description;
    this.thumbnail = item.thumbnail;
    this.disclaimer = item.disclaimer;
    this.paragraphs = [];

    if (item.paragraphs) {
      item.paragraphs.items.forEach(paragraph => {
        this.paragraphs.push(new HelpcenterParagraph(paragraph));
      });
    }

    if (item.more_like_this) {
      const uids = item.more_like_this.map(article => article.uid);
      this.referenceUids = uids;
    }
  }

  public get isArticle(): boolean {
    if (!this.thumbnail) {
      return false;
    }

    const icon = this.thumbnail.icon;
    return (icon.includes('HTML'));
  }

  public get isMedia(): boolean {
    if (!this.thumbnail) {
      return false;
    }

    const icon = this.thumbnail.icon;
    return (icon.includes('Video') || icon.includes('Audio'));
  }

  public get isDocument(): boolean {
    if (!this.thumbnail) {
      return false;
    }

    const icon = this.thumbnail.icon;
    return icon.includes('PDF');
  }
}
