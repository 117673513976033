import { MaterialParagraph } from './material-paragraph';
import { TranslationInterface } from '../interfaces/translation.interface';

export class Material {
  public uid: string;
  public title: string;
  public description: string;
  public thumbnail: any;
  public paragraphs: MaterialParagraph[];
  public disclaimer: string;
  public phaseName: TranslationInterface;
  public progress = 0;
  public reference: TranslationInterface;
  public order: number;

  constructor(item?) {
    if (item) {
      this.fillFromJson(item);
    }
  }

  fillFromJson(item) {
    this.uid = item.id ?? item.uid;
    this.title = item.title;
    this.description = item.description;
    this.reference = item.reference;
    // DEBUGGING WITH FE TEST MATERIAL
    // this.reference.key = 'bltd811f61777427e00';

    this.progress = item.progress || 0;
    this.order = item.order;
  }

  fillFromCms(entry: any) {
    if (!entry) {
      return;
    }

    this.title = entry.title;
    this.description = entry.description;
    this.thumbnail = entry.thumbnail;
    this.disclaimer = entry.disclaimer;

    if (entry.hasOwnProperty("paragraphs")) {
      this.paragraphs = [];

      entry.paragraphs.items.forEach(paragraph => {
        this.paragraphs.push(new MaterialParagraph(paragraph));
      });
    }
  }

  displayThumb(): boolean {
    return ((!this.paragraphs?.length || this.paragraphs[0].type === 'video') ? false : true);
  }

  public get cmsUid(): string {
    return this.reference?.key;
  }

  public get isArticle(): boolean {
    if (!this.thumbnail) return false;

    const icon = this.thumbnail.icon;
    return (icon.includes('HTML'));
  }

  public get isMedia(): boolean {
    if (!this.thumbnail) return false;

    const icon = this.thumbnail.icon;
    return (icon.includes('Video') || icon.includes('Audio'));
  }

  public get isDocument(): boolean {
    if (!this.thumbnail) return false;

    const icon = this.thumbnail.icon;
    return icon.includes('PDF');
  }

  public get isCompleted(): boolean {
    return Number(this.progress) >= 100;
  }

  public get isStarted(): boolean {
    return Number(this.progress) > 0 && Number(this.progress) < 100;
  }

  public get isUnstarted(): boolean {
    return Number(this.progress) == 0;
  }

  public get isUnavailable(): boolean {
    return !this.title;
  }
}
