<div class="layout-header-pusher header-back-tabs"></div>

  <div class="layout-header header-back-tabs">
    <div class="header-content">
      <div class="container">
        <app-back-link route="/dashboard" label="action.back_to_overview" id="timeline-overview-back" />
      </div>

    <div class="container my-auto">
      <h5>{{ 'pages.default.timeline.your_timeline' | translate }}</h5>
    </div>

    <div class="container">
      <app-page-tabs [items]="pageTabItems" *ngIf="!isLoading"></app-page-tabs>
    </div>
  </div>
</div>

<div class="body-content">
  <div class="container" *ngIf="isLoading">
    <app-page-loader></app-page-loader>
  </div>

  <div class="container" *ngIf="!isLoading">
    <div class="timeline-vertical">
      <ng-container *ngFor="let phase of phases; last as last; first as first; index as index">

        <!-- TODAY MARKER -->
        @if (printToday(index) && scopeService.doesCurrentPatientHasClicinalCareModule()) {
          <div class="timeline-item-wrapper heading today-marker">
            <div class="tl-day">
              <p class="large mb-0 fw-semibold">
                {{ 'pages.default.timeline.today' | translate }}
                , {{ today | date:dateFormat.format | translateNumber }}
              </p>
            </div>
          </div>
        }
        <!-- /TODAY MARKER -->

        @if (printUpcoming(index) && scopeService.doesCurrentPatientHasClicinalCareModule()) {
          @if (futureAppointments?.length > 0 && phase.inTheFuture) {

            <!-- UPCOMING APPOINTMENTS -->
            <div class="timeline-item-wrapper muted upcoming-appointment-marker">
              <div class="tl-title">
                <span class="icon sm rtl-mirrored-inline-svg m-end-2"
                      [inlineSVG]="'./assets/svg/calendar-outline-24.svg'"></span>
                <h6 class="h7">{{ 'pages.default.timeline.upcoming_appointments' | translate }}</h6>
              </div>
            </div>

            <div id="future-appointment-{{appointmentIndex}}" class="timeline-item-wrapper muted appointment"
                  *ngFor="let appointment of futureAppointments; let appointmentIndex = index"
                  [ngClass]="{'pb-0': last}">
              <div class="single-item-block item-clickable" (click)="selectAppointment($event, appointment);">
                <div class="block-icon">
                  <span class="icon" [inlineSVG]="'./assets/svg/calendar-outline-24.svg'"></span>
                </div>
                <div class="block-wrapper">
                  <p
                    class="value">{{ appointment?.start_date | timeZoneDate:dateFormat.format | translateNumber }}</p>

                  <p class="label" *ngIf="appointment?.title">{{ appointment?.title }}</p>
                  <p class="label" *ngIf="!appointment?.title">
                    {{ appointment?.title_key | translateHelp | translate }}
                  </p>

                  <p *ngIf="appointment?.description">{{ appointment?.description }}</p>
                  <p *ngIf="!appointment?.description">{{ appointment?.description_key | translateHelp | translate }}
                  </p>
                </div>

                <div class="block-btn-wrapper">
                  <div class="block-action btn btn-lg btn-tertiary btn-icon">
                    <span [inlineSVG]="'./assets/svg/arrow-right-32.svg'" class="rtl-mirrored-inline-svg"></span>
                  </div>
                </div>
              </div>
            </div>
          }
          <!-- /UPCOMING APPOINTMENTS -->

            <!-- UPCOMING MARKER -->
          @if (!phase.isDummy && phase.inTheFuture) {
            <div class="timeline-item-wrapper muted upcoming-marker">
              <div class="tl-title">
                <span class="icon sm rtl-mirrored-inline-svg m-end-2"
                      [inlineSVG]="'./assets/svg/reload-24.svg'"></span>
                <h6 class="h7">{{ 'pages.default.timeline.upcoming' | translate }}</h6>
              </div>
            </div>
          }
          <!-- /UPCOMING MARKER -->
        }

        <!-- PHASE  -->
        @if (!phase.isSubPhase && !phase.isDummy) {
          <div class="timeline-item-wrapper heading phase"
                [ngClass]="{muted: phase.inTheFuture}"
                id="phase-{{phase?.id}}">

            <!-- force pt-4 on the item that markes the treatment as stopped, which is the very very last item -->
            <div class="tl-phase-card">
              <div class="tl-bullet"></div>
              <div class="card">
                <div class="card-body d-flex">
                  <div class="content">
                    <h5>{{ phase?.translationKey | translate | evalTranslation }}</h5>

                    <div *ngIf="phase?.translated_description" [innerHTML]="phase?.translated_description"></div>
                  </div>

                  <div class="picture" *ngIf="phase?.picture && phase.media_selector !== 'video'"
                        [ngStyle]="{'background-image': 'url(' + phase?.picture + '?width=1832&disable=upscale&format=pjpg&auto=webp' + ')'}">
                  </div>

                  <!-- Video -->
                  <div class="content-video" *ngIf="phase?.videos && phase.media_selector === 'video'">
                    <app-video-carousel [videos]="phase.videos" [controlsPosition]="'bottom'"></app-video-carousel>
                  </div>
                  <!-- /Video -->
                </div>
              </div>
            </div>
          </div>
        }
        <!-- /PHASE  -->

        <!-- SUBPHASE subphases are never shown in future -->
        @if (phase.isSubPhase && !phase.inTheFuture && phase.phase_visible) {
          <div class="timeline-item-wrapper sub-phase"
                [ngClass]="{muted: phase.inTheFuture, 'pb-0': phase.isDummy}">
            <div class="tl-sub-phase-card">
              <div class="tl-bullet"></div>
              <div class="card">
                <div class="card-body d-flex">
                  <div class="content">
                    <p
                      class="small date mb-0">{{ phase?.started_at | timeZoneDate:dateFormat.format | translateNumber }}</p>
                    <h5>{{ (phase?.translationKey) | translate | evalTranslation }}</h5>
                    <div *ngIf="phase?.translated_description" [innerHTML]="phase?.translated_description"></div>

                    <div class="picture" *ngIf="phase?.picture && phase.media_selector !== 'video'"
                          [ngStyle]="{'background-image': 'url(' + phase?.picture + '?width=846&disable=upscale&format=pjpg&auto=webp' + ')'}">
                    </div>
                  </div>

                  @if (phase?.videos && phase.media_selector === 'video') {
                    <!-- Video -->
                    <div class="content-video">
                      <app-video-carousel [videos]="phase.videos" [controlsPosition]="'bottom'"></app-video-carousel>
                    </div>
                    <!-- Video -->
                  }
                </div>
              </div>
            </div>
          </div>
        }
        <!-- /SUBPHASE -->

        <!-- PASSED APPOINTMENTS -->
        @if (!phase.inTheFuture && scopeService.doesCurrentPatientHasClicinalCareModule()) {
          <div class="e2e-past-appointments">
            @for (appointment of phase?.appointments; track appointment.uid) {
              <div class="timeline-item-wrapper appointment" [ngClass]="{'pt-1': $first}">
                <div id="passed-appointment-{{ $index }}" class="single-item-block item-clickable"
                      (click)="selectAppointment($event, appointment);">

                  <div class="block-icon">
                    <span class="icon" [inlineSVG]="'./assets/svg/calendar-outline-24.svg'"></span>
                  </div>

                  <div class="block-wrapper">
                    <p
                      class="value">{{ appointment?.start_date | timeZoneDate:dateFormat.format | translateNumber }}</p>

                    <p class="label" *ngIf="appointment?.title">{{ appointment?.title }}</p>
                    <p class="label"
                        *ngIf="!appointment?.title">{{ appointment?.title_key | translateHelp | translate }}
                    </p>

                    <p *ngIf="appointment?.description">{{ appointment?.description }}</p>
                    <p
                      *ngIf="!appointment?.description">{{ appointment?.description_key | translateHelp |translate }}</p>
                  </div>

                  <div class="block-btn-wrapper">
                    <div class="block-action btn btn-lg btn-tertiary btn-icon">
                      <span [inlineSVG]="'./assets/svg/arrow-right-32.svg'" class="rtl-mirrored-inline-svg"></span>
                    </div>
                  </div>
                </div>
              </div>
            }
          </div>
        }
        <!-- PASSED APPOINTMENTS -->

        <!-- Learning Materials -->
        <div class="learning-materials">
          <div class="row m-0 g-0">
            <div class="timeline-item-wrapper learning-material col-4 pt-1" *ngIf="phase?.learning_materials?.length">
              <div id="learning-material-{{phase.id}}" class="single-item-block item-clickable"
                    (click)="goToMaterialPhase($event, phase)">

                <div class="block-icon">
                  <span class="icon" [inlineSVG]="'./assets/svg/learning-outline-24.svg'"></span>
                </div>

                <div class="block-wrapper font-weight-bold">
                  <span>{{ phase?.learning_materials?.length }} {{ 'pages.default.timeline.learning_materials' | translate }}</span>
                </div>

                <div class="block-btn-wrapper">
                  <div class="block-action btn btn-lg btn-tertiary btn-icon">
                    <span [inlineSVG]="'./assets/svg/arrow-right-32.svg'" class="rtl-mirrored-inline-svg"></span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- /Learning Materials -->
      </ng-container>
    </div>

    <!-- Spacer - do not remove -->
    <div class="pb-4"></div>
    <!-- Spacer - do not remove -->

  </div>
</div>
